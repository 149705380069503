import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Label,
  Input,
  Form,
  FormGroup,
  FormText,
  FormFeedback,
  Button,
  Spinner
} from "reactstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import ShowNotification from "../../show_notification";


const InitialForm = props => {
  const {
    toggleTab, steps, headers, projectId, initialFormData
   } = props

  const { register, handleSubmit, errors } = useForm()
  const [ formatHaveAtJobType, setFormatHaveAtJobType ] = useState(false)
  const [ formatHaveNumbersJobType, setFormatHaveNumbersJobType ] = useState(false)
  const [ formatHaveAtJobDescription, setFormatHaveAtJobDescription ] = useState(false)
  const [ formatHaveNumbersJobDescription, setFormatHaveNumbersJobDescription ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isDisabled, ] = useState( initialFormData !== null ? true : false )
  const numberRex = /\d{4}/


  const onSubmit = value => {
    let validValue = true
    if( [...value.jobType].find( find => find === '@' ) !== undefined) {
      setFormatHaveAtJobType(true)
      setTimeout(() => { setFormatHaveAtJobType(false) }, 3000)
      validValue = false
    }

    if( [...value.jobDescription].find( find => find === '@' ) !== undefined ) {
      setFormatHaveAtJobDescription(true)
      setTimeout(() => { setFormatHaveAtJobDescription(false) }, 3000)
      validValue = false
    }

    if( numberRex.test(value.jobType) ) {
      setFormatHaveNumbersJobType(true)
      setTimeout(() => { setFormatHaveNumbersJobType(false) }, 3000)
      validValue = false
    }

    if( numberRex.test(value.jobDescription) ) {
      setFormatHaveNumbersJobDescription(true)
      setTimeout(() => { setFormatHaveNumbersJobDescription(false) }, 3000)
      validValue = false
    }

    if( validValue ) {
      setIsLoading(true)
      axios({
        method: 'POST',
        url: `${window.location.origin}/project/initial_info`,
        headers: headers,
        data: {
          charge_name: value.jobType,
          description: value.jobDescription
        }
      })
      .then( response => {
        if(response.status === 201) {
        ShowNotification({
          message: "Informacion inicial guardada en borradores, puedes continuar...",
          type: "success"
        })
        setIsLoading(false)
        steps("offer_detail")
        toggleTab("offer_detail")
        window.scrollTo(0, 0)
        projectId(response.data.id)
      } else {
        ShowNotification({
          message: "Ups, ocurrió un error. Vuelve a Intentar...",
          type: "error"
        })
        setIsLoading(false)
      }
      })
      .catch( error => {
        setIsLoading(false)
        ShowNotification({
          message: "Ups, ocurrió un error. Vuelve a Intentar...",
          type: "error"
        })
        console.log(error)
      })
    }
  }

  useEffect(() => {}, [ formatHaveAtJobType, formatHaveAtJobDescription, formatHaveAtJobDescription, formatHaveNumbersJobDescription, isLoading ])


  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <Label for="jobType">Cargo / Título de la oferta</Label>
        <Input
          type="text"
          name="jobType"
          id="jobType"
          defaultValue={ initialFormData && initialFormData.jobType || "" }
          disabled={ isDisabled }
          innerRef={ register({
            required: {
              value: !isDisabled,
              message: 'Título de Oferta es Requerido'
            },
            maxLength: {
              value: 40,
              message: 'Debe ser menor a 40 caracteres'
            }
          }) }
          invalid={ errors.jobType || formatHaveAtJobType || formatHaveNumbersJobType }
          />
          { errors.jobType &&
            <FormFeedback invalid={ errors.jobType.toString() }>{ errors.jobType.message }</FormFeedback>
          }

          { formatHaveAtJobType &&
            <FormFeedback invalid={ formatHaveAtJobType.toString() }>No puede tener @</FormFeedback>
          }

          { formatHaveNumbersJobType &&
            <FormFeedback invalid={ formatHaveNumbersJobType.toString() }>No se permite mas de 3 números consecutivos</FormFeedback>
          }
        <FormText color="muted">
          No está permitido el uso de '@', 'arroba', correos, ni +3 números consecutivos.
        </FormText>
      </FormGroup>
      <FormGroup>
        <Label for="jobDescription">Descripción del cargo</Label>
        <Input
          type="textarea"
          name="jobDescription"
          id="jobDescription"
          rows={10}
          defaultValue={ initialFormData && initialFormData.jobDescription || "" }
          disabled={ isDisabled }
          innerRef={register({
            required: {
              value: !isDisabled,
              message: 'Descripción del cargo es requerido.'
            },
            maxLength: {
              value: 920,
              message: 'Debe ser menor a 920 caracteres'
            }
          })}
          invalid={ errors.jobDescription || formatHaveAtJobDescription || formatHaveNumbersJobDescription }
        />
        { errors.jobDescription &&
          <FormFeedback invalid={ errors.jobDescription.toString() }>{ errors.jobDescription.message }</FormFeedback>
        }
        { formatHaveAtJobDescription &&
          <FormFeedback invalid={ formatHaveAtJobDescription.toString() }>No puede tener @</FormFeedback>
        }
        { formatHaveNumbersJobDescription &&
          <FormFeedback invalid={ formatHaveNumbersJobDescription.toString() }>No se permite mas de 3 números consecutivos</FormFeedback>
        }
      </FormGroup>
      <Row>
        <Col md="12" className="text-right">
          { !isDisabled &&
            <React.Fragment>
              { isLoading ?
                <Button
                  color="secondary"
                  disabled
                >
                  <Spinner color="primary" style={{ width: '2rem', height: '2rem' }} />
                </Button>
                :
                <Button
                  color="success"
                  className="my-4"
                  type="submit"
                >
                  Guardar y Continuar
                  <i className="fas fa-arrow-right ml-2"></i>
                </Button>
              }
            </React.Fragment>
          }
        </Col>
      </Row>
    </Form>
  )
}
export default InitialForm;
