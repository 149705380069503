import React, { Fragment, useState } from "react";
import axios from "axios";
import ShowNotification from "./show_notification";
const moment = require("moment");

const ContactUs = ({ current_user }) => {
  const [requested, setRequested] = useState(false);

  const isDisabled = () => {
    var last_help_requested = localStorage.getItem("last_help_requested");
    return last_help_requested
      ? moment(last_help_requested) >= moment().subtract(5, "minutes")
      : false;
  };

  const handleContact = e => {
    e.preventDefault();
    const data = { current_url: window.location.href };

    const headers = {
      "X-User-Email": current_user.email,
      "X-User-Token": current_user.authentication_token
    };

    if (isDisabled()) {
      ShowNotification({
        message: "Ya has solicitado ayuda, pronto estaremos en contacto",
        type: "error"
      });
    } else {
      axios
        .post(`${window.location.origin}/users/contact_support_member`, data, {
          headers: headers
        })
        .then(response => {
          localStorage.setItem("last_help_requested", moment().format());
          setRequested(true);
          ShowNotification({
            message: "Hemos notificado a un miembro de nuestro equipo.",
            type: "success"
          });
        })
        .catch(error => {
          ShowNotification({
            message: "Vuelva a intentar",
            type: "error"
          });
        });
    }
  };

  if (!isDisabled() && !requested) {
    return (
      <button
        type="button"
        onClick={handleContact}
        className="btn btn-primary btn-lg float"
        data-toggle="tooltip"
        data-placement="top"
        title={I18n.t("projects.messages.need_help")}
      >
        {"¡Dame una mano! "}
        <i className="fas fa-hands-helping"></i>
      </button>
    );
  } else {
    return (
      <button
        type="button"
        disabled={true}
        className="btn btn-primary btn-lg float"
        data-toggle="tooltip"
        data-placement="top"
        title={I18n.t("projects.messages.need_help")}
      >
        {"¡Te daremos una mano! "}
        <i className="far fa-thumbs-up"></i>
      </button>
    );
  }
};

export default ContactUs;
