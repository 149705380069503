import React from "react";
import ContentLoader from "react-content-loader";

const DonutChartLoader = props => {
  return (
    <ContentLoader
      height={200}
      width={200}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      {...props}
    >
      <circle cx="95" cy="74" r="70" />
      <rect x="7" y="157" rx="0" ry="0" width="47" height="11" />
      <rect x="144" y="157" rx="0" ry="0" width="47" height="11" />
      <rect x="72" y="157" rx="0" ry="0" width="47" height="11" />
    </ContentLoader>
  );
};

export default DonutChartLoader;
