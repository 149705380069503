import React from "react";
import {
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon
} from "react-share";
import ShowNotification from "../show_notification";

const SocialShareButtons = props => {
  const {
    publication_link,
    portal_name,
    title,
    type
  } = props;

  const handleCopyURL = (event, publication_link) => {
    event.preventDefault()
    navigator.clipboard.writeText(publication_link)
    ShowNotification({
      message: "Se ha copiado la URL al portapapeles",
      type: "success"
    });
  }

  const drawInlineButtons = () => {
    return (
      <div className="d-block">
        <LinkedinShareButton
          className="ml-2"
          url={publication_link}
        >
          <LinkedinIcon size={35} round />
        </LinkedinShareButton>
        <FacebookShareButton
          className="ml-2"
          url={publication_link}
          quote={title}
        >
          <FacebookIcon size={35} round />
        </FacebookShareButton>
        <TwitterShareButton
          className="ml-2"
          url={publication_link}
          title={title}
        >
          <TwitterIcon size={35} round />
        </TwitterShareButton>
        <WhatsappShareButton
          className="ml-2"
          url={publication_link}
          title={title}
        >
          <WhatsappIcon size={35} round />
        </WhatsappShareButton>
      </div>
    );
  }

  const drawDropdownButtons = () => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle className="btn btn-sm btn-label-warning btn-icon mx-1 border-0" title="Compartir Publicación">
          <i className="fas fa-share-alt"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>
            Compartir Publicación
          </DropdownItem>
          <div className="dropdown-item d-block py-1">
            <div className="d-flex w-75 text-left py-0 my-0">
              <a className="mx-0 my-0" style={{ cursor: "pointer" }}
                onClick={event => handleCopyURL(event, publication_link)}
              >
                <span>
                  <i className="fas fa-circle" style={{ fontSize: "16px" }}></i>
                  <i className="fas fa-copy fa-inverse"
                  style={{ fontSize: "8px", position: "relative", left: "-11px", top: "-3px" }}></i>
                </span>
                <span>Copiar a Portapapeles</span>
              </a>
            </div>
          </div>
          <div className="dropdown-item d-block py-1">
            <LinkedinShareButton
              className="w-75 text-left"
              url={publication_link}
            >
              <LinkedinIcon size={16} round />&nbsp;
              Linkedin
            </LinkedinShareButton>
          </div>
          <div className="dropdown-item d-block py-1">
            <FacebookShareButton
              className="w-75 text-left"
              url={publication_link}
              quote={title}
            >
              <FacebookIcon size={16} round />&nbsp;
              Facebook
            </FacebookShareButton>
          </div>
          <div className="dropdown-item d-block py-1">
            <TwitterShareButton
              className="w-75 text-left"
              url={publication_link}
              title={title}
            >
              <TwitterIcon size={16} round />&nbsp;
              Twitter
            </TwitterShareButton>
          </div>
          <div className="dropdown-item d-block py-1">
            <WhatsappShareButton
              className="w-75 text-left"
              url={publication_link}
              title={title}
            >
              <WhatsappIcon size={16} round />&nbsp;
              WhatsApp
            </WhatsappShareButton>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }

  return (
    <React.Fragment>
      { type === 'dropdown' ? drawDropdownButtons() : drawInlineButtons()}
    </React.Fragment>
  );
};

export default SocialShareButtons;
