import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Avatar from "react-avatar";
import Rating from "../postulation/rating"
import { Progress } from "react-sweet-progress";
import DropDownOptions from "../postulation/card/dropdown_options";

import axios from "axios";
import ShowNotification from "../show_notification";
import { element } from "prop-types";

import OffCanvas from "./offcanvas.js";
import { result } from "lodash";


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


// Moves an item from one list to another list.
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};


// Update Kanban State throught Endpoint
const updateKanbanState = async (postulationId, newKanbanStateObject) => {
  await axios
    .patch(`${window.location.origin}/postulations/update_kanban_state`, {
      postulation_id: postulationId,
      kanban_state: JSON.stringify(newKanbanStateObject),
    })
    .then((response) => {
      if (response.data.message === "OK") {
        ShowNotification({
          message: "Candidato actualizado con éxito.",
          type: "success"
        });
      }
    })
    .catch(() => {
      ShowNotification({
        message: "Ha ocurrido un error.",
        type: "error"
      });
    });
};

// Update Kanban Project Setting throught Endpoint
const updateKanbanProjectSetting = async (current_user, projectId, newKanbanProjectSetting) => {
  const headers = {
    "X-User-Email": current_user.email,
    "X-User-Token": current_user.authentication_token
  };

  await axios
    .patch(`${window.location.origin}/process/${projectId}/update_kanban_project_setting`, {
      kanban_project_setting: JSON.stringify(newKanbanProjectSetting),
    }, {
      headers: headers
    })
    .then((response) => {
      if (response.data.message === "OK") {
        ShowNotification({
          message: "Columnas actualizadas con éxito.",
          type: "success"
        });
      }
    })
    .catch(() => {
      ShowNotification({
        message: "Ha ocurrido un error.",
        type: "error"
      });
    });
};


// Update is_new throught Endpoint
const updateIsNew = async (postulationId) => {
  await axios
    .patch(`${window.location.origin}/postulations/${postulationId}/update_is_new`)
    .then((response) => {
      if (response.data.message === "OK") {

        const isNewDiv = document.querySelector(`#is_new-${postulationId}`);
        isNewDiv.innerHTML = "";

        ShowNotification({
          message: "El candidato ya no es nuevo.",
          type: "success"
        });

        return true;

      }
    })
    .catch(() => {
      ShowNotification({
        message: "Ha ocurrido un error.",
        type: "error"
      });
    });
};


const grid = 8;
const getItemStyle = (isDragging, isSelected, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: '4px',
  border: '1px solid #DEE2E6', // Style changed
  boxShadow: '0px 2px 4px rgba(0,0,0,.2)',

  // change background colour if dragging or if selected
  background: (isDragging || isSelected) ? "#C4CFF9" : "white",

  // we need this to show the number of cards indicator
  position: 'relative',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#EDF1FD" : "#F4F4F4",
  padding: grid,
  width: 300,
  margin: 8,
  height: '100%',
  minHeight: '50px',
  borderRadius: '4px'
});

const Board = ({ current_user, project_id, postulations_ids, kanban_board, authentication_headers, subtree_ids }) => {
  const [state, setState] = useState(null);
  const [kanban, setKanban] = useState(kanban_board);

  const [isLoading, setIsLoading] = useState(false);
  const [Error, setError] = useState(false);

  const [offCanvasId, setOffCanvasId] = useState(null);
  const [offCanvasBody, setOffCanvasBody] = useState(null);

  const [firstLoad, setFirstLoad] = useState(true);
  let offCanvasIdInUse = null;

  const [isSelected, setIsSelected] = useState([]);
  const [selectedCardIds, setSelectedCardIds] = useState(null);
  const [droppableInd, setDroppableInd] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [selectCard, setSelectCard] = useState(0);
  const [draggingCardIds, setDraggingCardIds] = useState(null);
  const [draggableIndex, setDraggableIndex] = useState(null);

  const [isMultiDraggingNow, setIsMultiDraggingNow] = useState(false);
  const [whichIsMultiDraggingNow, setWhichIsMultiDraggingNow] = useState(null);
  const [howManyAreMultiDraggingNow, setHowManyAreMultiDraggingNow] = useState(0);

  const [selectSeveralCards, setSelectSeveralCards] = useState(0);

  const [dontRepeatInitial, setDontRepeatInitial] = useState(false);
  const [dontRepeatAtMoveToRight, setDontRepeatAtMoveToRight] = useState(false);
  const [dontRepeatAtMoveToLeft, setDontRepeatAtMoveToLeft] = useState(false);

  let isSelectedArray = [];

  const getApplicants = async (project_id, postulations_ids, state, authentication_headers) => {
    const postulationsArray = [];
    const contentArray = [];

    const urlElastic = `${window.location.origin}/elastic/postulations/_msearch?`;
    const queryElastic = {
      size: 10000,
      sort: [
        {
          is_new: "desc",
          rating: "desc",
          global_score: "desc",
        },
      ],
      query: {
        bool: {
          must: [
            { term: { "project.id": project_id } },
            { terms: { _id: postulations_ids } },
          ],
          filter: [
            { term: { "kanban_state.key": state } },
          ],
        },
      },
    };

    return await axios({
      url: urlElastic,
      type: "json",
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        ...authentication_headers
      },
      data: { "_json": `{"preference": "null"}\n${JSON.stringify(queryElastic)}` }
    }).then((res) => {
      res.data.responses[0].hits.hits.forEach((resItem) => {
        postulationsArray.push(resItem);
      });

      postulationsArray.forEach((postulation) => {
        const { project, applicant } = postulation._source;
        const contentObject = {
          id: `${postulation._id}`,
          content: (
            <>
              <div style={{ display: "flex" }}>
                <Avatar
                  size="32"
                  round
                  name={applicant.name}
                  email={applicant.email}
                  color="rgba(93, 120, 255, 0.4)"
                />
                <div className="ml-2">
                  <a
                    href="#!"
                    style={{ fontSize: "1em", fontWeight: "500" }}
                    onClick={() => {

                      if (offCanvasIdInUse === postulation._id) {
                        $('.offcanvas-collapse').toggleClass('open');
                      } else {
                        setOffCanvasId(postulation._id);
                        offCanvasIdInUse = postulation._id;

                        if (postulation._source.is_new) {
                          if (updateIsNew(postulation._id)) {
                            postulation._source.is_new = false;
                          }
                        }

                        if ($('.offcanvas-collapse').hasClass('open')) {
                          $('.offcanvas-collapse').removeClass('open');
                          setTimeout(() => { $('.offcanvas-collapse').addClass('open'); }, 800);
                        } else {
                          $('.offcanvas-collapse').toggleClass('open');
                        }

                      }
                    }}
                  >
                    {applicant.name}
                  </a>
                  <Rating
                    total={5}
                    postulation_id={postulation._id}
                    current_rating={postulation._source.rating}
                    interactive={true}
                  />
                </div>

                <div className="ml-auto d-flex flex-column text-center">
                  <div>
                    <a className="btn btn-clean btn-sm btn-icon"
                      style={{ height: '2rem', width: '2rem' }}
                      data-toggle="dropdown">
                      <i className="flaticon-more" style={{ fontSize: '0.8rem' }}></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <DropDownOptions
                        project={project}
                        postulation={postulation._source}
                        postulation_id={postulation._id}
                        applicant={applicant}
                      />
                    </div>
                  </div>

                  <div id={`is_new-${postulation._id}`}>
                    {postulation._source.is_new ?
                      <i className="flaticon2-bell-2 kt-font-warning"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Nuevo Candidato"></i> : ""}
                  </div>
                </div>

              </div>

              <div className="mt-2">
                <span className="text-primary">
                  Puntaje Global
                </span>
                <Progress
                  theme={{
                    success: {
                      symbol: postulation._source.global_score,
                      color: "#5d78ff"
                    }
                  }}
                  percent={postulation._source.global_score}
                  status="success"
                  strokeWidth={3}
                />
              </div>

            </>
          )
        };
        contentArray.push(contentObject);
      });
      return contentArray;
    }).catch((error) => {
      ShowNotification({
        message: "Ha ocurrido un error, vuelva a intentar.",
        type: "error"
      });
      return [{ error: true }];
    });
  };


  const getOnePostulation = async (project_id, postulation_id, authentication_headers) => {
    const url = `${window.location.origin}/elastic/postulations/_msearch?`;
    const query = {
      size: 1,
      query: {
        bool: {
          must: [
            { term: { "project.id": project_id } },
            { term: { _id: postulation_id } },
          ],
        },
      },
    };

    await axios({
      url: url,
      type: "json",
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        ...authentication_headers
      },
      data: { "_json": `{"preference": "null"}\n${JSON.stringify(query)}` }
    }).then((res) => {
      setOffCanvasBody(res.data.responses[0].hits.hits[0]);
    }).catch((error) => {
      ShowNotification({
        message: "Ha ocurrido un error, vuelva a intentar.",
        type: "error"
      });
    });
  };

  let kanban_states = [];
  let kanban_titles = [];
  const getKanbanStatesAndTitles = () => {
    kanban_states = [];
    kanban_titles = [];
  Array.from(kanban).forEach(element => {
    kanban_states.push(element.kanban_state);
    kanban_titles.push(element.title);
  });
  }
  getKanbanStatesAndTitles();


  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);

      const applicantsStates = [];

      // Custom asyncForEach due to problems whit async/await in forEach
      const asyncForEach = async (array, callback) => {
        for (let index = 0; index < array.length; index++) {
          await callback(array[index], index, array);
        }
      }

      await asyncForEach(kanban_states, async state => {
        const applicantsArray = await getApplicants(project_id, postulations_ids, state, authentication_headers);

        if (applicantsArray.length === 1 && applicantsArray[0].error) {
          setError(true);
        } else {
          applicantsStates.push(applicantsArray);
        }
      });

      // Setting initial Multi-Drag Data Structure
      const arrayOfArrays = applicantsStates;
      arrayOfArrays.forEach((column) => {
        column.forEach((card) => {
          isSelectedArray.push({ id: card.id, selected: false, dragIndex: null });
        });
      });
      setIsSelected(isSelectedArray);

      setState(applicantsStates);
    }
    loadData();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    let slide = 0;
    const slideWidth = document.getElementById('slide');
    if (slideWidth) {
      slide = slideWidth.offsetWidth;
    }
    let container = 0;
    const containerWidth = document.getElementById('container');
    if (containerWidth) {
      container = containerWidth.offsetWidth;
    }
    if (slide - container > 0 && state && !dontRepeatInitial) {
      pulsar("right");
      setDontRepeatInitial(true);
    }
  }, [state]);

  useEffect(() => {
    getKanbanStatesAndTitles();
    if (firstLoad) {
      setFirstLoad(false);
    } else {
      updateKanbanProjectSetting(current_user, project_id, kanban);
    }
  }, [kanban]);

  useEffect(() => {
    // Update Multi-Drag Data Structure and Deselect All too when state is updated
    if (!firstLoad) {
      isSelectedArray = [];
      const stateArray = Array.from(state);
      stateArray.forEach((column) => {
        column.forEach((card) => {
          isSelectedArray.push({ id: card.id, selected: false, dragIndex: null });
        });
      });
      setIsSelected(isSelectedArray);
    }
  }, [state]);


  useEffect(() => {
    // Unselect All first
    if (!firstLoad) {
      const selArr = Array.from(isSelected);
      const selArrMap = selArr.map(x => { return { id: x.id, selected: false, dragIndex: null } });
      setSelectedCardIds(null);
      setIsSelected(selArrMap);
      setSelectCard(selectCard + 1); // Then, select one
    }
  }, [droppableInd]);


  useEffect(() => {
    // Select/Deselect One Card
    if (!firstLoad) {
      const selectedArr = Array.from(isSelected);
      const object = selectedArr.find(x => x.id === itemId);
      const index = selectedArr.findIndex(x => x.id === itemId);
      const newObj = { id: itemId, selected: !object.selected, dragIndex: draggableIndex }

      // replace
      selectedArr.splice(index, 1, newObj);

      // setting selectedCardId
      const selCard = selectedArr.filter(x => x.selected);
      const selCardId = selCard.map(x => { return { id: x.id, dragIndex: x.dragIndex } });

      // change to the new states
      setSelectedCardIds(selCardId);
      setIsSelected(selectedArr);
    }
  }, [selectCard]);

  useEffect(() => {
    // Select Several Cards
    if (!firstLoad) {
      const selectedArr = Array.from(isSelected);
      const selectedApplicants = selectedArr.filter(x => x.selected);

      if (selectedApplicants.length === 0) {
        const object = selectedArr.find(x => x.id === itemId);
        const index = selectedArr.findIndex(x => x.id === itemId);
        const newObj = { id: itemId, selected: !object.selected, dragIndex: draggableIndex }
        // replace
        selectedArr.splice(index, 1, newObj);

      } else {
        const applicantsIndexes = selectedApplicants.map(x => x.dragIndex);
        const lastInd = applicantsIndexes[(applicantsIndexes.length - 1)];
        const firstInd = applicantsIndexes[0];

        if (draggableIndex > lastInd) {
          const lastPrevSelectedInd = selectedArr.findIndex( x => x.dragIndex === lastInd);
          const untilFutureSelected = selectedArr.findIndex(x => x.id === itemId);

          let j = 0;
          for (let i = (lastPrevSelectedInd + 1); i <= untilFutureSelected; i++) {
            selectedArr[i].selected = true;
            selectedArr[i].dragIndex = lastInd + 1 + j;
            j += 1;
          }

        } else if (draggableIndex < firstInd) {
          const firstPrevSelectedInd = selectedArr.findIndex(x => x.dragIndex === firstInd);
          const untilFutureSelected = selectedArr.findIndex(x => x.id === itemId);

          let j = 0;
          for (let i = (firstPrevSelectedInd - 1); i >= untilFutureSelected; i--) {
            selectedArr[i].selected = true;
            selectedArr[i].dragIndex = firstInd - 1 - j;
            j += 1;
          }

        }

      }

      // setting selectedCardId
      const selCard = selectedArr.filter(x => x.selected);
      const selCardId = selCard.map(x => { return { id: x.id, dragIndex: x.dragIndex } });

      // change to the new states
      setSelectedCardIds(selCardId);
      setIsSelected(selectedArr);
    }
  }, [selectSeveralCards]);


  useEffect(() => {
    if (offCanvasId) {
      getOnePostulation(project_id, offCanvasId, authentication_headers);
    }
  }, [offCanvasId]);


  // // TESTING
  // useEffect(() => {
  //   console.log(offCanvasBody);
  // }, [offCanvasBody]);


  // Add column to Kanban Board
  const addColumn = () => {
    const newNameInput = document.querySelector('#column-name');
    const newName = newNameInput.value;

    if (newName !== "" && !kanban_titles.includes(newName)) {
      const newKanbanState = newName.toLowerCase();

      const newObject = {
        title: newName,
        kanban_state: newKanbanState
      }

      setKanban([...kanban, newObject]);
      setState([...state, []]);

      ShowNotification({
        message: "Columna creada con éxito.",
        type: "success"
      });

      $('#modalNewColumn').modal('hide');

      const containerWidth = document.getElementById('container').offsetWidth;
      const slideWidth = document.getElementById('slide').offsetWidth;
      if (slideWidth > containerWidth) {
        pulsar("right");
      }

    } else {
      ShowNotification({
        message: "Debe ingresar un nuevo título que no esté en uso.",
        type: "error"
      });
    }
  };


  let indexToDelColumn = null;
  const deleteColumn = () => {
    if (indexToDelColumn || indexToDelColumn === 0) {
      const deletingColumn = Array.from(state);
      const lookingKanban = Array.from(kanban);
      if (deletingColumn[indexToDelColumn].length === 0 && lookingKanban[indexToDelColumn].kanban_state !== "rejected") {

        deletingColumn.splice(indexToDelColumn, 1);

        const deletingKanban = Array.from(kanban);
        deletingKanban.splice(indexToDelColumn, 1);

        setKanban(deletingKanban);
        setState(deletingColumn);

        ShowNotification({
          message: "Columna eliminada con éxito.",
          type: "success"
        });

        $('#modalConfirmDelete').modal('hide');
      } else {
        ShowNotification({
          message: "Para eliminar una columna, no debe tener candidatos. Además, no es posible eliminar la columna Rechazados.",
          type: "error"
        });
      }
    }
  };

  const onDragStart = (result) => {
    // To generate how many numbers of cards are dragging indicator
    if (Array.isArray(selectedCardIds) && selectedCardIds.length > 1 && result.type === "postulation") {
      setWhichIsMultiDraggingNow(result.draggableId);
      setHowManyAreMultiDraggingNow(selectedCardIds.length);
      setIsMultiDraggingNow(true);

      setDraggingCardIds(selectedCardIds);
    }
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId, type } = result;

    // Reset how many numbers of cards are dragging indicator
    setIsMultiDraggingNow(false);
    setWhichIsMultiDraggingNow(null);
    setHowManyAreMultiDraggingNow(0);

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      if (type === "column") {
        // Move columns
        const newColumnOrder = Array.from(state);
        const [removed] = newColumnOrder.splice(source.index, 1);
        newColumnOrder.splice(destination.index, 0, removed);

        const newKanbanOrder = Array.from(kanban);
        const [removedKanban] = newKanbanOrder.splice(source.index, 1);
        newKanbanOrder.splice(destination.index, 0, removedKanban);
        setKanban(newKanbanOrder);
        setState(newColumnOrder);

      } else {
        if (Array.isArray(draggingCardIds) && draggingCardIds.length > 1) {
          // Move group of cards
          const sourceClone = Array.from(state[sInd]);
          const destClone = Array.from(state[dInd]);

          const multiRemoved = [];
          draggingCardIds.forEach((card) => {
            const index = sourceClone.findIndex(x => x.id === card.id);
            const [removed] = sourceClone.splice(index, 1);
            multiRemoved.push(removed);
          });

          let i = 0;
          multiRemoved.forEach((removed) => {
            destClone.splice(destination.index + i, 0, removed);
            i += 1;
          });

          // Update kanban_state in DB
          const kanbanStateObject = {
            key: kanban_states[dInd],
            es: kanban_titles[dInd]
          };

          /*
          draggingCardIds.forEach((card) => {
            updateKanbanState(card.id, kanbanStateObject);
          });
          */

          const updateKanbanStateAsync = async () => {
            // Custom asyncForEach due to problems whit async/await in forEach
            const asyncForEach = async (array, callback) => {
              for (let index = 0; index < array.length; index++) {
                await callback(array[index], index, array);
              }
            }

            await asyncForEach(draggingCardIds, async (card) => {
              await updateKanbanState(card.id, kanbanStateObject);
            });
          };
          updateKanbanStateAsync();

          // Reset the draggingcardids state
          setDraggingCardIds(null);

          // Prepare new state
          const newState = [...state];
          newState[sInd] = sourceClone;
          newState[dInd] = destClone;

          // Set new state
          setState(newState);
          setSelectedCardIds(null);

        } else {
          const result = move(state[sInd], state[dInd], source, destination);

          // kanban_state in DB
          const kanbanStateObject = {
            key: kanban_states[destination.droppableId],
            es: kanban_titles[destination.droppableId]
          };
          updateKanbanState(draggableId, kanbanStateObject);

          const newState = [...state];
          newState[sInd] = result[sInd];
          newState[dInd] = result[dInd];

          //setState(newState.filter(group => group.length));
          setState(newState); // Do not remove columns with zero cards

        }
      }
    }
  }

  // Multi-Drag Events
  const onClick = (event, itemId, dropInd, dragIndex) => {
    if (event.ctrlKey || event.metaKey) {
      setItemId(itemId);
      setDraggableIndex(dragIndex);
      if (droppableInd !== dropInd || droppableInd === null) {
        setDroppableInd(dropInd);
      } else {
        setSelectCard(selectCard + 1);
      }
    } else if (event.shiftKey) {
      setItemId(itemId);
      setDraggableIndex(dragIndex);
      if (droppableInd !== dropInd || droppableInd === null) {
        setDroppableInd(dropInd);
      } else {
        setSelectSeveralCards(selectSeveralCards + 1);
      }
    }
  };

  const getSelectedStatus = (itemId) => {
    const selectedArr = Array.from(isSelected);
    const object = selectedArr.find(x => x.id === itemId);
    return object.selected;
  };

  // Horizontal Scroll
  let initialPosition = 0;
  const step = 300;

  // Intermitent buttons
  const pulsar = (direction) => {
    const arrow = document.querySelector(`#${direction}_arrow`)
    if (arrow) {
      arrow.classList.add("animated");
      arrow.classList.add("flash");
      setTimeout(() => {
        arrow.classList.remove("animated");
        arrow.classList.remove("flash");
      }, 2500);
    }
  };


  return (
    <>
    { isLoading || !state ?
        ( Error ?
      <div>
        <h6>No fue posible cargar los datos.</h6>
      </div>
      :
      <div>
        <h6>Cargando...</h6>
      </div>
        )
    :
    <div id="slide">
      <div id="scroll_left">
        <div
          id="left_arrow"
          className="scroll_left_right d-flex justify-content-center align-items-center"
          onClick={() => {
            const container = document.getElementById('container');
            container.scroll({
              top: 0,
              left: initialPosition - step,
              behavior: 'smooth'
            });
            if (initialPosition >= step) {
              initialPosition -= step;
            }

            const containerWidth = container.offsetWidth;
            const slideWidth = document.getElementById('slide').offsetWidth;
            if ( slideWidth > containerWidth && !dontRepeatAtMoveToLeft ) {
              pulsar("right");
              setDontRepeatAtMoveToLeft(true);
            }
          }}>
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </div>
      </div>

      <div id="help">
        <a
          className="btn btn-sm btn-label-warning btn-icon mx-1 border-0"
          data-toggle="tooltip" title="Ayuda"
          onClick={() => {
            $('#modalInstructions').modal('show');
          }}
        >
          <i className="fas fa-question"></i>
        </a>
      </div>

      <div id="add_column">
        <a
          className="btn btn-sm btn-label-success btn-icon mx-1 border-0"
          data-toggle="tooltip" title="Nueva columna"
          onClick={() => {
            const newName = document.querySelector('#column-name');
            newName.value = ''; // Clean the input before
            $('#modalNewColumn').modal({
              focus: true
            });
          }}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
        </a>
      </div>

      <div id="scroll_right">
        <div
          id="right_arrow"
          className="scroll_left_right d-flex justify-content-center align-items-center"
          onClick={() => {
            const slideWidth = document.getElementById('slide').offsetWidth;
            const containerWidth = document.getElementById('container').offsetWidth;
            const finalPosition = slideWidth - containerWidth;

            const container = document.getElementById('container');
            container.scroll({
              top: 0,
              left: initialPosition + step,
              behavior: 'smooth'
            });

            if(initialPosition <= finalPosition) {
              initialPosition += step;
            }

            if (slideWidth > containerWidth && !dontRepeatAtMoveToRight) {
              pulsar("left");
              setDontRepeatAtMoveToRight(true);
            }
          }}>
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </div>
      </div>

      <br />
      <br />
      <div style={{ display: "flex" }}>
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <Droppable key={1} droppableId="board" direction="horizontal" type="column">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={{ display: "inline-flex", height: '100%' }}
                  {...provided.droppableProps}
                >
              {state.map((el, ind) => (
                <Draggable key={ind} draggableId={`${ind}`} index={ind} direction="horizontal" type="column">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{ ...provided.draggableProps.style, display: "inline-flex", height: '100%' }}
                    >
                  <Droppable key={ind} droppableId={`${ind}`} type="postulation">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                      >
                        <div className="d-flex justify-content-between align-items-center mb-1">
                          <h5>{kanban_titles[ind]} ({el.length}) </h5>
                          <a
                            className="btn btn-sm btn-label-danger btn-icon border-0"
                            style={{ height: '1.5rem', width: '1.5rem'}}
                            data-toggle="tooltip" title="Eliminar columna"
                            onClick={() => {
                              indexToDelColumn = null;
                              indexToDelColumn = ind;
                              $('#modalConfirmDelete').modal({
                                focus: true
                              })
                            }}
                          >
                            <i className="fa fa-minus" aria-hidden="true" style={{ fontSize: '0.5rem' }}></i>
                          </a>
                        </div>

                        <div style={{ overflow: 'auto', maxHeight: '500px' }}>

                        {el.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                            type="postulation"
                          >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                getSelectedStatus(item.id),
                                provided.draggableProps.style
                              )}
                              onClick={(event) => {
                                onClick(event, item.id, ind, index);
                              }}
                            >
                              { (isMultiDraggingNow && (whichIsMultiDraggingNow === item.id)) ?
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                  backgroundColor: '#5d78ff',
                                  color: 'white',
                                  borderRadius: '50%',
                                  width: '40px',
                                  height: '40px',
                                  position: 'absolute',
                                  top: '-20px',
                                  right: '-20px'
                                  }}>
                                  <strong>{howManyAreMultiDraggingNow}</strong>
                              </div> : ""
                              }

                              {item.content}

                            </div>
                          )}
                          </Draggable>
                        ))}
                        {provided.placeholder}

                        </div>

                </div>
              )}
            </Droppable>
            </div>
            )}
            </Draggable>
          ))}
          {provided.placeholder}
          </div>)}
          </Droppable>
        </DragDropContext>
      </div>


      {/* Modal New Column begin*/}
      <div className="modal fade" id="modalNewColumn" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Agregar nueva columna</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form id="addColumnForm" onSubmit={(event) => {
                event.preventDefault();
                addColumn();
                $('#modalNewColumn').modal('hide');
              }}>
                <div className="form-group">
                  <label htmlFor="column-name" className="col-form-label">Título de la nueva columna:</label>
                  <input type="text" className="form-control" id="column-name" />
                </div>
                <div className="d-flex justify-content-end">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                  <button type="submit" className="btn btn-primary">Crear columna</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Modal New Column end */}

      {/* Modal Confirm Delete begin */}

      <div className="modal fade" id="modalConfirmDelete" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Eliminar columna</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>¿Realmente quiere eliminar esta columna?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
              <button type="button" className="btn btn-danger"
                onClick={() => {
                  deleteColumn();
                }}
              >Confirmar eliminación</button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Confirm Delete end */}

      {/* Offcanvas begin */}

      <div className="offcanvas-collapse p-3 material-shadow" id="offcanvas-right" style={{zIndex: '1001'}}>

        <div className="d-flex justify-content-between align-items-start">
          <h5></h5>
          <div className="mt-0 pt-0">
            <a href="#!"
              style={{ fontSize: "2em" }}
              onClick={() => {
                $('.offcanvas-collapse').toggleClass('open');
              }}>
              &times;
            </a>
          </div>
        </div>

        <div>
          {offCanvasBody ?
            <OffCanvas
              postulation={offCanvasBody}
              authentication_headers={authentication_headers}
              subtree_ids={subtree_ids}
            />
            :
            <div></div>
          }
        </div>
      </div>

      {/* Offcanvas end */}


      {/* Modal Instructions begin */}

      <div className="modal fade" id="modalInstructions" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Instrucciones:</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p><strong>Para mover un candidato entre etapas:</strong><br /><strong><code><i className="fas fa-mouse-pointer"></i> click</code></strong>, arrastrar manteniendo el <strong><code><i className="fas fa-mouse-pointer"></i> click</code></strong> apretado y soltar en la etapa deseada.</p>
              <p><strong>Para seleccionar m&uacute;ltiples candidatos:</strong><br /> <strong><code>&#8679; Shift</code> + <code><i className="fas fa-mouse-pointer"></i> click</code></strong> simult&aacute;neamente.</p>
              <p><strong>Para seleccionar/deseleccionar candidatos individualmente:</strong></p>
              <ul>
                <li><p>En Windows: <strong><code>⎈ Ctrl</code> + <code><i className="fas fa-mouse-pointer"></i> click</code></strong> simult&aacute;neamente.</p></li>
                <li><p>En Mac: <strong><code>⌘ Command</code> + <code><i className="fas fa-mouse-pointer"></i> click</code></strong> simult&aacute;neamente.</p></li>
              </ul>
              <p><strong>Para mover un grupo de candidatos entre etapas:</strong><br />Una vez seleccionados, para moverlos a otra etapa debe hacer <strong><code><i className="fas fa-mouse-pointer"></i> click</code></strong>, arrastrar manteniendo el <strong><code><i className="fas fa-mouse-pointer"></i> click</code></strong> apretado y soltar en la etapa deseada.</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success"
                onClick={() => {
                  $('#modalInstructions').modal('hide');
                }}
              >Entendido</button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Instructions end */}

    </div>

  }
  </>
  );
}

export default Board;
