import React, { useState, useEffect } from "react";
import DonutChartLoader from "../loaders/donut_chart";
import { colors } from "../line_library_opt";
import Chart from "react-apexcharts";
import _ from "lodash";

const DonutChartWrapper = ({ aggregations, agg, title, loading }) => {
  const [labels, setLabels] = useState([]);
  const [series, setSeries] = useState([]);

  var options = {
    colors: colors,
    labels: labels,
    legend: {
      fontSize: "16px"
    }
  };

  const handleData = _buckets => {
    return new Promise((resolve, reject) => {
      resolve({
        labels: _.map(_buckets, "key"),
        series: _.map(_buckets, "doc_count")
      });
    });
  };

  useEffect(() => {
    if (aggregations && aggregations[agg].buckets) {
      const { buckets } = aggregations[agg];
      handleData(buckets).then(result => {
        setLabels(result.labels);
        setSeries(result.series);
      });
    }
  }, [aggregations]);

  if ((_.isEmpty(series) && _.isEmpty(labels)) || loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="w-50">
          <DonutChartLoader />
        </div>
      </div>
    );
  } else {
    return (
      <div className="card border-0">
        <div className="card-header text-center bg-white border-0">
          <h4 className="text-primary pt-3">{title}</h4>
        </div>
        <div className="card-body">
          <Chart options={options} series={series} height={300} type="donut" />
        </div>
      </div>
    );
  }
};

export default DonutChartWrapper;
