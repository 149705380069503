import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ShowNotification from "../../show_notification";
import axios from "axios";

import PostulationResults from "./body";

const ModalWrapper = ({
  selectedPostulation,
  setSelectedPostulation,
  authentificationHeaders
}) => {
  const [postulation, setPostulation] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(selectedPostulation)) {
      axios
        .get(`${window.location.origin}/postulations/${selectedPostulation}`, {
          headers: authentificationHeaders
        })
        .then(response => {
          setPostulation(JSON.parse(response.data.postulation));
          setOpen(true);
        })
        .catch(error => {
          ShowNotification({
            message: error,
            type: "error"
          });
        });
    }
  }, [selectedPostulation]);

  const unselectPostulation = () => {
    setSelectedPostulation({});
    setPostulation({});
    setOpen(false);
  };

  return !_.isEmpty(postulation) ? (
    <Modal size="xl" isOpen={open} className={"modal-dialog-centered"}>
      <ModalHeader>
        Resultados -{" "}
        <span className="text-primary">{postulation.applicant.name}</span>
      </ModalHeader>
      <ModalBody>
        <PostulationResults postulation={postulation} />
      </ModalBody>
      <ModalFooter>
        <a
          href={`/process/${postulation.project_id}/applicants/${postulation.applicant.id}`}
          className="btn btn-label-primary btn-sm btn-upper"
          target="_blank"
        >
          Ver Perfil
        </a>

        <button
          onClick={() => unselectPostulation()}
          className="btn btn-label-secondary btn-sm btn-upper"
        >
          Cerrar
        </button>
      </ModalFooter>
    </Modal>
  ) : null;
};

export default ModalWrapper;
