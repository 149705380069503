import React, { useState, useEffect } from "react";

const AutoComplete = ({ suggestions, setInputValue, deleteValue }) => {

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState("");


  const onChange = (e) => {
    const userInput = e.target.value;
    // Filter our suggestions that don't contain the user's input
    const unLinked = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setInput(e.target.value);
    setFilteredSuggestions(unLinked);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const onClick = (e) => {
    setFilteredSuggestions([]);
    setInput(e.target.innerText);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {         // Enter key
      e.preventDefault();
      setInput(filteredSuggestions[activeSuggestionIndex]);
      setFilteredSuggestions([]);
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);
    } else if (e.keyCode === 38) {  // Up arrow key
      e.preventDefault();
      if (activeSuggestionIndex > 0) {
        setActiveSuggestionIndex(activeSuggestionIndex - 1);
      }
    } else if (e.keyCode === 40) {  // Down arrow key
      e.preventDefault();
      if (activeSuggestionIndex < filteredSuggestions.length - 1) {
        setActiveSuggestionIndex(activeSuggestionIndex + 1);
      }
    }
  }

  const onMouseOver = (e) => {
    setActiveSuggestionIndex(parseInt(e.target.id, 10));
  }

  const SuggestionsListComponent = () => {
    return filteredSuggestions.length ? (
      <ul className="suggestions">
        {filteredSuggestions.map((suggestion, index) => {
          let className;
          // Flag the active suggestion with a class
          if (index === activeSuggestionIndex) {
            className = "suggestion-active";
          }
          return (
            <li className={className} id={index} key={suggestion} onClick={onClick} onMouseOver={onMouseOver}>
              {suggestion}
            </li>
          );
        })}
      </ul>
    ) : (
      <div className="no-suggestions">
        <em>No se encontraron sugerencias. Se creará una nueva etiqueta.</em>
      </div>
    );
  };

  useEffect(() => {
    if (input) {
      setInputValue(input.trim())
    }
  }, [input]);

  useEffect(() => {
    setInput("");
  }, [deleteValue]);

  return (
    <>
      <input
        type="text"
        className="form-control"
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={input}
      />
      {showSuggestions && input && <SuggestionsListComponent />}
    </>
  );
};
export default AutoComplete;
