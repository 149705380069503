import React from "react";
import _ from "lodash";
import moment from "moment";
import "moment/min/locales";
import LazyComponent from "../lazy_component";
import { Progress } from "react-sweet-progress";

export default class ProjectNotification extends LazyComponent {
  constructor(props) {
    super(props);
    this.state = {
      processes: props.processes,
      userId: props.userId
    };
    this.received = this.received.bind(this);
  }

  drawProcess() {
    var processes = this.state.processes;

    if (processes.length > 0) {
      return processes.map(function(process, index) {
        var status =
          process.progress == 100
            ? "success"
            : process.state == "active"
            ? "active"
            : "error";

        return (
          <div key={index}>
            <div className="card text-left bg-white mb-2">
              <div className="card-header bg-white pb-1 border-bottom-0">
                <small className="text-primary __force-text_break-word">
                  {process.charge_name}
                </small>
              </div>
              <div className="card-body bg-white py-0">
                <Progress percent={process.progress} status={status} />
              </div>
              <div className="card-footer bg-white pt-1 border-top-0">
                <small className="text-info __force-text_break-word">
                  <span className="text-muted">
                    {moment(process.updated_at).format("HH:mm")}
                    {" - "}
                  </span>
                  {process.type}
                </small>
                <hr className="mt-0 mb-0" />
                <small className="text-muted __force-text_break-word">
                  {process.log}
                </small>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="d-flex align-items-center justify-content-center h-100 w-100 flex-fill text-muted text-center flex-column">
          <i className="far fa-check-circle fa-5x mb-2 d-block" />
          <div>Sin notificaciones para hoy</div>
        </div>
      );
    }
  }

  componentDidMount() {
    var _this = this;
    App.cable.subscriptions.create(
      {
        channel: "NotificationChannel"
      },
      {
        received: _this.received
      }
    );
  }

  received(data) {
    var process = data.process;
    if (this.state.processes.length > 0) {
      var found = this.state.processes.findIndex(function(p) {
        return p.id == data.process.id;
      });
      if (found == -1) {
        this.state.processes.unshift(process);
      } else {
        _.pullAt(this.state.processes, [found]);
        this.state.processes.unshift(process);
      }
    } else {
      this.state.processes.unshift(process);
    }

    document.querySelector("#notification_badge").classList.remove("d-none");
    this.setState({
      processes: this.state.processes
    });
  }

  render() {
    return (
      <div
        className="list-group p-3 w-100 h-100 overflow-auto"
        style={{ minHeight: "90vh", minWidth: "280px" }}
      >
        {this.drawProcess()}
      </div>
    );
  }
}
