import React from "react";
import _ from "lodash";

const Result = ({ responses, points, status }) => (
  <div className="container col-md-12">
    <div className="d-flex align-items-center justify-content-around text-bold m-4">
      <span className="text-center">
        <i className="fas fa-medal mr-1"></i>Puntaje Shortlist:
        <strong className="mx-1">{Math.round(points)} %</strong>
      </span>
      <span className="text-center">
        <i className="fas fa-spinner mx-1"></i>Estado Shortlist:
        <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill kt-badge--rounded ml-2">
          {status}
        </span>
      </span>
    </div>

    {_.map(responses, (response, index) => {
      const icon = response.is_wrong ? (
        <i className="far fa-times-circle text-danger mr-2"></i>
      ) : (
        <i className="far fa-check-circle text-success mr-2"></i>
      );

      return (
        <div key={`shortlist_${index}`}>
          <p>
            <strong>{response.question}</strong>
            <br></br>
          </p>
          <p>
            <span className="float-left">{icon}</span>
            <em>{response.answer}</em>
          </p>
        </div>
      );
    })}
  </div>
);

export default Result;
