import React, { Component, useEffect } from "react";
import { render } from "react-dom";
import { Stage, Layer, Image, Transformer } from "react-konva";
import useImage from "use-image";

class URLImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      image: null
    };

    this.shapeRef = React.createRef();
    this.trRef = React.createRef();
  }

  componentDidMount() {
    this.loadImage();
  }

  componentWillReceiveProps() {
    if (this.props.isSelected) {
      // we need to attach transformer manually
      this.trRef.current.setNode(this.shapeRef);
      this.trRef.current.getLayer().batchDraw();
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
  }

  componentWillUnmount() {
    this.image.removeEventListener("load", this.handleLoad);
  }

  loadImage() {
    // save to "this" to remove "load" handler on unmount
    this.image = new window.Image();
    this.image.src = this.props.src;
    this.image.addEventListener("load", this.handleLoad);
  }

  handleLoad = () => {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed
    this.setState({
      image: this.image
    });
    // if you keep same image object during source updates
    // you will have to update layer manually:
    // this.imageNode.getLayer().batchDraw();
  };

  render() {
    const { onChange } = this.props;
    const _this = this;

    return (
      <React.Fragment>
        <Image
          id={this.props.id}
          x={this.props.x}
          y={this.props.y}
          width={this.props.width}
          height={this.props.height}
          draggable={this.props.draggable}
          onDragEnd={e => {
            onChange({
              x: e.target.x(),
              y: e.target.y()
            });
          }}
          onTransformEnd={e => {
            // transformer is changing scale
            const node = _this.shapeRef;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);
            onChange({
              x: node.x(),
              y: node.y(),
              width: node.width() * scaleX,
              height: node.height() * scaleY
            });
          }}
          image={this.state.image}
          ref={node => {
            this.imageNode = node;
            _this.shapeRef = node;
          }}
        />
        {this.props.isSelected && (
          <Transformer
            ref={this.trRef}
            rotateEnabled={this.props.rotateEnabled}
          />
        )}
      </React.Fragment>
    );
  }
}

export default URLImage;
