import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import DayPicker, { DateUtils } from "react-day-picker";
import LazyComponent from "../lazy_component";

const moment = require("moment");
const _ = require("lodash");

export default class PostInPortal extends LazyComponent {
  static defaultProps = {
    numberOfMonths: 1
  };

  constructor(props) {
    super(props);

    this.state = {
      own_portals: [],
      talent_portals: [],
      hidden_inputs: [],
      selected: [],
      modal: false
    };

    var to_push = (type, portal) => {
      return {
        id: `${type}_${portal.name}`,
        name: portal.name,
        checked: false,
        portal: {
          id: portal.id,
          image: portal.image,
          hunting: portal.hunting,
          name: portal.name,
          need_reservation: portal.need_reservation,
          availability: portal.availability,
          origin: type,
          credential_id: portal.credential_id,
          start_date: "",
          end_date: ""
        }
      };
    };

    this.props.own_portals.map(portal => {
      this.state.selected.push(to_push("own", portal));
      this.state.own_portals.push(to_push("own", portal));
    });

    var hunting_portals = [];
    this.props.talent_portals.map(portal => {
      if (portal.hunting) {
        hunting_portals.push(portal);
      } else {
        this.state.talent_portals.push(to_push("talent", portal));
        this.state.selected.push(to_push("talent", portal));
      }
    });

    var hunting_portal = {
      id: `talent_4hunting`,
      name: "4hunting",
      checked: false,
      portals: hunting_portals
    };

    this.state.selected.push(hunting_portal);
    // this.state.talent_portals.push(hunting_portal); # Deactivate hunting portal
    this.handleDayClick = this.handleDayClick.bind(this);
  }

  getPortalImage(portal_name) {
    var portal = _.find(this.props.images, portal => {
      return portal.name == portal_name;
    });

    return !_.isEmpty(portal) ? portal.image : this.props.hunting_logo;
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  handleDayClick(day, modifiers, selector) {
    if (modifiers.disabled) return;

    var new_selected = this.state.selected;
    var index = _.findIndex(new_selected, current_selected => {
      return current_selected.id == selector.id;
    });

    const new_day = moment(day).set({
      hour: moment().get("hour"),
      minute: moment().get("minute"),
      second: moment().get("second")
    });

    new_selected[index]["portal"]["start_date"] = new_day.toDate();
    new_selected[index]["portal"]["end_date"] = new_day.add(2, "days").toDate();

    this.setState({ selected: new_selected });
  }

  handleChange({ event, portal, portals }) {
    const { id, checked } = event.target;
    var new_selected = this.state.selected;
    var obj = {
      id,
      checked,
      portal,
      portals
    };

    var selected_index = _.findIndex(new_selected, el => {
      return el.id == obj.id;
    });

    if (selected_index == -1) {
      new_selected.push(obj);
    } else {
      new_selected[selected_index] = obj;
    }
    if (checked) {
      var portal_index = _.findIndex(new_selected, el => {
        if (!el.portals) {
          return (
            el.portal.name == obj.portal.name &&
            el.portal_origin != obj.portal_origin
          );
        }
      });
      if (portal_index != -1) {
        new_selected[portal_index]["checked"] = !checked;
        document.querySelector(
          `#${new_selected[portal_index]["id"]}`
        ).checked = !checked;
      }
    }

    this.setState({ selected: new_selected });
    this.setHiddenInputs();
  }

  renderHiddenInputs() {
    return this.state.hidden_inputs.map((project_portal, index) => {
      const { portal } = project_portal;
      if (portal.need_reservation) {
        const { next_date } = portal.availability;
        const current_start = portal.start_date;
        const current_end = portal.end_date;
        var start_date =
          current_start != "" ? current_start : moment(next_date).toDate();
        var end_date =
          current_end != ""
            ? current_end
            : moment(next_date)
                .add(2, "days")
                .toDate();
      } else {
        var start_date = "";
        var end_date = "";
      }
      return (
        <div key={`${index}_input`}>
          <input
            name={`project[publications_attributes][${index}][origin]`}
            value={portal.origin}
            type="hidden"
            readOnly={true}
          />
          <input
            name={`project[publications_attributes][${index}][credential_id]`}
            value={portal.credential_id}
            type="hidden"
            readOnly={true}
          />
          <input
            name={`project[publications_attributes][${index}][portal_id]`}
            value={portal.id}
            type="hidden"
            readOnly={true}
          />
          <input
            name={`project[publications_attributes][${index}][start_date]`}
            value={start_date}
            type="hidden"
            readOnly={true}
          />
          <input
            name={`project[publications_attributes][${index}][end_date]`}
            value={end_date}
            type="hidden"
            readOnly={true}
          />
        </div>
      );
    });
  }

  setHiddenInputs() {
    var portals_to_send = _.filter(this.state.selected, element => {
      return element.checked;
    });
    var hidden_inputs = [];

    portals_to_send.map(selected => {
      if (selected.portals) {
        selected.portals.map(hunting_portal => {
          hunting_portal["origin"] = "talent";
          var portal = { portal: hunting_portal };
          hidden_inputs.push(portal);
        });
      } else {
        hidden_inputs.push(selected);
      }
    });

    const to_remove = _.filter(portals_to_send, selected => {
      return selected.portal.origin == "own";
    });

    to_remove.map(selected1 => {
      _.remove(hidden_inputs, selected2 => {
        return (
          selected2.portal.origin == "talent" &&
          selected1.portal.id == selected2.portal.id
        );
      });
    });

    this.setState({
      hidden_inputs
    });
  }

  drawSelectors(array, origin) {
    if (_.isEmpty(array)) {
      return (
        <div className="col">
          <div className="alert alert-solid-brand alert-bold" role="alert">
            <div className="alert-text">
              No tienes ninguna cuenta propia ingresada. &nbsp;
              <strong>Pero siempre podrás publicar a través de Miterna</strong>
            </div>
          </div>
        </div>
      );
    } else {
      return array.map((selector, index) => {
        var selected = _.find(this.state.selected, current_selected => {
          return current_selected.id == selector.id;
        });
        var { checked } = selected;
        var activeClass = checked ? "active" : "";

        return (
          <div
            className="col-sm-12 col-md-4 col-lg-3 text-center mb-5"
            key={`container_${selector.name}`}
          >
            <div
              className={`portal-item border-0 d-flex flex-column justify-content-center align-items-center ${activeClass} form-check `}
            >
              <label
                id={`label_${origin}_${selector.name}`}
                className=" pointer w-100 flex-fill text-center m-3 d-flex flex-column justify-content-center align-items-center form-check-label"
              >
                <img
                  className={`rounded-lg mb-2 d-block p-2 portal-img-form ${activeClass}`}
                  src={this.getPortalImage(selector.name)}
                />
                <input
                  type="checkbox"
                  id={`${origin}_${selector.name}`}
                  name={`${origin}_roles`}
                  className="form-check-input"
                  onChange={event =>
                    this.handleChange({
                      event,
                      portal: {
                        id: !selector.portals ? selector.portal.id : null,
                        name: !selector.portals ? selector.portal.name : null,
                        origin: origin,
                        credential_id: !selector.portals
                          ? selector.portal.credential_id
                          : null,
                        hunting: !selector.portals
                          ? selector.portal.hunting
                          : null,
                        need_reservation: !selector.portals
                          ? selector.portal.need_reservation
                          : null,
                        availability: !selector.portals
                          ? selector.portal.availability
                          : null,
                        start_date: !selector.portals
                          ? selected.portal.start_date
                          : null,
                        end_date: !selector.portals
                          ? selected.portal.end_date
                          : null
                      },
                      portals: selector.portals
                    })
                  }
                />
              </label>
              {checked ? this.renderNextAvailable(selector) : null}
            </div>
          </div>
        );
      });
    }
  }

  renderSelectors(origin) {
    const isOwn = origin == "own";

    return (
      <div className="card flex-fill mb-2 mb-sm-0 border-0">
        <div className="card-header bg-white border-0">
          <strong className="mb-0 text-primary">
            {isOwn
              ? "Publicar con cuenta propia en:"
              : "Publicar con cuenta MT en:"}
          </strong>
        </div>
        <div className="card-body">
          <div className="row">
            {this.drawSelectors(this.state[`${origin}_portals`], origin)}
          </div>
        </div>
      </div>
    );
  }

  renderNextAvailable(selector) {
    const { portal } = selector;
    if (portal && portal.need_reservation) {
      var selected = _.find(this.state.selected, current_selected => {
        return current_selected.id == selector.id;
      });

      if (selected.portal.start_date != "") {
        var date = moment(selected.portal.start_date).format("DD/MM");
      } else {
        var date = moment(portal.availability.next_date).format("DD/MM");
      }

      return (
        <React.Fragment>
          <span className="text-muted">{` Se publicará el ${date}`}</span>
          <div>
            <span
              data-toggle="tooltip"
              data-placement="bottom"
              title={"Escojer cuando publicar"}
              onClick={this.toggle}
              className="pointer"
            >
              <a href="#"> o programar</a>
            </span>
            <Modal
              toggle={this.toggle}
              isOpen={this.state.modal}
              className={"modal-dialog-centered"}
            >
              <ModalHeader toggle={this.toggle}>
                Reservar Publicación
              </ModalHeader>
              <ModalBody>{this.drawModalBody(selector)}</ModalBody>
            </Modal>
          </div>
        </React.Fragment>
      );
    }
  }

  drawModalBody(selector) {
    var index = _.findIndex(this.state.selected, current_selected => {
      return current_selected.id == selector.id;
    });

    const { start_date, end_date } = this.state.selected[index]["portal"];
    const modifiers = { start: start_date, end: end_date };

    return (
      <div className="card border-0 text-center">
        <div className="card-body">
          <div className="d-flex justify-content-center align-items-center">
            <DayPicker
              className="Selectable"
              numberOfMonths={this.props.numberOfMonths}
              selectedDays={[start_date, { from: start_date, to: end_date }]}
              modifiers={modifiers}
              onDayClick={(day, modifiers) =>
                this.handleDayClick(day, modifiers, selector)
              }
              initialMonth={moment().toDate()}
              disabledDays={[
                {
                  before: moment(
                    selector.portal.availability.next_date
                  ).toDate()
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="flaticon2-graph-1"></i>
            </span>
            <h3 className="kt-portlet__head-title">Portales de empleo</h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="alert alert-outline-info fade show" role="alert">
            <div className="alert-icon">
              <i className="flaticon-questions-circular-button"></i>
            </div>
            <div className="alert-text">
              Selecciona los portales donde quieres publicar tu oferta laboral.
              Puedes usar cuenta propia o cuenta MT para cada portal. &nbsp;
              <strong>
                Si no tienes cuentas propias de portales de empleo, Miterna lo
                hará por ti.
              </strong>
            </div>
            <div className="alert-close">
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <i className="la la-close"></i>
                </span>
              </button>
            </div>
          </div>
          <div
            className="row d-flex justify-content-between"
            id="post_in_portals"
          >
            <div className="col-sm-12">{this.renderSelectors("talent")}</div>
            <div className="col-sm-12">{this.renderSelectors("own")}</div>

            {this.renderHiddenInputs()}
            <div className="col-md-12">
              <span id="post_in_portal_errors" className="text-danger" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
