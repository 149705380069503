import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  TabContent,
  TabPane
} from "reactstrap";
import axios from "axios";
import InitialForm from "./steps/initial_form.js.jsx";
import PortalsForm from "./steps/portals_form.js.jsx";
import OfferForm from "./steps/offer_form.js.jsx";
import DetailsForm from "./steps/details_form.js.jsx";


const NewPublicationStepper = props => {
  const { current_user, config_params, config_state, project_id } = props

  const headers = {
    "X-User-Email": current_user.email,
    "X-User-Token": current_user.authentication_token
  }

  const [activeTab, setActiveTab] = useState(
    localStorage.getItem('steps') !== null ?
    JSON.parse(localStorage.getItem('steps'))[ JSON.parse(localStorage.getItem('steps')).length - 1 ]
    : "initial_info"
  )
  const [steps, setSteps] = useState(["initial_info"])
  const [projectId, setProjectId] = useState(
    localStorage.getItem('project_id') !== null ?
    localStorage.getItem('project_id') : project_id || null
  )

  const [ areas, setAreas ] = useState(null)
  const [ careers, setCareers ] = useState(null)
  const [ chargeTypes, setChargeTypes ] = useState(null)
  const [ companyActivities, setCompanyActivities ] = useState(null)
  const [ contractualRelationships, setContractualRelationships ] = useState(null)
  const [ countries, seCountries ] = useState(null)
  const [ educationTypes, setEducationTypes ] = useState(null)
  const [ levels, setLevels ] = useState(null)
  const [ profileTypes, setProfileTypes ] = useState(null)
  const [ regions, setRegions ] = useState(null)
  const [ studyAreas, setStudyAreas ] = useState(null)
  const [ studySituations, setStudySituations ] = useState(null)
  const [ workingTypes, setWorkingTypes ] = useState(null)
  const [ portals, setPortals ] = useState(null)

  const [ dataForm, ] = useState(
    config_params ? JSON.parse(config_params) : null
  )

  const [ configState, ] = useState(
    config_state ? JSON.parse(config_state) : null
  )

  const toggleTab = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const handledSteps = value => {
    if( steps.find( step => step === value ) === undefined ){
      setSteps([...steps, value])
    }
  }

  const fetchSelects = () => {
    axios
    .get(`${window.location.origin}/resources/`, {
    }, { headers: headers })
    .then(res => {
      setAreas(res.data.areas)
      setCareers(res.data.career)
      setChargeTypes(res.data.charge_types)
      setCompanyActivities(res.data.company_activities)
      setContractualRelationships(res.data.contractual_relationship)
      seCountries(res.data.countries)
      setEducationTypes(res.data.education_type)
      setLevels(res.data.levels)
      setProfileTypes(res.data.profile_types)
      setRegions(res.data.regions)
      setStudyAreas(res.data.study_area)
      setStudySituations(res.data.study_situations)
      setWorkingTypes(res.data.working_types)
    })
    .catch(error => {
      console.log(error)
    })
  }

  const fetchPortals = () => {
    axios
    .get(`${window.location.origin}/project/get_portals`, {
    }, { headers: headers })
    .then( res => {
      setPortals( res.data )
    })
    .catch(error => {
      console.log(error)
    })
  }

  useEffect(() => {
    fetchSelects()
    fetchPortals()
    if( configState ){
      switch(configState){
        case "portals_selected":
          setSteps([ ...steps, 'portals_selected' ])
        case "offer_detail":
          setSteps([ ...steps, 'portals_selected', 'offer_detail' ])
        case "additional_detail":
          setSteps([ ...steps, 'portals_selected', 'offer_detail', 'additional_detail' ])
        case "configured":
          setSteps([ ...steps, 'portals_selected', 'offer_detail', 'additional_detail' ])
        default:
          return
      }
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('steps', JSON.stringify(steps))
  }, [ steps ])

  useEffect(() => {
    if( projectId !== null ){
      localStorage.setItem('project_id', projectId)
    }
  }, [ activeTab, projectId ])

  return (
    <div>
      <Row id="profile-panel-forms">
        <Col md="12">
          <Nav pills className="nav-fill perfil-tabs-navigation text-center steps mb-3" role="tablist">
            <NavItem role="presentation">
              <NavLink
                className={
                  `d-flex align-items-center ${ activeTab === "initial_info" ? "active" : "" } ${steps.find( find => find === "initial_info" ) ? "active" : ""}`
                }
                onClick={() => {
                  toggleTab("initial_info");
                }}
                href="#initial_info"
                aria-controls="initial_info"
                role="tab"
                data-toggle="tab"
                id="initial_info-tab"
                aria-label="Información Inicial"
              >
                <span className="step-content-link">
                  <i className="fa fa-list-alt primary" aria-hidden="true"></i>
                  <span className="d-none d-sm-inline-block ml-2">
                    Información Inicial
                  </span>
                  <div className="input_required"></div>
                </span>
              </NavLink>
            </NavItem>

            <NavItem role="presentation">
              { steps.find( find => find === 'offer_detail' ) ?
                <NavLink
                  className={
                    `d-flex align-items-center active ${ activeTab === 'offer_detail' }`
                  }
                  onClick={() => {
                    toggleTab("offer_detail");
                  }}
                  href="#offer_detail"
                  aria-controls="offer_detail"
                  role="tab"
                  data-toggle="tab"
                  id="offer_detail-tab"
                  aria-label="Detalles de la Publicación"
                >
                  <span className="step-content-link">
                    <i className="fa fa-briefcase" aria-hidden="true"></i>
                    <span className="d-none d-sm-inline-block ml-2">
                      Detalles de la Publicación
                    </span>
                  </span>
                </NavLink>
                :
                <NavLink
                  className="d-flex align-items-center disabled"
                  href="#offer_detail"
                  aria-controls="offer_detail"
                  role="tab"
                  data-toggle="tab"
                  id="offer_detail-tab"
                  aria-label="Detalles de la Publicación"
                  disabled
                >
                  <span className="step-content-link">
                    <i className="fa fa-briefcase" aria-hidden="true"></i>
                    <span className="d-none d-sm-inline-block ml-2">
                      Detalles de la Publicación
                    </span>
                  </span>
                </NavLink>
              }
            </NavItem>
            <NavItem role="presentation">
              { steps.find( find => find === 'additional_detail' ) ?
                <NavLink
                  className={
                    `d-flex align-items-center ${steps.find( find => find === 'offer_detail' ) ? 'active' : 'disabled'} ${ activeTab === 'additional_detail' }`
                  }
                  onClick={() => {
                    toggleTab("additional_detail");
                  }}
                  href="#additional_detail"
                  aria-controls="additional_detail"
                  role="tab"
                  data-toggle="tab"
                  id="additional_detail-tab"
                  aria-label="..."
                >
                  <span className="step-content-link">
                    <i className="fas fa-info-circle" aria-hidden="true"></i>
                    <span className="d-none d-sm-inline-block ml-2">
                      Detalles Adicionales
                    </span>
                    <div className="input_match"></div>
                  </span>
                </NavLink>
                :
                <NavLink
                  className={
                    `d-flex align-items-center disabled`
                  }
                  href="#additional_detail"
                  aria-controls="additional_detail"
                  role="tab"
                  data-toggle="tab"
                  id="additional_detail-tab"
                  aria-label="..."
                  disabled
                >
                  <span className="step-content-link">
                    <i className="fas fa-info-circle" aria-hidden="true"></i>
                    <span className="d-none d-sm-inline-block ml-2">
                      Detalles Adicionales
                    </span>
                    <div className="input_match"></div>
                  </span>
                </NavLink>
              }
            </NavItem>

            <NavItem role="presentation">
              {steps.find(find => find === 'portals_selected') ?
                <NavLink
                  className={
                    `d-flex align-items-center ${steps.find(find => find === 'portals_selected') ? 'active' : ''}`
                  }
                  onClick={() => {
                    toggleTab("portals_selected");
                  }}
                  href="#portals_selected"
                  aria-controls="portals_selected"
                  role="tab"
                  data-toggle="tab"
                  id="portals_selected-tab"
                  aria-label="Portales de Empleo"
                >
                  <span className="step-content-link">
                    <i className="fa fa-bullhorn" aria-hidden="true"></i>
                    <span className="d-none d-sm-inline-block ml-2">
                      Portales de Empleo
                    </span>
                  </span>
                </NavLink>
                :
                <NavLink
                  className="d-flex align-items-center disabled"
                  href="#portals_selected"
                  aria-controls="portals_selected"
                  role="tab"
                  data-toggle="tab"
                  id="portals_selected-tab"
                  aria-label="Portales de Empleo"
                  disabled
                >
                  <span className="step-content-link">
                    <i className="fa fa-bullhorn" aria-hidden="true"></i>
                    <span className="d-none d-sm-inline-block ml-2">
                      Portales de Empleo
                    </span>
                  </span>
                </NavLink>
              }
            </NavItem>

          </Nav>
        </Col>
      </Row>
      <Card className="mb-5">
        <CardBody className="pt-3">
          <TabContent activeTab={activeTab} id="pills-tabContent">
            <TabPane id="initial_info" tabId="initial_info" aria-labelledby="initial_info-tab">
              <h2 className="tab-pane-title text-muted font-weight-bold">
                <i className="fa mr-2 fa-list-alt" aria-hidden="true"></i>
                <small className="">Información Inicial</small>
                <div className="input_required"></div>
                <hr />
              </h2>
              <InitialForm
                toggleTab={value => setActiveTab(value)}
                steps={ value => handledSteps(value)}
                headers={ headers }
                projectId={ value => setProjectId(value) }
                initialFormData={
                  dataForm ?
                    { jobType: dataForm.charge_name,
                      jobDescription: dataForm.description
                    }
                    :
                    null
                }
              />
            </TabPane>
            <TabPane id="portals_selected" tabId="portals_selected" aria-labelledby="portals_selected-tab">
              <h2 className="tab-pane-title text-muted font-weight-bold">
                <i className="fa mr-2 fa-bullhorn" aria-hidden="true"></i>
                <small className="">Portales de Empleo</small>
                <hr />
              </h2>
              {/*
              <div className="alert alert-outline-info fade show" role="alert">
                <div className="alert-icon">
                  <i className="flaticon-questions-circular-button"></i>
                </div>
                <div className="alert-text">
                  Selecciona los portales donde quieres que tu oferta de trabajo sea visible.&nbsp;
                  <strong>
                    Si tienes una cuenta en otro portal laboral puedes utilizarla para ingresar,
                    y que MiTerna publique por ti.
                  </strong>
                </div>
                <div className="alert-close">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <i className="la la-close"></i>
                    </span>
                  </button>
                </div>
              </div>*/}
              <PortalsForm
                toggleTab={value => setActiveTab(value)}
                portals={ portals }
                steps={ value => handledSteps(value)}
                headers={ headers }
                projectId={ projectId }
                portalsFormData={ dataForm ? dataForm.portals_selected : null }
              />
            </TabPane>
            <TabPane id="offer_detail" tabId="offer_detail" aria-labelledby="offer_detail-tab">
              <h2 className="tab-pane-title text-muted font-weight-bold">
                <i className="fa mr-2 fa-briefcase" aria-hidden="true"></i>
                <small className="">Detalles de la Publicación</small>
                <hr />
              </h2>
              {/*
              <div className="alert alert-outline-info fade show" role="alert">
                <div className="alert-icon">
                  <i className="flaticon-questions-circular-button"></i>
                </div>
                <div className="alert-text">
                  <strong>
                    Casi listo!&nbsp;
                  </strong>
                  Ahora completa estos detalles para publicar tu oferta en
                  <span className="pl-1">
                    { portals && portals.map( (portal, index) =>
                      <strong key={ portal.id } className="pl-1">
                        { `${portal.name}` }
                      </strong>
                    )}
                  </span>
                </div>
                <div className="alert-close">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <i className="la la-close"></i>
                    </span>
                  </button>
                </div>
              </div>
              */}
              <OfferForm
                toggleTab={value => setActiveTab(value)}
                chargeTypes={ chargeTypes }
                areas={ areas }
                workingTypes={ workingTypes }
                contractualRelationships={ contractualRelationships }
                companyActivities={ companyActivities }
                steps={ value => handledSteps(value)}
                headers={ headers }
                projectId={ projectId }
                offerDetailData={ dataForm ? dataForm.offer_detail : null }
              />
            </TabPane>
            <TabPane
              id="additional_detail"
              tabId="additional_detail"
              aria-labelledby="additional_detail-tab"
            >
              <h2 className="tab-pane-title text-muted font-weight-bold">
                <i className="fas mr-2 fa-info-circle" aria-hidden="true"></i>
                <small className="">Detalles Adicionales</small>
                <div className="input_match"></div>
                <hr />
              </h2>
              {/*
              <div className="alert alert-outline-info fade show" role="alert">
                <div className="alert-icon">
                  <i className="flaticon-questions-circular-button"></i>
                </div>
                <div className="alert-text">
                  <strong>
                    No queda nada!&nbsp;
                  </strong>
                  Solo un par de datos mas para publicar en
                  <span className="pl-1">
                    { portals && portals.map( (portal, index) =>
                      <strong key={ portal.id } className="pl-1">
                        { `${portal.name}` }
                      </strong>
                    )}
                  </span>
                </div>
                <div className="alert-close">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <i className="la la-close"></i>
                    </span>
                  </button>
                </div>
              </div>
              */}
              <DetailsForm
                toggleTab={value => setActiveTab(value)}
                regions={ regions }
                studySituations={ studySituations }
                profileTypes={ profileTypes }
                levels= { levels }
                steps={ value => handledSteps(value)}
                headers={ headers }
                projectId={ projectId }
                detailsData={ dataForm ? dataForm.additional_detail : null }
                careers={ careers }
              />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  )
}
export default NewPublicationStepper;
