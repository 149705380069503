import React from "react";
import { Progress } from "react-sweet-progress";
import _ from "lodash";

const SpendingWrapper = ({ data, title }) => {
  if (_.isEmpty(data)) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="w-50">
          <DonutChartLoader />
        </div>
      </div>
    );
  } else {
    return (
      <div className="row text-center">
        <div className="col-12 ">
          <h5 className="mt-0">{title}</h5>
        </div>
        <div className="col-12">
          <Progress type="circle" percent={data[2][1]} width={90} />
        </div>
        <div className="col-12">
          <h5 className="text-primary mt-2">
            <b>{data[0][1]}</b> usados de <b>{data[1][1]}</b> disponibles
          </h5>
        </div>
      </div>
    );
  }
};

export default SpendingWrapper;
