import React from "react";
import EnrollmentScores from "miterna-tests-scores";
import LazyComponent from "../../lazy_component";

export default class ZyvoProfileResults extends LazyComponent {
  render() {
    const { scores } = this.props;
    return <EnrollmentScores scores={scores} />;
  }
}
