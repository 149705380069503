import React from "react";
import ContentLoader from "react-content-loader";

const IndicatorCardLoader = props => (
  <ContentLoader
    speed={2}
    width={200}
    height={100}
    viewBox="0 0 200 100"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="15" y="10" rx="0" ry="0" width="150" height="79" />
  </ContentLoader>
);

export default IndicatorCardLoader;
