import React from "react";
import LazyComponent from "../../lazy_component";
import { FormGroup, CustomInput, Collapse } from "reactstrap";
import axios from "axios";
import Select from "react-select";
import { drawCompetence } from "./common";
import CreateProfile from "./create_profile";
import VyaCreateTest from "./create_vya";
import _ from "lodash";

const baseName = "project[zyvo_project_attributes]";

export default class ZyvoProjectAttribute extends LazyComponent {
  constructor(props) {
    super(props);
    this.state = {
      competencies_ids: [],
      zyvo_profiles: [],
      zyvo_process_duration: {},
      selectable_profiles: [],
      selected_profile: {},
      isLoading: false
    };
    this.getProcessDuration();
  }

  componentDidMount() {
    document.querySelector("#react-select-2-input")["required"] = true;
  }

  getProfiles() {
    this.setState({ isLoading: true });
    const { current_user } = this.props;
    const headers = {
      "X-User-Email": current_user.email,
      "X-User-Token": current_user.authentication_token
    };

    axios
      .get(`${window.location.origin}/zyvo/profiles`, {
        headers: headers
      })
      .then(response => {
        this.setState({ zyvo_profiles: response.data.data, isLoading: false });
        this.loadSelectOptions();
      })
      .catch(error => {
        console.log("error", error);
      });
  }

  getProcessDuration(_competencies_ids = null, stateUpdater = null) {
    const { current_user } = this.props;
    const competencies_ids = _competencies_ids
      ? _competencies_ids
      : this.state.competencies_ids;
    const headers = {
      "X-User-Email": current_user.email,
      "X-User-Token": current_user.authentication_token
    };

    axios
      .get(
        `${window.location.origin}/zyvo/profiles/duration`,
        {
          params: {
            competencies_ids: competencies_ids.toString()
          }
        },
        {
          headers: headers
        }
      )
      .then(response => {
        if (stateUpdater) {
          stateUpdater(response.data);
        } else {
          this.setState({ zyvo_process_duration: response.data });
        }
      });
  }

  loadSelectOptions() {
    const { zyvo_profiles } = this.state;
    const _selectable_profiles = _.map(zyvo_profiles, profile => {
      return { value: profile.profile_integration_id, label: profile.name };
    });

    this.setState({ selectable_profiles: _selectable_profiles });
  }

  handleSelectChange(e) {
    const { zyvo_profiles } = this.state;

    const profile = _.find(zyvo_profiles, profile => {
      return profile.profile_integration_id == e.value;
    });

    if (profile)
      this.setState(
        {
          competencies_ids: _.map(profile.competencies, profile => {
            return profile.id.toString();
          }),
          selected_profile: profile
        },
        () => {
          document.querySelector("#react-select-2-input")["required"] = false;
          this.getProcessDuration();
        }
      );
  }

  findScoreByCompetence(competence_id) {
    const competence = _.find(
      this.state.selected_profile.competencies,
      profile => {
        return profile.id == competence_id;
      }
    );
    return competence ? competence.score : 50;
  }

  isIncluded(competence_id) {
    const { selected_profile } = this.state;
    return !!_.find(selected_profile.competencies, { id: competence_id });
  }

  setCompetencies() {
    let uniq_ids = _.uniq(this.state.competencies_ids);
    return _.sortBy(uniq_ids, competence => {
      return parseInt(competence, 10);
    }).join(",");
  }

  drawDescription() {
    const { selected_profile } = this.state;
    if (!_.isEmpty(selected_profile))
      return (
        <div className="alert alert-primary mt-4" role="alert">
          <i className="fas fa-info-circle mr-1"></i>
          {selected_profile.description}
        </div>
      );
  }

  drawProcessDuration(_zyvo_process_duration = null, _competencies_ids = null) {
    const zyvo_process_duration = _zyvo_process_duration
      ? _zyvo_process_duration
      : this.state.zyvo_process_duration;
    const competencies_ids = _competencies_ids
      ? _competencies_ids
      : this.state.competencies_ids;
    if (!_.isEmpty(zyvo_process_duration) && !_.isEmpty(competencies_ids))
      return (
        <div className="alert alert-primary mt-4" role="alert">
          <i className="fa-spin far fa-clock mr-1"></i>
          La medición de estas habilidades al candidato le tomará entre{" "}
          <b>{zyvo_process_duration.duration_minutes_min} min</b> y{" "}
          <b>{zyvo_process_duration.duration_minutes_max} min</b>
        </div>
      );
  }

  drawGroupedCompetences(competence_type, group_name) {
    const { competences } = this.props;
    const { competencies_ids } = this.state;
    const grouped_competences = _.groupBy(
      competences.competencies,
      "competency_type"
    );

    const iterator = grouped_competences[`${group_name}`];
    const have_any = _.some(iterator, competence => {
      return _.includes(competencies_ids, competence.id.toString());
    });

    if (!_.isEmpty(competencies_ids) && have_any)
      return (
        <div className="col">
          <div className="card card-hover mb-2">
            <div
              className="card-header bg-white d-flex flex-column justify-content-between"
              style={{ minHeight: "105px" }}
            >
              <div className="d-inline-block d-flex align-items-center">
                <i className="fas fa-brain fa-pull-left h5 mb-0"></i>
                <h3 className="h5 mb-0 d-inline-block">
                  {competences[`es_${competence_type}_title`]}
                </h3>
              </div>
              <p className="small text-muted mb-0 pr-2 pr-sm-0">
                {competences[`es_${competence_type}_description`]}
              </p>
            </div>
            <div className="card-body">
              {grouped_competences[`${group_name}`].map(competence => {
                const _checked = _.includes(
                  competencies_ids,
                  competence.id.toString()
                );
                if (_checked) {
                  return (
                    <div
                      key={competence.name}
                      className="form-group form-check"
                    >
                      <FormGroup>
                        <div>
                          <CustomInput
                            type="switch"
                            disabled={true}
                            checked={_checked}
                            id={competence.id}
                            label={competence.es_description}
                          />
                          <Collapse isOpen={_checked}>
                            <div className="d-flex align-items-center my-2">
                              <CustomInput
                                className="zyvo_range"
                                type="range"
                                disabled={true}
                                readOnly={true}
                                value={this.findScoreByCompetence(
                                  competence.id
                                )}
                                id={`range_${competence.id}`}
                                min="0"
                                max="100"
                              />
                            </div>
                            <div className="row my-2">
                              <small className="col-4 text-left text-primary">
                                {competence.es_low_level}
                              </small>
                              <small className="col-4 text-center text-primary">
                                {`${Math.abs(
                                  2 *
                                    (this.findScoreByCompetence(competence.id) -
                                      50)
                                )}%`}
                              </small>
                              <small className="col-4 text-right text-primary">
                                {competence.es_high_level}
                              </small>
                            </div>
                          </Collapse>
                        </div>
                        <small className="text-muted d-block">
                          {competence.es_tooltip}
                        </small>
                      </FormGroup>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      );
  }

  render() {
    const { attributes, competences } = this.props;
    const { competencies_ids, selected_profile } = this.state;

    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="flaticon-interface-7" />
            </span>
            <h3 className="kt-portlet__head-title">
              Configuración habilidades a Evaluar
              <small>
                <a
                  href="https://tests.4talent.cl"
                  target="_blank"
                  className="text-primary"
                >
                  Filtro Test
                </a>
              </small>
            </h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <input
            type="hidden"
            readOnly={true}
            value={this.state.selected_profile.profile_integration_id || ""}
            name={baseName + "[profile_id]"}
          />
          <input
            type="hidden"
            readOnly={true}
            value={this.state.selected_profile.description || ""}
            name={baseName + "[description]"}
          />
          <input
            type="hidden"
            readOnly={true}
            value={attributes.name}
            name={baseName + "[name]"}
          />
          <input
            type="hidden"
            readOnly={true}
            value={attributes.company}
            name={baseName + "[company]"}
          />
          <input
            type="hidden"
            readOnly={true}
            value="zyvo"
            name={baseName + "[service][name]"}
          />
          <input
            type="hidden"
            value={this.setCompetencies()}
            readOnly={true}
            name={baseName + "[service][competencies]"}
          />
          <div className="row">
            <div className="col-sm-12 my-2">
              <div className="alert alert-outline-info" role="alert">
                <div className="alert-text">
                  <h4 className="alert-heading">Introducción</h4>
                  <p>
                    A continuación podrás{" "}
                    <b>configurar las habilidades a evaluar</b> por la
                    herramienta Zyvo. Para ello debes{" "}
                    <b>seleccionar el perfil deseado</b>.
                  </p>
                  <hr></hr>
                  <p className="mb-0">
                    Si necesitas evaluar otras habilidades, puedes crear otro
                    perfil en el botón.
                  </p>
                </div>
              </div>
              {/* <div className="row d-flex justify-content-center align-items-stretch">
                <div className="col-6 col-sm-6 col-md-4 col-lg-3 text-center d-flex align-items-stretch justify-content-center">
                  <CreateProfile
                    current_user={this.props.current_user}
                    competences={this.props.competences}
                    getProcessDuration={this.getProcessDuration}
                    drawProcessDuration={this.drawProcessDuration}
                  />
                </div>
                <div className="col-6 col-sm-6 col-md-4 col-lg-3 text-center d-flex align-items-stretch justify-content-center">
                  <VyaCreateTest
                    current_user={this.props.current_user}
                    competences={this.props.competences}
                    getProcessDuration={this.getProcessDuration}
                    drawProcessDuration={this.drawProcessDuration}
                  />
                </div>
              </div> */}
              <div className="row">
                <div className="col col-md-10">
                  <Select
                    required
                    onMenuOpen={this.getProfiles}
                    options={this.state.selectable_profiles}
                    placeholder="Seleccione un perfil"
                    onChange={this.handleSelectChange}
                    isLoading={this.state.isLoading}
                  />
                </div>
                <div className="col col-md-2">
                  <CreateProfile
                    current_user={this.props.current_user}
                    competences={this.props.competences}
                    getProcessDuration={this.getProcessDuration}
                    drawProcessDuration={this.drawProcessDuration}
                  />
                </div>
              </div>
              {this.drawDescription()}
            </div>
            {drawCompetence({
              competence_type: "cognitive",
              group_name: "cognitive_skill",
              competences,
              competencies_ids,
              selected_profile,
              type: "project"
            })}
            {drawCompetence({
              competence_type: "personality",
              group_name: "personality_trait",
              competences,
              competencies_ids,
              selected_profile,
              type: "project"
            })}
          </div>
          <div className="d-flex justify-content-center">
            {this.drawProcessDuration()}
          </div>
        </div>
      </div>
    );
  }
}
