import React from "react";
import toasty from "mk-toasty";
import { Progress } from "react-sweet-progress";
import _ from "lodash";
import moment from "moment";
import LazyComponent from "../lazy_component";

export default class Dashboard extends LazyComponent {
  constructor(props) {
    super(props);
    this.state = {
      reviews: props.reviews,
      notifications: props.notifications,
    };
    this.received = this.received.bind(this);
    this.pushItem = this.pushItem.bind(this);
  }

  componentDidMount() {
    var _this = this;
    App.cable.subscriptions.create(
      {
        channel: "DashboardChannel",
      },
      { received: _this.received }
    );
  }

  pushItem({ collection, instance, name }) {
    if (collection.length > 0) {
      var found = collection.findIndex((p) => {
        return p.id == instance.id;
      });
      if (found == -1) {
        collection.unshift(instance);
      } else {
        _.pullAt(collection, [found]);
        collection.unshift(instance);
      }
    } else {
      collection.unshift(instance);
    }
    this.state[name] = collection;
    this.setState(this.state);
  }

  received(data) {
    var { notification, review, reviews, type } = data;
    switch (type) {
      case "notification":
        this.pushItem({
          collection: this.state.notifications,
          instance: notification,
          name: "notifications",
        });
        break;
      case "review":
        this.pushItem({
          collection: this.state.reviews,
          instance: review,
          name: "reviews",
        });
        toasty().trigger();
        break;
      case "reviews":
        this.setState({ reviews });
        break;
    }
  }

  drawReviewing() {
    var reviews = this.state.reviews;

    if (reviews.length > 0) {
      return reviews.map(function (process, index) {
        return (
          <div className="col-lg-2 text-center" key={index}>
            <a href={`${process.id}/draft`}>
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <span className="kt-portlet__head-icon">
                      <i className="flaticon2-graph-1"></i>
                    </span>
                    <h3 className="kt-portlet__head-title">
                      {process.charge_name} - <small>{process.id}</small>
                    </h3>
                  </div>
                </div>
                <div className="kt-portlet__body">
                  <div className="row">
                    <div className="col-md-9">
                      <small className="text-muted d-block text-truncate">
                        {`${process.user_email}`}
                      </small>
                      <small className="text-muted d-block text-truncate">
                        {`${process.name}`}
                      </small>
                      <hr className="mt-0 mb-0" />
                      <small className="text-muted d-inline-block text-truncate w-100">
                        {moment(process.updated_at).format("HH:mm")}
                        {process.portals_abbr}
                      </small>
                    </div>
                    <div className="col-md-3">
                      <div className="mx-auto h-100 d-flex align-items-center justify-content-center">
                        <Progress
                          type="circle"
                          strokeWidth={5}
                          width={70}
                          style={{ height: "auto" }}
                          percent={process.progress}
                          status={status}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        );
      });
    } else {
      return (
        <div className="d-flex align-items-center justify-content-center h-100 w-100 flex-fill text-muted text-center flex-column">
          <i className="far fa-check-circle fa-5x mb-2 d-block" />
          <div>No hay postulaciones por revisar</div>
        </div>
      );
    }
  }

  drawPosting() {
    var notifications = this.state.notifications;

    if (notifications.length > 0) {
      return notifications.map(function (process, index) {
        const status =
          process.progress == 100
            ? "success"
            : process.state == "active"
            ? "active"
            : "error";

        return (
          <div className="col-lg-3 text-left" key={index}>
            <a href={`${process.project_id}`}>
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <span className="kt-portlet__head-icon">
                      <i className="flaticon2-graph-1"></i>
                    </span>
                    <h3 className="kt-portlet__head-title">
                      {process.charge_name} -{" "}
                      <small>{process.project_id}</small>
                    </h3>
                  </div>
                </div>
                <div className="kt-portlet__body">
                  <div className="row">
                    <div className="col-md-9">
                      <small className="text-muted d-block text-truncate">
                        {`${process.user_email}`}
                      </small>
                      <small className="text-muted d-block text-truncate">
                        {`${process.name}`}
                      </small>
                      <hr className="mt-0 mb-0" />
                      <small className="text-muted d-inline-block text-truncate w-100">
                        {moment(process.updated_at).format("HH:mm")}
                        {process.log}
                      </small>
                    </div>
                    <div className="col-md-3">
                      <div className="mx-auto h-100 d-flex align-items-center justify-content-center">
                        <Progress
                          type="circle"
                          strokeWidth={5}
                          width={70}
                          style={{ height: "auto" }}
                          percent={process.progress}
                          status={status}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        );
      });
    } else {
      return (
        <div className="d-flex align-items-center justify-content-center h-100 w-100 flex-fill text-muted text-center flex-column">
          <i className="far fa-check-circle fa-5x mb-2 d-block" />
          <div>No hay publicaciones</div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row justify-content-md-center">
          <div className="col-md-12">
            <h4 className="text-primary">En revisión</h4>
          </div>
          {this.drawReviewing()}
        </div>
        <hr />
        <div className="row justify-content-md-center">
          <div className="col-md-12">
            <h4 className="text-primary">Publicaciones</h4>
          </div>
          {this.drawPosting()}
        </div>
      </div>
    );
  }
}
