import React, { Fragment, useEffect, useState } from "react";
import _ from "lodash";

import {
  DataSearch,
  ReactiveBase,
  SingleList,
  MultiList,
  RatingsFilter,
  MultiDropdownList,
  RangeSlider,
  ReactiveList,
  ReactiveComponent,
} from "@appbaseio/reactivesearch";

import theme from "../reactive_search/theme";
import CustomSelectedFilters from "../reactive_search/custom_selected_filters";

import {
  componentsIds,
  defaultQuery,
  hasProduct,
  transformedBool,
  transformedQuery,
} from "./functions";

import { Col } from 'reactstrap';

import PostulationCard from "../postulation/card";
import PostulationRankCard from "../postulation/card/rank";
import ModalResults from "../postulation/modal_results/index";
import ModalConfiguration from "../postulation/modal_configuration/index";
import axios from "axios";


const CandidateList = ({
  project_id,
  board_config,
  postulations_ids,
  authentication_headers,
  with_salary_question,
  with_years_question,
}) => {
  const projectFilters = board_config;
  const [selectedPostulation, setSelectedPostulation] = useState({});
  const [rangeValues, setRangeValues] = useState(null);
  const [ evaluations, setEvaluations ] = useState(null)

  const getEvaluations = () => {
    axios
    .get(`${window.location.origin}/project/${project_id}/optional_phase/`, {
    }, {headers: authentication_headers})
    .then(res => {
      if(res.data.length !== 0) {
        setEvaluations(res.data)
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  const toCurrency = (data) => {
    return data
      ? data.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
      : data;
  };

  const renderProduct = ({ product, title }) => {
    if (hasProduct({ product, projectFilters })) {
      return (
        <Fragment>
          <div className="p-3">
            <RangeSlider
              componentId={`${product}Score`}
              defaultQuery={defaultQuery(project_id, postulations_ids)}
              dataField={`${product}.score`}
              title={`Puntaje ${title}`}
              filterLabel={`Puntaje ${title}`}
              range={{
                start: 0,
                end: 100,
              }}
              defaultValue={{
                start: 0,
                end: 100,
              }}
              rangeLabels={{
                start: "0 pts",
                end: "100 pts",
              }}
              tooltipTrigger={"hover"}
              stepValue={1}
              showHistogram={true}
              showFilter={true}
              react={{
                and: componentsIds(`${product}Score`),
              }}
            />
          </div>

          <div className="p-3">
            <MultiList
              componentId={`${product}State`}
              defaultQuery={defaultQuery(project_id, postulations_ids)}
              dataField={`${product}.status`}
              title={`Estado ${title}`}
              sortBy="asc"
              queryFormat="or"
              selectAllLabel="Todos los Estados"
              showCheckbox={true}
              showCount={true}
              showSearch={false}
              showFilter={true}
              filterLabel="Estados"
              loader="Cargando ..."
              react={{
                and: componentsIds(`${product}State`),
              }}
            />
          </div>
        </Fragment>
      );
    }
  };

  useEffect(() => {
    if(_.isNull(evaluations)) {
      getEvaluations()
    }
  }, [ evaluations ])


  return (
    <ReactiveBase
      app="postulations"
      url={`${window.location.origin}/elastic`}
      theme={theme}
      headers={{
        "Content-Type": "application/json",
        ...authentication_headers,
      }}
      transformRequest={(data) => {
        return { ...data, body: JSON.stringify(data.body) };
      }}
    >
      <ModalResults
        selectedPostulation={selectedPostulation}
        setSelectedPostulation={setSelectedPostulation}
        authentificationHeaders={authentication_headers}
      />

      {with_salary_question || with_years_question ? (
        <ReactiveComponent
          componentId="rangeValuesAggs"
          defaultQuery={defaultQuery(project_id, postulations_ids)}
          customQuery={() => ({
            timeout: "10s",
            aggregations: {
              min_age: { min: { field: "age" } },
              min_salary: { min: { field: "salary_expectation" } },
              min_years: { min: { field: "experience_years" } },
              max_age: { max: { field: "age" } },
              max_salary: { max: { field: "salary_expectation" } },
              max_years: { max: { field: "experience_years" } },
            },
            size: 0,
          })}
          onData={({ aggregations }) => {
            if (aggregations && !rangeValues) setRangeValues(aggregations);
          }}
        />
      ) : null}

      <ReactiveList
        componentId="rankPostulations"
        dataField={"applicant.name"}
        showResultStats={false}
        size={3}
        infiniteScroll={false}
        scrollOnChange={false}
        pagination={false}
        defaultQuery={() => {
          return {
            sort: [
              { rating: { order: "desc" } },
              { global_score: { order: "desc" } },
            ],
            query: {
              bool: {
                must: [
                  { term: { "project.id": project_id } },
                  { term: { discarded: false } },
                  { terms: { _id: postulations_ids } },
                ],
              },
            },
          };
        }}
        loader="Cargando ..."
        react={{
          and: ["postulationsResult"],
        }}
      >
        {({ data }) => (
          <div className="kt-portlet kt-iconbox kt-iconbox--brand kt-iconbox--animate-fast p-0">
            <div className="kt-portlet__head kt-portlet__head--noborder">
              <div className="kt-portlet__head-label">
                <span className="kt-portlet__head-icon">
                  <i className="flaticon-map"></i>
                </span>
                <h3 className="kt-portlet__head-title">Candidatos en Terna</h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div className="row">
                {data.map((item) => (
                  <PostulationRankCard
                    key={item._id}
                    postulation={item}
                    setSelectedPostulation={setSelectedPostulation}
                    authentificationHeaders={authentication_headers}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </ReactiveList>

      <div className="row">
        <div className="col-md-12">
          <CustomSelectedFilters />
        </div>
        {/*<Col xs={"6"}>*/}
        {/*  /!*TODO: refactor with component from reactstrap modal with button*!/*/}
        {/*  <div className="text-right">*/}
        {/*    <div className="dropdown dropdown-inline">*/}
        {/*      <button*/}
        {/*        className={"btn btn-danger btn-sm btn-upper"}*/}
        {/*        data-toggle={"dropdown"}*/}
        {/*        aria-haspopup={true}*/}
        {/*        aria-expanded={true}*/}
        {/*      >*/}
        {/*        Configuracion de Evaluación*/}
        {/*      </button>*/}
        {/*      <div className="dropdown-menu dropdown-menu-right">*/}
        {/*        <ul className="kt-nav">*/}
        {/*          <li className="kt-nav__item">*/}
        {/*            <ModalConfiguration*/}
        {/*              type="vya"*/}
        {/*              projectID={project_id}*/}
        {/*              authentificationHeaders={authentication_headers}*/}
        {/*              evaluations={evaluations}*/}
        {/*            />*/}
        {/*          </li>*/}
        {/*          <li className="kt-nav__item">*/}
        {/*            <ModalConfiguration*/}
        {/*              type="zyvo"*/}
        {/*              projectID={project_id}*/}
        {/*              authentificationHeaders={authentication_headers}*/}
        {/*              evaluations={evaluations}*/}
        {/*            />*/}
        {/*          </li>*/}
        {/*        </ul>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Col>*/}
        <div className="col-sm-12 col-md-12 col-lg-3 col-xl-2 pt-5 mt-2">
          <div className="p-3">
            <DataSearch
              innerClass={{
                input: "form-control",
              }}
              style={{ minWidth: "200px" }}
              defaultQuery={defaultQuery(project_id, postulations_ids)}
              componentId="searchPostulation"
              dataField={[
                "applicant.name",
                "applicant.rut",
                "applicant.email",
                "applicant.phone_number",
              ]}
              placeholder="Buscar por nombre / rut / email / número"
              highlight={true}
              highlightField="applicant.name"
              autosuggest={false}
              fuzziness={2}
              debounce={500}
              react={{
                and: componentsIds("searchPostulation"),
              }}
              filterLabel="Buscar postulante"
            />
          </div>

          <div className="p-3">
            <MultiDropdownList
              innerClass={{
                select: "form-control",
              }}
              style={{ minWidth: "200px" }}
              defaultQuery={defaultQuery(project_id, postulations_ids)}
              componentId="universitiesSearch"
              dataField="study_data.description"
              showSearch={true}
              sortBy="count"
              showCount={true}
              placeholder="Buscar por estudios"
              showFilter={true}
              filterLabel="Estudios"
              loader="Cargando ..."
              react={{
                and: componentsIds("universitiesSearch"),
              }}
            />
          </div>

          {/* Kanban State Filter. Before: reindex kanban_state as keyword */}
          <div className="p-3">
            <SingleList
              componentId="kanbanState"
              defaultQuery={defaultQuery(project_id, postulations_ids)}
              dataField="kanban_state.es"
              title="Etapas"
              selectAllLabel="Todas las etapas"
              defaultValue="Todas las etapas"
              showRadio={true}
              showCount={true}
              showSearch={false}
              showFilter={true}
              filterLabel="Etapas"
              includeNullValues
              loader="Cargando ..."
              react={{
                and: componentsIds("kanbanState"),
              }}
            />
          </div>

          <div className="p-3">
            <RangeSlider
              componentId="globalScore"
              defaultQuery={defaultQuery(project_id, postulations_ids)}
              dataField="global_score"
              title="Puntaje Global"
              filterLabel="Puntaje Global"
              range={{
                start: 0,
                end: 100,
              }}
              defaultValue={{
                start: 0,
                end: 100,
              }}
              rangeLabels={{
                start: "0 pts",
                end: "100 pts",
              }}
              tooltipTrigger={"hover"}
              stepValue={1}
              showHistogram={true}
              showFilter={true}
              react={{
                and: componentsIds("globalScore"),
              }}
            />
          </div>

          <div className="p-3">
            <RangeSlider
              componentId="cvScore"
              defaultQuery={defaultQuery(project_id, postulations_ids)}
              dataField="cv_score"
              title="Match CV"
              filterLabel="Match CV"
              range={{
                start: 0,
                end: 100,
              }}
              defaultValue={{
                start: 0,
                end: 100,
              }}
              rangeLabels={{
                start: "0 pts",
                end: "100 pts",
              }}
              tooltipTrigger={"hover"}
              stepValue={1}
              showHistogram={true}
              showFilter={true}
              react={{
                and: componentsIds("cvScore"),
              }}
            />
          </div>

          <div className="p-3">
            {rangeValues && _.isNumber(rangeValues.min_age.value) ? (
              <RangeSlider
                componentId="ageRange"
                defaultQuery={defaultQuery(project_id, postulations_ids)}
                dataField="age"
                title="Edad"
                filterLabel="Edad"
                range={{
                  start: rangeValues.min_age.value,
                  end: rangeValues.max_age.value,
                }}
                defaultValue={{
                  start: rangeValues.min_age.value,
                  end: rangeValues.max_age.value,
                }}
                rangeLabels={{
                  start: `${rangeValues.min_age.value} años`,
                  end: `${rangeValues.max_age.value} años`,
                }}
                tooltipTrigger={"hover"}
                stepValue={1}
                showHistogram={true}
                showFilter={true}
                react={{
                  and: componentsIds("ageRange"),
                }}
                URLParams={true}
              />
            ) : null}
          </div>

          <div className="p-3">
            {with_salary_question &&
            rangeValues &&
            _.isNumber(rangeValues.min_salary.value) ? (
              <RangeSlider
                componentId="salaryRange"
                defaultQuery={defaultQuery(project_id, postulations_ids)}
                dataField="salary_expectation"
                title="Expectativas de renta"
                filterLabel="Expectativas de renta"
                range={{
                  start: rangeValues.min_salary.value,
                  end: rangeValues.max_salary.value,
                }}
                defaultValue={{
                  start: rangeValues.min_salary.value,
                  end: rangeValues.max_salary.value,
                }}
                rangeLabels={{
                  start: `$ ${toCurrency(rangeValues.min_salary.value)}`,
                  end: `$ ${toCurrency(rangeValues.max_salary.value)}`,
                }}
                tooltipTrigger={"hover"}
                renderTooltipData={(data) => toCurrency(data)}
                stepValue={50000}
                showHistogram={true}
                showFilter={true}
                react={{
                  and: componentsIds("salaryRange"),
                }}
                URLParams={true}
              />
            ) : null}
          </div>

          <div className="p-3">
            {with_years_question &&
            rangeValues &&
            _.isNumber(rangeValues.min_years.value) ? (
              <RangeSlider
                componentId="yearsRange"
                defaultQuery={defaultQuery(project_id, postulations_ids)}
                dataField="experience_years"
                title="Años de experiencia"
                filterLabel="Años de experiencia"
                range={{
                  start: rangeValues.min_years.value,
                  end: rangeValues.max_years.value,
                }}
                defaultValue={{
                  start: rangeValues.min_years.value,
                  end: rangeValues.max_years.value,
                }}
                rangeLabels={{
                  start: `${rangeValues.min_years.value} años`,
                  end: `${rangeValues.max_years.value} años`,
                }}
                tooltipTrigger={"hover"}
                stepValue={1}
                showHistogram={true}
                showFilter={true}
                react={{
                  and: componentsIds("yearsRange"),
                }}
                URLParams={true}
              />
            ) : null}
          </div>

          <div className="p-3">
            <SingleList
              defaultQuery={defaultQuery(project_id, postulations_ids)}
              componentId="genderList"
              dataField="applicant.gender"
              title="Géneros"
              selectAllLabel="Todos los Géneros"
              showRadio={true}
              showCount={true}
              showSearch={false}
              showFilter={true}
              filterLabel="Género"
              includeNullValues
              loader="Cargando ..."
              react={{
                and: componentsIds("genderList"),
              }}
            />
          </div>

          <div className="p-3">
            <SingleList
              defaultQuery={defaultQuery(project_id, postulations_ids)}
              customQuery={(value, props) =>
                transformedQuery({ value, field: "discarded" })
              }
              transformData={(list) => transformedBool(list)}
              componentId="discardedList"
              title="Descartado"
              defaultValue="No"
              dataField="discarded"
              showRadio={true}
              showCount={true}
              showSearch={false}
              showFilter={true}
              filterLabel="Descartado"
              includeNullValues
              loader="Cargando ..."
              react={{
                and: componentsIds("discardedList"),
              }}
            />
          </div>

          <div className="p-3">
            <SingleList
              defaultQuery={defaultQuery(project_id, postulations_ids)}
              customQuery={(value, props) =>
                transformedQuery({ value, field: "pinned" })
              }
              transformData={(list) => transformedBool(list)}
              componentId="pinnedList"
              title="Favoritos"
              dataField="pinned"
              showRadio={true}
              showCount={true}
              showSearch={false}
              showFilter={true}
              filterLabel="Favoritos"
              loader="Cargando ..."
              react={{
                and: componentsIds("pinnedList"),
              }}
            />
          </div>

          <div className="p-3">
            <SingleList
              defaultQuery={defaultQuery(project_id, postulations_ids)}
              customQuery={(value, props) =>
                transformedQuery({ value, field: "own_cv" })
              }
              transformData={(list) => transformedBool(list)}
              componentId="ownCvList"
              title="Tiene CV"
              dataField="own_cv"
              showRadio={true}
              showCount={true}
              showSearch={false}
              showFilter={true}
              filterLabel="Tiene Cv"
              includeNullValues
              loader="Cargando ..."
              react={{
                and: componentsIds("ownCvList"),
              }}
            />
          </div>

          <div className="p-3">
            <SingleList
              defaultQuery={defaultQuery(project_id, postulations_ids)}
              customQuery={(value, props) =>
                transformedQuery({ value, field: "is_new" })
              }
              transformData={(list) => transformedBool(list)}
              componentId="isNewList"
              title="Nuevo Candidato"
              dataField="is_new"
              showRadio={true}
              showCount={true}
              showSearch={false}
              showFilter={true}
              filterLabel="Nuevo Candidato"
              includeNullValues
              loader="Cargando ..."
              react={{
                and: componentsIds("isNewList"),
              }}
            />
          </div>

          <div className="p-3">
            <MultiList
              componentId="globalState"
              defaultQuery={defaultQuery(project_id, postulations_ids)}
              dataField="global_state.es"
              title="Estado Candidatos"
              sortBy="asc"
              queryFormat="or"
              selectAllLabel="Todos los Estados"
              showCheckbox={true}
              showCount={true}
              showSearch={false}
              showFilter={true}
              filterLabel="Estados"
              loader="Cargando ..."
              react={{
                and: componentsIds("globalState"),
              }}
            />
          </div>

          <div className="p-3">
            <MultiList
              className={"multi_list-h"}
              defaultQuery={defaultQuery(project_id, postulations_ids)}
              componentId="portalList"
              dataField="portal.name"
              title="Portales"
              sortBy="asc"
              queryFormat="or"
              selectAllLabel="Todos los Portales"
              showCheckbox={true}
              showCount={true}
              showSearch={false}
              showFilter={true}
              filterLabel="Portales"
              loader="Cargando ..."
              react={{
                and: componentsIds("portalList"),
              }}
            />
          </div>

          <div className="p-3">
            <RatingsFilter
              defaultQuery={defaultQuery(project_id, postulations_ids)}
              componentId="ratingList"
              dataField="rating"
              title="Estrellas"
              filterLabel="Estrellas"
              data={[
                { start: 4, end: 5, label: "+4" },
                { start: 3, end: 5, label: "+3" },
                { start: 2, end: 5, label: "+2" },
                { start: 1, end: 5, label: "+1" },
              ]}
              includeNullValues
              react={{
                and: componentsIds("ratingList"),
              }}
            />
          </div>

          <hr />

          {renderProduct({ product: "shortlist", title: "Filtro Curricular" })}
          {renderProduct({ product: "zyvo", title: "Tests" })}
          {renderProduct({ product: "interview", title: "Entrevista" })}
        </div>

        <div className="col-sm-12 col-md-12 col-lg-9 col-xl-10">
          <ReactiveList
            innerClass={{ resultsInfo: "m-2", sortOptions: "m-2" }}
            componentId="postulationsResult"
            dataField={"applicant.name"}
            defaultQuery={defaultQuery(project_id, postulations_ids)}
            scrollOnChange={false}
            pagination={true}
            paginationAt="bottom"
            loader="Cargando ..."
            renderItem={(item) => (
              <PostulationCard
                key={item._id}
                postulation={item}
                projectFilters={projectFilters}
                setSelectedPostulation={setSelectedPostulation}
                authentificationHeaders={authentication_headers}
                with_salary_question={with_salary_question}
                with_years_question={with_years_question}
                evaluations={evaluations}
              />
            )}
            sortOptions={[
              {
                label: "Puntaje Global Descendiente",
                dataField: "global_score",
                sortBy: "desc",
              },
              {
                label: "Puntaje Global Ascendiente",
                dataField: "global_score",
                sortBy: "asc",
              },
              {
                label: "Match CV Descendiente",
                dataField: "cv_score",
                sortBy: "desc",
              },
              {
                label: "Match CV Ascendiente",
                dataField: "cv_score",
                sortBy: "asc",
              },
              {
                label: "Estrellas Descendiente",
                dataField: "rating",
                sortBy: "desc",
              },
              {
                label: "Estrellas Ascendiente",
                dataField: "rating",
                sortBy: "asc",
              },
              {
                label: "Filtro Curricular Descendiente",
                dataField: "shortlist.score",
                sortBy: "desc",
              },
              {
                label: "Filtro Curricular Ascendiente",
                dataField: "shortlist.score",
                sortBy: "asc",
              },
              {
                label: "Tests Descendiente",
                dataField: "zyvo.score",
                sortBy: "desc",
              },
              {
                label: "Tests Ascendiente",
                dataField: "zyvo.score",
                sortBy: "asc",
              },
            ]}
            renderResultStats={(stats) => (
              <small className="text-primary">{`Mostrando ${stats.displayedResults} de ${stats.numberOfResults}`}</small>
            )}
            react={{
              and: componentsIds("postulationsResult"),
            }}
          />
        </div>
      </div>

      {/* END CANDIDATES LIST */}
    </ReactiveBase>
  );
};

export default CandidateList;
