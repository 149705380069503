import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon
} from "react-share";
import ShowNotification from "../show_notification";
import _ from 'lodash';
import axios from "axios";


const Portals = props => {
  const { is_admin, is_dev, is_reposteable, project_id, current_user, publications, rss_url } = props

  const handleCopyURL = event => {
    event.preventDefault()
    navigator.clipboard.writeText(rss_url)
    ShowNotification({
      message: "Se ha copiado la URL al portapapeles",
      type: "success"
    });
  }

  const handleClickRepost = (event, children_id) => {
    event.preventDefault();
    const headers = {
      "X-User-Email": current_user.email,
      "X-User-Token": current_user.authentication_token
    };

    axios
      .post(
        `${window.location.origin}/publications/${children_id}/repost`,
        "",
        { headers: headers }
      )
      .then(response => {
        ShowNotification({
          message: "Estamos procesando su solicitud para republicar",
          type: "success"
        });
      })
      .catch(error => {
        ShowNotification({
          message: "Vuelva a intentar",
          type: "error"
        });
        console.log(error)
      });
  }

  return(
    <>
      { publications &&
      <>
        <Row>
          { publications && publications.map( (portal, index) => 
            <Col md="3" key={`${portal.id}-${index}`} className="mb-2">
              <div className="h-100 border-0 d-flex my-2 py-2 flex-column justify-content-center align-items-center" style={{boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)"}}>
                <label id="label_miterna"
                  className="w-100 flex-fill text-center d-flex flex-column justify-content-center align-items-center"
                >
                  <img 
                    className="rounded-lg d-block p-2 img-fluid active"
                    width="120"
                    height="120"
                    src={portal.image}
                  />
                </label>
                <div className="d-flex justify-content-center py-1">
                  { portal.readable_state && portal.readable_state.state && 
                    <>
                      { portal.readable_state.state === "pending" &&
                        <div className="d-flex justify-content-center py-1">
                          <div className="px-1">
                            <a
                              className="btn btn-warning btn-sm"
                              disabled
                              style={{ color: "white", cursor: "unset" }}
                            >
                              Pendiente para Publicar
                            </a>
                          </div>
                        </div>
                      }

                      { portal.readable_state.state === "processing" &&
                        <div className="d-flex justify-content-center py-1">
                          <div className="px-1">
                            <a
                              className="btn btn-info btn-sm"
                              disabled
                              style={{ color: "white", cursor: "unset" }}
                            >
                              Estamos publicando su proceso
                            </a>
                          </div>
                        </div>
                      }

                      { portal.readable_state.state === "published" &&
                        <div className="py-1">
                          { portal.readable_state.portal.private_url &&
                            <div className="px-1 d-inline-block">
                              <a
                                className="btn btn-label-success btn-sm"
                                href={ portal.readable_state.portal.private_url }
                                target="_blank"
                              >
                                Vista Gestión
                              </a>
                            </div>
                          }

                          { portal.readable_state.portal.public_url && 
                            <div className="px-1 d-inline-block">
                              <a
                                className="btn btn-label btn-sm"
                                href={ portal.readable_state.portal.public_url }
                                target="_blank"
                              >
                                Ir a Publicación
                              </a>
                            </div>
                          }
                        </div>
                      }

                      { portal.readable_state.state === "closed" &&
                        <>
                          <div className="px-1 d-inline-block">
                            <a
                              className="btn btn-label-success btn-sm"
                              href={ portal.readable_state.portal.private_url }
                              target="_blank"
                            >
                              Vista Gestión
                            </a>
                          </div>
                          { portal.readable_state.portal.public_url ? 
                            <div className="px-1 d-inline-block">
                              <a
                                className="btn btn-label"
                                href={ portal.readable_state.portal.public_url }
                                target="_blank"
                              >
                                Ir a Publicación
                              </a>
                            </div>
                            :
                            <div>
                              <div className="btn btn-label-danger">Cerrada</div>
                            </div>
                          }

                          { is_dev && is_reposteable &&
                            <div className="py-1">
                              <button
                                onClick={ event => handleClickRepost(event, portal.children_id) }
                                className="btn btn-label-success btn-sm"
                              >
                                Republicar
                              </button>
                            </div>
                          }
                        </>
                      }

                      { portal.readable_state.state === "rejected" &&
                        <div className="d-flex justify-content-center py-1">
                          <div className="px-1">
                            <a
                              className="btn btn-danger"
                              disabled
                              style={{ color: "white", cursor: "unset" }}
                            >
                              Esta publicación no cumple con nuestras políticas
                            </a>
                          </div>
                        </div>
                      }

                      { portal.readable_state.state === "failed" &&
                        <div className="d-flex justify-content-center py-1">
                          <div className="px-1">
                            <a
                              className="btn btn-label-warning"
                              target="_blank"
                              onClick={ 
                                is_admin ?
                                  () => window.location = `${window.location.href}/retry?portal_name=${publication.name}`
                                  :
                                  () => false
                              }
                            >
                              Reintentar Publicación
                            </a>
                          </div>
                        </div>
                      }
                    </>
                  }
                </div>
              </div>
            </Col>
          )}
        </Row>
        <div className="card mt-4">
          <div className="d-flex justify-content-between card-header">
            <h4>Comparte tu publicacion en tus redes sociales</h4>
            <div className="d-flex justify-content-end">
              <p className="px-2 pt-2">Copiar a portapapeles</p>
              <a style={{cursor: "pointer"}} 
                onClick={event => handleCopyURL(event)}
              >
                <span className="fa-stack" style={{verticalAlign: "top", lineHeight: "2.5em"}}>
                  <i className="fas fa-circle fa-stack-2x" style={{fontSize: "2.6em"}}></i>
                  <i className="fas fa-clone fa-stack-1x fa-inverse"></i>
                </span>
              </a>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <Col md="3">
                <div className="border-0 d-flex my-2 py-3 flex-column justify-content-center align-items-center" style={{boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)"}}>
                  <LinkedinShareButton
                    url={rss_url}
                  >
                    <LinkedinIcon size={120} round />
                  </LinkedinShareButton>
                </div>
              </Col>
              <Col md="3">
                <div className="border-0 d-flex my-2 py-3 flex-column justify-content-center align-items-center" style={{boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)"}}>
                  <FacebookShareButton
                    url={rss_url}
                  >
                    <FacebookIcon size={120} round />
                  </FacebookShareButton>
                </div>
              </Col>
              <Col md="3">
                <div className="border-0 d-flex my-2 py-3 flex-column justify-content-center align-items-center" style={{boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)"}}>
                  <TwitterShareButton
                    url={rss_url}
                  >
                    <TwitterIcon size={120} round />
                  </TwitterShareButton>
                </div>
              </Col>
              <Col md="3">
                <div className="border-0 d-flex my-2 py-3 flex-column justify-content-center align-items-center" style={{boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)"}}>
                  <WhatsappShareButton
                    url={rss_url}
                  >
                    <WhatsappIcon size={120} round />
                  </WhatsappShareButton>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    }
    </>
  )
}

export default Portals
