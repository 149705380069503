import React, { Component } from "react";

export default class LazyComponent extends Component {
  constructor(props) {
    super(props);
    var foos = Object.getOwnPropertyNames(this.__proto__);
    foos.forEach(
      function(foo) {
        this[foo] = this[foo].bind(this);
      }.bind(this)
    );
  }
}
