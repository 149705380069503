import React, { useEffect, useState } from "react";
import Select from "react-select";
import { 
  Button, Form, FormGroup, Label, Input, FormText, Collapse, Container, Row,
  CustomInput, Spinner, Col
} from "reactstrap";
import axios from "axios";
import _ from "lodash";
import ShowNotification from "../../show_notification";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


const zyvo = props => {
  const {
    authentificationHeaders,
    evaluation,
    competences,
    profiles,
    projectId,
  } = props

  const MySwal = withReactContent(Swal)

  const [ profileSetting, setProfileSetting ] = useState(profiles)
  const [ profileSelect, setProfileSelect ] = useState([])
  const [ selectedProfile, setSelectedProfile ] = useState(null)
  const [ personalityTrait, setPersonalityTrait ] = useState([])
  const [ cognitiveSkill, setCognitiveSkill ] = useState([])
  const [ personalityTraitFromProfile, setPersonalityTraitFromProfile ] = useState([])
  const [ cognitiveSkillFromProfile, setCognitiveSkillFromProfile ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)
  const [ selectedCognitiveSkill, setSelectedCognitiveSkill ] = useState([])
  const [ selectedPersonalityTrait, setSelectedPersonalityTrait ] = useState([])
  const [ collapseCognitiveSkill, setCollapseCognitiveSkill ] = useState([])
  const [ collapsePersonality, setCollapsePersonality ] = useState([])
  const [ openNewProfile, setOpenNewProfile ] = useState(false)
  const [ openExistProfile, setOpenExistProfile ] = useState(false)
  const [ addingProfileLoad, setAddingProfileLoad ] = useState(false)
  const [ haveEvaluation, setHaveEvaluation ] = useState(evaluation !== null ? evaluation : null)

  const toggle = () => setOpenNewProfile(!openNewProfile)

  const handledShowPercent = (competenceID, competenceType) => {
    let obtainScore

    if( competenceType.includes("cognitive_skill") ) {
      obtainScore = selectedCognitiveSkill.find( find => find.competence_id === competenceID )
      if(!_.isUndefined(obtainScore)) {
        return obtainScore.score
      }
    }
    
    if( competenceType.includes("personality_trait") ) {
      obtainScore = selectedPersonalityTrait.find( find => find.competence_id === competenceID )
      if(!_.isUndefined(obtainScore)) {
        return obtainScore.score
      }
    }
  }

  const showPercent = score => {
    score = score.toString()
    let format_score = parseInt(score)
    let range = Math.abs((format_score * 2) - 100)
    return range
  }
  
  const handleSwitch = (event, competenceID, competenceType, collapseIndex) => {
    let collapse

    if(event.target.checked) {
      if( competenceType.includes("cognitive_skill") ) {
        collapse = [...collapseCognitiveSkill]
        collapse[collapseIndex] = !collapse[collapseIndex]
        setCollapseCognitiveSkill(collapse)

        setSelectedCognitiveSkill([
          ...selectedCognitiveSkill, { competence_id: competenceID, score: 0, value: 0 }
        ])
      }

      if( competenceType.includes("personality_trait") ) {
        collapse = [...collapsePersonality]
        collapse[collapseIndex] = !collapse[collapseIndex]
        setCollapsePersonality(collapse)

        setSelectedPersonalityTrait([
          ...selectedPersonalityTrait, { competence_id: competenceID, score: 0, value: 0 }
        ])
      }
    } else {
      if( competenceType.includes("cognitive_skill") ) {
        collapse = [...collapseCognitiveSkill]
        collapse[collapseIndex] = !collapse[collapseIndex]
        setCollapseCognitiveSkill(collapse)

        setSelectedCognitiveSkill([
          ...selectedCognitiveSkill.filter(
            remove_cognitive_skill => remove_cognitive_skill.competence_id !== competenceID
          )
        ])
      }
      
      if( competenceType.includes("personality_trait") ) {
        collapse = [...collapsePersonality]
        collapse[collapseIndex] = !collapse[collapseIndex]
        setCollapsePersonality(collapse)

        setSelectedPersonalityTrait([
          ...selectedPersonalityTrait.filter(
            remove_personality_trait => remove_personality_trait.competence_id !== competenceID
          )
        ])
      }
    }
  }

  const handledNumberPorcent = (event, competenceID, competenceType) => {
    let filterCompetence
    let range = Math.abs( 2 * (parseInt(event.target.value) - 50) )

    if( competenceType.includes("cognitive_skill") ) {
      filterCompetence = selectedCognitiveSkill.filter(filter => {
        if(filter.competence_id === competenceID) {
          filter.value = parseInt(event.target.value)
          return filter.score = range
        }
      })
      
      setSelectedCognitiveSkill([
        ...selectedCognitiveSkill.filter( filter => filter.competence_id !== filterCompetence )
      ])
    }
    
    if( competenceType.includes("personality_trait") ) {
      filterCompetence = selectedPersonalityTrait.filter(filter => {
        if(filter.competence_id === competenceID) {
          filter.value = parseInt(event.target.value)
          return filter.score = range
        }
      })

      setSelectedPersonalityTrait([
        ...selectedPersonalityTrait.filter( filter => filter.competence_id !== filterCompetence )
      ])
    }
  }

  const handleSelect = event => {
    setSelectedProfile(
      profileSetting.find( findProfile => findProfile.id === event.value )
    )
    setOpenExistProfile(true)
    setOpenNewProfile(false)
  }

  const showAlert = () => {
    MySwal.fire({
      title: 'Guardada la Configuración de Evaluación Psicolaboral',
      type: 'success',
    }).then( result => {
      if(result.value) window.location.reload()
    });
  }

  const handleSelectedProfile = (event, projectId) => {
    setAddingProfileLoad(true)
    event.preventDefault()
    axios
      .post(`${window.location.origin}/project/optional_phase`, {
        project_id: projectId,
        selected_profile: selectedProfile,
        type: "zyvo"
      }, {headers: authentificationHeaders})
      .then(response => {
        if(response.status === 201) {
          setIsLoading(false)
          showAlert()
          window.scrollTo(0, 0)
        } else {
          ShowNotification({
            message: "Ups, ocurrio un error. Vuelve a Intentar...",
            type: "error"
          })
        }
      })
      .catch(error => {
        setIsLoadingButton(false)
        ShowNotification({
          message: `Ha Ocurrido un Error, intente nuevamente...`,
          type: "error"
        })
        console.log(error)
      });
  }

  const addProfile = async event => {
    setAddingProfileLoad(true)
    event.preventDefault()
    const data = new FormData(event.target)
    await axios
      .post(`${window.location.origin}/zyvo/profiles`, {
        profile_name: data.get("profile_name"),
        profile_description: data.get("profile_description"),
        cognitive_skill: selectedCognitiveSkill,
        personality_trait: selectedPersonalityTrait,
      }, {headers: authentificationHeaders})
      .then(res => {
        if( res.status === 201 ) {
          setHaveEvaluation({
            type: "zyvo",
            attributes: { 
              id: res.data.data.id,
              name: res.data.data.name,
              competencies: res.data.data.competencies
            }
          })
          
          setAddingProfileLoad(false)
          ShowNotification({
            message: `Se ha creado el nuevo perfil ${data.get("profile_name")}.`,
            type: "success"
          });
          setOpenExistProfile(true)
          setOpenNewProfile(false)
          
          setProfileSelect([ 
            { value: res.data.data.id, label: res.data.data.name },
            ...profileSelect 
          ])
          setSelectedProfile(res.data.data)
          fetchProfiles()
        } else {
          ShowNotification({
            message: `Ha ocurrido un Error al crear el perfil, intente nuevamente...`,
            type: "error"
          })
          setAddingProfileLoad(false)
        }
      })
      .catch(error => {
        setAddingProfileLoad(false)
        ShowNotification({
          message: `Ha ocurrido un Error al crear el perfil, intente nuevamente...`,
          type: "error"
        })
        console.log(error)
      });
  }

  const fetchProfiles = async () => {
    axios
    .get(`${window.location.origin}/zyvo/profiles`, { headers: authentificationHeaders })
    .then( res => {
      setProfileSetting(res.data.data)
    })
    .catch( error => {
      console.log(error)
    })
  }

  useEffect(() => {
    profileSetting.forEach( profile =>
      setProfileSelect(
        profileSelect => 
        [...profileSelect,
          { value: profile.id, label: profile.name }
        ]
      )
    )

    setCognitiveSkill(
      competences.competencies.filter(
        cognitiveSkill => cognitiveSkill.competency_type === "cognitive_skill"
      )
    )

    setPersonalityTrait(
      competences.competencies.filter(
        personalityTrait => personalityTrait.competency_type === "personality_trait"
      )
    )
  }, [ profileSetting ])

  useEffect(() => {
    if( selectedProfile !== null ) {
      setCognitiveSkillFromProfile(
        cognitiveSkill.filter( filterSkill => selectedProfile.competencies.find(({id}, index, item) => {
          filterSkill.score = item[index].score
          return filterSkill.id === id
        }))
      )
  
      setPersonalityTraitFromProfile(
        personalityTrait.filter( filterPersonality => selectedProfile.competencies.find(({id}, index, item) => {
          filterPersonality.score = item[index].score
          return filterPersonality.id === id
        }))
      )
    } else {
      if( (evaluation !== null && evaluation !== undefined) && ( profileSetting && profileSetting.length !== 0 ) ) {
        setSelectedProfile(
          profileSetting.find( findProfile => findProfile.id === evaluation.attributes.id)
        )
        setOpenExistProfile(true)
        setOpenNewProfile(false)
      }
    }
  }, [ selectedProfile, profileSetting, openNewProfile , competences ])


	return(
		<div className="kt-portlet kt-portlet--mobile">
			<div className="kt-portlet__head">
				<div className="kt-portlet__head-label">
					<span className="kt-portlet__head-icon">
						<i className="flaticon-interface-7" />
					</span>
					<h3 className="kt-portlet__head-title">
						Configuración habilidades a Evaluar
						<small>
							<a
								href="https://tests.4talent.cl"
								target="_blank"
								className="text-primary"
							>
								Filtro Test
							</a>
						</small>
					</h3>
				</div>
			</div>
			<Container>
				<Row>
					<Col xs="12" className="my-2">
						<div className="alert alert-outline-info" role="alert">
							<div className="alert-text">
								<h4 className="alert-heading">Introducción</h4>
								<p>
									A continuación podrás{" "}
									<b>configurar las habilidades a evaluar</b> por la
									herramienta Zyvo. Para ello debes{" "}
									<b>seleccionar el perfil deseado</b>.
								</p>
								<hr></hr>
								<p className="mb-0">
									Si necesitas evaluar otras habilidades, puedes crear otro
									perfil en el botón.
								</p>
							</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs="10">
            <Select
              required
              isDisabled={isLoading ? isLoading : openNewProfile }
              placeholder="Seleccione un perfil"
              options={profileSelect}
              onChange={handleSelect}
              isLoading={isLoading}
            />
					</Col>
					<Col xs="2">
            <Button
              disabled={isLoading}
							className={openNewProfile ? "btn btn-label-warning btn-block" : "btn btn-label-primary btn-block" }
              onClick={toggle}
						>
              { openNewProfile ?
                "Seleccionar Perfil"
                :
                "Crear Perfil"
              }
						</Button>
					</Col>
				</Row>
				
				<Collapse
					className="mt-2 my-4"
					isOpen={openNewProfile || openExistProfile}
				>
					<Row>
						<Col xs="12">
							<Form onSubmit={openNewProfile ? event => addProfile(event) : event => handleSelectedProfile(event, projectId) }>
                { openNewProfile &&       
                  <div className="alert alert-outline-info" role="alert">
                    <div className="alert-text">
                      <p>
                        A continuación podrás <b>crear un nuevo perfil</b> para la
                        herramienta Zyvo.
                      </p>
                      <hr></hr>
                      <p className="mb-0">
                        Debes <b>seleccionar las habilidades</b> a evaluar y luego ajustar
                        el <b> resultado esperado</b> utilizando la barra.
                      </p>
                    </div>
                  </div>
                }
								<FormGroup>
									<Label for="zyvo_profile_name">Nombre</Label>
                  { selectedProfile !== null && !openNewProfile ?
                    <>
                      <Input
                        type="text"
                        id="zyvo_profile_name"
                        name="profile_name"
                        defaultValue={selectedProfile.name }
                        disabled={true}
                        required
                      />
                    </>
                    :
                    <>
                      <Input
                        type="text"
                        id="zyvo_profile_name"
                        name="profile_name"
                        defaultValue={""}
                        disabled={false}
                        required
                      />
                    </>
                  }
									<FormText>
										Nombre que tendrá el perfil. Esto es solo para poder identificarlo.
									</FormText>
								</FormGroup>
								<FormGroup>
									<Label for="zyvo_profile_description">Descripción</Label>
                  { selectedProfile !== null && !openNewProfile ?
                    <>
                      <Input
                        type="textarea"
                        id="zyvo_profile_description"
                        name="profile_description"
                        defaultValue={selectedProfile.description}
                        disabled={true}
                        required
                      />
                    </>
                    :
                    <>
                      <Input
                        type="textarea"
                        id="zyvo_profile_description"
                        name="profile_description"
                        defaultValue={""}
                        disabled={false}
                        required
                      />
                    </>
                  }
									<FormText>
										Pequeña descripción del perfil, mencionando algunos rasgos
										específicos.
									</FormText>
								</FormGroup>
                <div className="kt-portlet">
                  <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                      <span className="kt-portlet__head-icon">
                        <i className="flaticon2-graph-1"></i>
                      </span>
                      <h3 className="kt-portlet__head-title">
                        Configuración habilidades a Evaluar
                        <small>
                          <a
                            href="https://tests.4talent.cl"
                            target="_blank"
                            className="text-primary"
                          >
                            Filtro Test
                          </a>
                        </small>
                      </h3>
                    </div>
                  </div>
                  <div className="kt-portlet__body">
                    { openNewProfile &&
                      <div className="alert alert-solid-brand alert-bold" role="alert">
                        <div className="alert-text">
                          Puedes seleccionar tanto habilidades cognitivas como rasgos de
                          personalidad, en ambos casos deberas <b>ajustar</b> el
                          <b> resultado esperado</b>.
                        </div>
                      </div>
                    }

                    <Row>
                      <Col xs="6">
                        <div className="card card-hover mb-2">
                          <div className="card-header bg-white d-flex flex-column justify-content-between">
                            <div className="d-inline-block d-flex align-items-center">
                              <h3 className="h5 mb-0 d-inline-block">
                                Habilidades Cognitivas
                              </h3>
                            </div>
                            <p className="small text-muted mb-0 pr-2 pr-sm-0">
                              Las Habilidades basicas que el cerebro usa en conjunto para tomar la informacion entrante y procesarla
                            </p>
                          </div>
                          <div className="card-body">
                            { selectedProfile !== null && !openNewProfile ?
                              <>
                                { cognitiveSkillFromProfile && cognitiveSkillFromProfile.map( competence =>
                                  <div 
                                    key={`${competence.name}-${competence.id}`}
                                    className="form-group form-check"
                                  >
                                    <FormGroup>
                                      <CustomInput
                                        type="switch"
                                        id={`${competence.competency_type}_switch_${competence.name}`}
                                        label={competence.es_description}
                                        disabled={true}
                                        checked={true}
                                      />
                                      <Collapse isOpen={true}>
                                        <div className="d-flex align-items-center my-2">
                                          <CustomInput
                                            className="zyvo_range"
                                            type="range"
                                            id={`${competence.competency_type}_range_${competence.name}`}
                                            min="0"
                                            max="100"
                                            defaultValue={ showPercent(competence.score) }
                                            disabled={true}
                                          />
                                        </div>
                                        <div className="row my-2">
                                          <small className="col text-left text-primary">
                                            { competence.es_low_level }
                                          </small>
                                            <small className="col text-center text-primary">
                                              { `${ showPercent(competence.score) } %` }
                                            </small>
                                          <small className="col text-right text-primary">
                                            { competence.es_high_level }
                                          </small>
                                        </div>
                                      </Collapse>
                                      <small className="text-muted d-block text-justify">
                                        { competence.es_tooltip }
                                      </small>
                                    </FormGroup>
                                  </div>
                                )}
                              </>
                              :
                              <>
                                { cognitiveSkill && cognitiveSkill.map( (competence, index) =>
                                  <div 
                                    key={`${competence.name}-${competence.id}`}
                                    className="form-group form-check"
                                  >
                                    <FormGroup>
                                      <CustomInput
                                        type="switch"
                                        id={`${competence.competency_type}_switch_${competence.name}`}
                                        label={competence.es_description}
                                        onChange={event => handleSwitch(
                                          event,
                                          competence.id,
                                          competence.competency_type,
                                          index
                                        )}
                                        disabled={false}
                                        checked={collapseCognitiveSkill[index] === true ? true : false}
                                      />
                                      <Collapse isOpen={collapseCognitiveSkill[index]}>
                                        <div className="d-flex align-items-center my-2">
                                          <CustomInput
                                            className="zyvo_range"
                                            type="range"
                                            id={`${competence.competency_type}_range_${competence.name}`}
                                            min="0"
                                            max="100"
                                            onChange={event => handledNumberPorcent(
                                              event, 
                                              competence.id, 
                                              competence.competency_type
                                            )}
                                            defaultValue={50}
                                            disabled={false}
                                          />
                                        </div>
                                        <div className="row my-2">
                                          <small className="col text-left text-primary">
                                            { competence.es_low_level }
                                          </small>
                                            <small className="col text-center text-primary">
                                              { 
                                                `${handledShowPercent(
                                                  competence.id, 
                                                  competence.competency_type
                                                )} %`
                                              }
                                            </small>
                                          <small className="col text-right text-primary">
                                            { competence.es_high_level }
                                          </small>
                                        </div>
                                      </Collapse>
                                      <small className="text-muted d-block text-justify">
                                        { competence.es_tooltip }
                                      </small>
                                    </FormGroup>
                                  </div>
                                )}
                              </>
                            }
                            
                          </div>
                        </div>
                      </Col>
                      <Col xs="6">
                        <div className="card card-hover mb-2">
                          <div className="card-header bg-white d-flex flex-column justify-content-between">
                            <div className="d-inline-block d-flex align-items-center">
                              <h3 className="h5 mb-0 d-inline-block">
                                Rasgos de Personalidad
                              </h3>
                            </div>
                            <p className="small text-muted mb-0 pr-2 pr-sm-0">
                              Refleja los patrones característicos de pensamientos, sentimientos y comportamientos de las personas. Los rasgos de personalidad implican consistencia y estabilidad
                            </p>
                          </div>
                          <div className="card-body">
                            { selectedProfile !== null && !openNewProfile ?
                              <>
                                { personalityTraitFromProfile && personalityTraitFromProfile.map( competence =>
                                  <div 
                                    key={`${competence.name}-${competence.id}`}
                                    className="form-group form-check"
                                  >
                                    <FormGroup>
                                      <CustomInput
                                        type="switch"
                                        id={`${competence.competency_type}_switch_${competence.name}`}
                                        label={competence.es_description}
                                        disabled={true}
                                        checked={true}
                                      />
                                      <Collapse isOpen={true}>
                                        <div className="d-flex align-items-center my-2">
                                          <CustomInput
                                            className="zyvo_range"
                                            type="range"
                                            id={`${competence.competency_type}_range_${competence.name}`}
                                            min="0"
                                            max="100"
                                            defaultValue={showPercent(competence.score)}
                                            disabled={true}
                                          />
                                        </div>
                                        <div className="row my-2">
                                          <small className="col text-left text-primary">
                                            { competence.es_low_level }
                                          </small>
                                            <small className="col text-center text-primary">
                                              { `${ showPercent(competence.score) } %` }
                                            </small>
                                          <small className="col text-right text-primary">
                                            { competence.es_high_level }
                                          </small>
                                        </div>
                                      </Collapse>
                                      <small className="text-muted d-block text-justify">
                                        { competence.es_tooltip }
                                      </small>
                                    </FormGroup>
                                  </div>
                                )}
                              </>
                              :
                              <>
                                { personalityTrait && personalityTrait.map( (competence, index) =>
                                  <div 
                                    key={`${competence.name}-${competence.id}`}
                                    className="form-group form-check"
                                  >
                                    <FormGroup>
                                      <CustomInput
                                        type="switch"
                                        id={`${competence.competency_type}_switch_${competence.name}`}
                                        label={competence.es_description}
                                        onChange={event => handleSwitch(
                                          event,
                                          competence.id,
                                          competence.competency_type,
                                          index
                                        )}
                                        disabled={false}
                                        checked={collapsePersonality[index] === true ? true : false}
                                      />
                                      <Collapse isOpen={collapsePersonality[index]}>
                                        <div className="d-flex align-items-center my-2">
                                          <CustomInput
                                            className="zyvo_range"
                                            type="range"
                                            id={`${competence.competency_type}_range_${competence.name}`}
                                            min="0"
                                            max="100"
                                            onChange={event => handledNumberPorcent(
                                              event, 
                                              competence.id, 
                                              competence.competency_type
                                            )}
                                            defaultValue={50}
                                            disabled={false}
                                          />
                                        </div>
                                        <div className="row my-2">
                                          <small className="col text-left text-primary">
                                            { competence.es_low_level }
                                          </small>
                                            <small className="col text-center text-primary">
                                              { 
                                                `${handledShowPercent(
                                                  competence.id, 
                                                  competence.competency_type
                                                )} %`
                                              }
                                            </small>
                                          <small className="col text-right text-primary">
                                            { competence.es_high_level }
                                          </small>
                                        </div>
                                      </Collapse>
                                      <small className="text-muted d-block text-justify">
                                        { competence.es_tooltip }
                                      </small>
                                    </FormGroup>
                                  </div>
                                )}
                              </>
                            }
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
								<div className="w-100 text-center">
                  { addingProfileLoad ?
                    <Spinner color="primary" style={{ width: '4rem', height: '4rem' }} />
                    :
                    <Button type="submit" color={ openNewProfile ? "primary" : "success"}>
                      { openNewProfile ? "Crear Perfil" : "Seleccionar Perfil" }
                    </Button>
                  }
								</div>
							</Form>
						</Col>
					</Row>
				</Collapse>
			</Container>
		</div>
	)
}

export default zyvo;