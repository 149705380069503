import React, { useState } from "react"
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, CardHeader, CardBody, Row, Col } from "reactstrap";
import classnames from 'classnames';



const FiltersSelectorStep = () => {

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  
  return (
    <React.Fragment>
      <div>
        <Nav pills className="mb-3 flex-nowrap border-active-nav align-items-stretch overflow-auto">
          <NavItem style={{"minWidth": "250px"}}>
            <NavLink
              className={`${classnames({ active: activeTab === '1' })} h-100 rounded-lg border d-flex flex-column justify-content-between`}
              onClick={() => { toggle('1'); }}
            >
              <div className="d-flex flex-row justify-content-between align-items-start">
                <h4 className="h6 font-weight-bold text-info mt-2">
                  <i className="fas fa-robot fa-lg mr-2 mb-2"></i>
                  Filtro Curricular
                </h4>
                <div className="text-right">
                  <span className="badge badge-pill badge-success d-inline-block">Active</span>
                </div>
              </div>
              <img className="img-fluid d-block my-3 bg-white p-3" style={{"width": "230px"}} src="/assets/landing/shortlist-cc5a11ab2dd30572986bf804d20003169c5728839aa25a9ffc3a1831f3981661.png"/>
              <div className="mt-2 text-right">
                <button className="btn btn-sm btn-primary">Ver</button>
              </div>
            </NavLink>
          </NavItem>
          <NavItem style={{"minWidth": "250px"}}>
            <NavLink
              className={`${classnames({ active: activeTab === '2' })} h-100 rounded-lg border d-flex flex-column justify-content-between`}
              onClick={() => { toggle('2'); }}
            >
              <div className="d-flex flex-row justify-content-between align-items-start">
                <h4 className="h6 font-weight-bold text-primary mt-2">
                  <i className="fas fa-brain fa-lg mr-2 mb-2"></i>
                  Evaluación Psicolaboral
                </h4>
                <div className="text-right">
                  <span className="badge badge-pill badge-success d-inline-block">Active</span>
                </div>
              </div>
              <img className="img-fluid d-block my-3 bg-white p-3 grayscale-disabled" style={{"width": "230px"}} src="/assets/tests/test_zyvo-686b6a914b096e8ad53e4947f9398f7d4cf16c45e96dea7e61af14fb6c7c7763.png"/>
              <div className="mt-2 text-right">
                <button className="btn btn-sm btn-warning">
                  <i className="flaticon-cart"></i>
                  Comprar
                </button>
              </div>
            </NavLink>
          </NavItem>
          <NavItem style={{"minWidth": "250px"}}>
            <NavLink
              className={`${classnames({ active: activeTab === '3' })} h-100 rounded-lg border d-flex flex-column justify-content-between`}
              onClick={() => { toggle('3'); }}
            >
              <div className="d-flex flex-row justify-content-between align-items-start">
                <h4 className="h6 font-weight-bold text-info mt-2">
                  <i className="fas fa-street-view fa-lg mr-2 mb-2"></i>
                  Evaluación Personal y Trabajo
                </h4>
                <div className="text-right">
                  <span className="badge badge-pill badge-success d-inline-block">Active</span>
                </div>
              </div>
              <img className="img-fluid d-block my-3 bg-white p-3 grayscale-disabled" style={{"width": "230px"}} src="/assets/tests/test_vya-a19259b50c6b5fd7e7b9983e38ab11d4c000864d9b054dc617c072c5bbc91fba.png"/>
              <div className="mt-2 text-right">
                <button className="btn btn-sm btn-warning">
                  <i className="flaticon-cart"></i>
                  Comprar
                </button>
              </div>
            </NavLink>
          </NavItem>
          <NavItem style={{"minWidth": "250px"}}>
            <NavLink
              className={`${classnames({ active: activeTab === '4' })} h-100 rounded-lg border d-flex flex-column justify-content-between`}
              onClick={() => { toggle('4'); }}
            >
              <div className="d-flex flex-row justify-content-between align-items-start">
                <h4 className="h6 font-weight-bold text-success mt-2">
                  <i className="fas fa-laptop-code fa-lg mr-2 mb-2"></i>
                  Evaluación Informática &amp; Ti
                </h4>
                <div className="text-right">
                  <span className="badge badge-pill badge-success d-inline-block">Active</span>
                </div>
              </div>
              <img className="img-fluid d-block my-3 bg-white p-3 grayscale-disabled" style={{"width": "230px"}} src="/assets/tests/test-hr-6f5939332f71a7b9c27fc01746b260ac27bc4224c24df115aa3c2be582743355.png" />
              <div className="mt-2 text-right">
                <button className="btn btn-sm btn-warning">
                  <i className="flaticon-cart"></i>
                  Comprar
                </button>
              </div>
            </NavLink>
          </NavItem>
          <NavItem style={{"minWidth": "250px"}}>
            <NavLink
              className={`${classnames({ active: activeTab === '5' })} h-100 rounded-lg border d-flex flex-column justify-content-between`}
              onClick={() => { toggle('5'); }}
            >
              <div className="d-flex flex-row justify-content-between align-items-start">
                <h4 className="h6 font-weight-bold text-warning mt-2">
                  <i className="fas fa-atom fa-lg mr-2 mb-2"></i>
                  Evaluación Comportamiento
                </h4>
                <div className="text-right">
                  <span className="badge badge-pill badge-success d-inline-block">Active</span>
                </div>
              </div>
              <img className="img-fluid d-block my-3 bg-white p-3 grayscale-disabled" style={{"width": "230px"}} src="/assets/tests/test-hr-6f5939332f71a7b9c27fc01746b260ac27bc4224c24df115aa3c2be582743355.png" />
              <div className="mt-2 text-right">
                <button className="btn btn-sm btn-warning">
                  <i className="flaticon-cart"></i>
                  Comprar
                </button>
              </div>
            </NavLink>
          </NavItem>
        </Nav>
        <Row>
          <Col md="12">
            <Card className="mb-3">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <CardHeader>
                    <h4 className="h5 font-weight-bold text-info mt-2">
                      <i className="fas fa-robot fa-lg mr-2"></i>
                      Filtro Curricular
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <div className="mt-2 text-right">
                <button className="btn btn-sm btn-warning">
                  <i className="flaticon-cart"></i>
                  Comprar
                </button>
              </div>
                  </CardBody>
                </TabPane>
                <TabPane tabId="2">
                  <CardHeader>
                    <h4 className="h5 font-weight-bold text-primary mt-2">
                      <i className="fas fa-brain fa-lg mr-2"></i>
                      Evaluación Psicolaboral
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <p>{I18n.t(`products.filter.zyvo.description`)}</p>
                    {I18n.t(`products.filter.zyvo.benefits_html`)}
                    <div className="owl-carousel">
                      <img src="/assets/zyvo/pincode-68882f7a4b0dbacd5240649b4f9444abe42c19fb9ff52fc387ff6adc67251474.png" />
                      <img src="/assets/zyvo/shapeshift-fadf02e521cc79bcd6bde73547c30de433278a04ce3e7e16243067e1ed1b444a.png" />
                      <img src="/assets/zyvo/skywrapper-c4477c7e858b8c20ba896cda2d31cdab802be1b5d0a6544ae1f49064ef86aaae.png" />
                      <img src="/assets/zyvo/grillmaster-4c8354136a976e7fb6c3bca67a42ad2d7f60f691d8cf9fb8b69256dc0f1f3d82.png" />
                      <img src="/assets/zyvo/globo-84b43b5a2be17e1dd13168645a8da0668068961340456e01d1fb90c936cb0bed.png" />
                      <img src="/assets/zyvo/codecompare-3a59c6b96da55a78652673afc9957b6efce361e5b7e105605d952c0b25318c11.png" />
                    </div>
                    <div className="mt-2 text-right">
                      <button className="btn btn-sm btn-warning">
                        <i className="flaticon-cart"></i>
                        Comprar
                      </button>
                    </div>
                  </CardBody>
                </TabPane>
                <TabPane tabId="3">
                  <CardHeader>
                    <h4 className="h5 font-weight-bold text-info mt-2">
                      <i className="fas fa-street-view fa-lg mr-2"></i>
                      Evaluación Personal y Trabajo
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <div className="mt-2 text-right">
                      <button className="btn btn-sm btn-warning">
                        <i className="flaticon-cart"></i>
                        Comprar
                      </button>
                    </div>
                  </CardBody>
                </TabPane>
                <TabPane tabId="4">
                  <CardHeader>
                    <h4 className="h5 font-weight-bold text-success mt-2">
                      <i className="fas fa-laptop-code fa-lg mr-2"></i>
                      Evaluación Informática &amp; Ti
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <div className="mt-2 text-right">
                      <button className="btn btn-sm btn-warning">
                        <i className="flaticon-cart"></i>
                        Comprar
                      </button>
                    </div>
                  </CardBody>
                </TabPane>
                <TabPane tabId="5">
                  <CardHeader>
                    <h4 className="h5 font-weight-bold text-warning mt-2">
                      <i className="fas fa-atom fa-lg mr-2"></i>
                      Evaluación Comportamiento
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <div className="mt-2 text-right">
                      <button className="btn btn-sm btn-warning">
                        <i className="flaticon-cart"></i>
                        Comprar
                      </button>
                    </div>
                  </CardBody>
                </TabPane>
              </TabContent>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default FiltersSelectorStep;
