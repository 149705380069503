import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import Rater from "react-rater";
import { Progress } from "react-sweet-progress";
import DropDownOptions from "../postulation/card/dropdown_options";
import axios from "axios";
import _ from "lodash";
import AutoComplete from "./autocomplete.js";
import ShowNotification from "../show_notification";

const OffCanvas = ({ postulation, authentication_headers, subtree_ids }) => {

  const toYears = (data) => {
    if (_.isNumber(data) && data > 0) return `(${data} años)`;
  };

  const toCurrency = (data) => {
    return data
      ? data.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
      : data;
  };

  const { project, applicant } = postulation._source;
  const [ allTags, setAllTags ] = useState(null);
  const [applicantTags, setApplicantTags] = useState(applicant.tags.filter(tag => subtree_ids.includes(tag.created_by_id)));
  const [ inputValue, setInputValue ] = useState(null);
  const [ deleteValue, setDeleteValue ] = useState(0);
  const [ showAlready, setShowAlready ] = useState(false);

  useEffect(() => {
    const url = `${window.location.origin}/tags/get_all_tags`;
    axios({
      url: url,
      type: "json",
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        ...authentication_headers
      },
      data: null
    }).then((res) => {
      setAllTags(res.data.all_tags);
    }).catch((error) => {
      ShowNotification({
        message: "Ha ocurrido un error, vuelva a intentar.",
        type: "error"
      });
    });
  }, [postulation]);

  useEffect(() => {
    setApplicantTags(applicant.tags.filter(tag => subtree_ids.includes(tag.created_by_id)));
  }, [postulation]);

  useEffect(() => {
    setDeleteValue(deleteValue + 1);
  }, [postulation]);

  useEffect(() => {
    //console.log(applicantTags);
  }, [applicantTags])

  useEffect(() => {
    //console.log(subtree_ids);
  }, [postulation])


  const createTag = () => {
    if ( inputValue.length > 0 ) {
      const url = `${window.location.origin}/tags/create_applicant_tag`;
      axios({
        url: url,
        type: "json",
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          ...authentication_headers
        },
        data: JSON.stringify({ tag_name: inputValue, applicant_id: applicant.id })
      }).then((res) => {
        if (res.data.message === 'OK') {
          const tagId = res.data.tag_id;
          const newTagObject = { id: tagId, name: inputValue };
          setApplicantTags([...applicantTags, newTagObject]);
        } else if (res.data.message === 'Tag already exists in applicant') {
          setShowAlready(true);
          setTimeout(() => { setShowAlready(false); }, 3000);
        }
      }).catch((error) => {
        ShowNotification({
          message: "Ha ocurrido un error, vuelva a intentar.",
          type: "error"
        });
      });
    }
    setDeleteValue(deleteValue + 1);
  }


  const deleteTag = (tagId) => {
    const url = `${window.location.origin}/tags/delete_applicant_tag`;
    axios({
      url: url,
      type: "json",
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        ...authentication_headers
      },
      data: JSON.stringify({ tag_id: tagId, applicant_id: applicant.id })
    }).then((res) => {
      if (res.data.message === 'OK') {
        const appTagClone = Array.from(applicantTags);
        const indexToDel = appTagClone.findIndex(e => e.id === tagId );
        appTagClone.splice(indexToDel, 1);
        setApplicantTags(appTagClone);
      }
    }).catch((error) => {
      ShowNotification({
        message: "Ha ocurrido un error, vuelva a intentar.",
        type: "error"
      });
    });
  }


  return (
          <>
            <div className="d-flex justify-content-between mb-3">

              <div className="d-flex">

                <Avatar
                  size="60"
                  round
                  name={applicant.name}
                  email={applicant.email}
                  color="rgba(93, 120, 255, 0.4)"
                />

                <div className="d-flex flex-column pl-3">

                  <span className="d-md-inline-flex">
                    <a
                      href={`/process/${project.id}/applicants/${applicant.id}`}
                      style={{ fontSize: "1.5em", fontWeight: "500" }}
                      target="_blank"
                      dangerouslySetInnerHTML={{
                        __html: applicant.name,
                      }}
                    ></a>
                    {toYears(postulation._source.age)}
                  </span>

                  <div className="">
                    <Rater
                      total={5}
                      rating={postulation._source.rating}
                      interactive={false}
                    />
                  </div>

                </div>

              </div>


              <div>
                <a className="btn btn-clean btn-icon btn-sm"
                  data-toggle="dropdown">
                  <i className="flaticon-more"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <DropDownOptions
                    project={project}
                    postulation={postulation._source}
                    postulation_id={postulation._id}
                    applicant={applicant}
                  />
                </div>
              </div>

            </div>


            {/* Applicant info begin */}

            <div className="d-flex flex-column align-items-center mb-3 pl-3 pr-5">

              <div className="d-flex justify-content-between w-100">
                <i className="flaticon-email" style={{ fontSize: '1.5em' }} />
                <span style={{ fontSize: '1.2em' }}>{applicant.email}</span>
              </div>

              <div className="d-flex justify-content-between w-100">
                <i className="flaticon-chat-2" style={{ fontSize: '1.5em' }} />
                <span style={{ fontSize: '1.2em' }}>{applicant.phone_number}</span>
              </div>

              <div className="d-flex justify-content-between w-100">
                <i className="flaticon2-calendar-3" style={{ fontSize: '1.5em' }} />
                <span style={{ fontSize: '1.2em' }}>{applicant.rut}</span>
              </div>

              <div className="d-flex justify-content-between w-100">
                <i className="flaticon-price-tag" style={{ fontSize: '1.5em' }} />
                <span style={{ fontSize: '1.2em' }}>{toCurrency(postulation._source.salary_expectation)}</span>
              </div>

              <div className="d-flex justify-content-between w-100">
                <i className="flaticon2-time" style={{ fontSize: '1.5em' }} />
                <span style={{ fontSize: '1.2em' }}>
                  {`${postulation._source.experience_years <= 0 ? 0 : postulation._source.experience_years} años`}
                </span>
              </div>

              <div className="d-flex justify-content-between w-100">
                <i className="flaticon2-open-text-book" style={{ fontSize: '1.5em' }} />
                <span style={{ fontSize: '1.2em' }}>{applicant.institute}</span>
              </div>

              <div className="d-flex justify-content-between w-100">
                <i className="flaticon-profile-1" style={{ fontSize: '1.5em' }} />
                <span style={{ fontSize: '1.2em' }}>{applicant.career}</span>
              </div>

              <div className="d-flex justify-content-between w-100">
                <i className="flaticon-presentation" style={{ fontSize: '1.5em' }} />
                <span style={{ fontSize: '1.2em' }}>{applicant.contact_reason}</span>
              </div>

            </div>

            {/* Applicant info end */}


            {/* Products begin */}

            <div className="px-3 d-flex flex-wrap">

              <div className="d-flex mb-3" style={{width: "33%"}}>
                <div className="">
                  <i className="flaticon-interface-10" style={{ fontSize: '2.5em' }}></i>
                </div>
                <div className="d-flex flex-column ml-2">
                  <span className="text-primary" style={{ fontSize: '1em', fontWeight: '500' }}>
                    {I18n.t("cv.title")}
                  </span>
                  <span className="">
                    <span style={{ fontSize: '1.2em' }}>{Math.round(postulation._source.cv_score)} pts.</span>
                  </span>
                </div>
              </div>


              <div className="d-flex mb-3" style={{ width: "33%" }}>
                <div className="">
                  <i className="flaticon-speech-bubble" style={{ fontSize: '2.5em' }}></i>
                </div>
                <div className="d-flex flex-column ml-2">
                  <span className="text-primary" style={{ fontSize: '1em', fontWeight: '500' }}>
                    {I18n.t("shortlist.title")}
                  </span>
                  <span className="">
                    <span style={{ fontSize: '1.2em' }}>{Math.round(postulation._source.shortlist.score)} pts.</span>
                  </span>
                </div>
              </div>


              <div className="d-flex mb-3" style={{ width: "33%" }}>
                <div className="">
                  <i className="flaticon-interface-7" style={{ fontSize: '2.5em' }}></i>
                </div>
                <div className="d-flex flex-column ml-2">
                  <span className="text-primary" style={{ fontSize: '1em', fontWeight: '500' }}>
                    {I18n.t("zyvo.title")}
                  </span>
                  <span className="">
                  <span style={{ fontSize: '1.2em' }}>{Math.round(postulation._source.zyvo.score)} pts.</span>
                  </span>
                </div>
              </div>


              <div className="d-flex mb-3" style={{ width: "33%" }}>
                <div className="">
                  <i className="flaticon-list" style={{ fontSize: '2.5em' }}></i>
                </div>
                <div className="d-flex flex-column ml-2">
                  <span className="text-primary" style={{ fontSize: '1em', fontWeight: '500' }}>
                    {I18n.t("interview.title")}
                  </span>
                  <span className="">
                  <span style={{ fontSize: '1.2em' }}>{Math.round(postulation._source.interview.score)} pts.</span>
                  </span>
                </div>
              </div>


              <div className="d-flex mb-3" style={{ width: "33%" }}>
                <div className="">
                  <i className="flaticon-computer" style={{ fontSize: '2.5em' }}></i>
                </div>
                <div className="d-flex flex-column ml-2">
                  <span className="text-primary" style={{ fontSize: '1em', fontWeight: '500' }}>
                    {I18n.t("portal.title")}
                  </span>
                  <span className="">
                    <span style={{ fontSize: '1.2em' }}>{postulation._source.portal.name}</span>
                  </span>
                </div>
              </div>


              <div className="d-flex mb-3" style={{ width: "33%" }}>
                <div className="">
                  <i className="flaticon-squares-3" style={{ fontSize: '2.5em' }}></i>
                </div>
                <div className="d-flex flex-column ml-2">
                  <span className="text-primary" style={{ fontSize: '1em', fontWeight: '500' }}>
                    Etapa
                  </span>
                  <span className="">
                    <span style={{ fontSize: '1.2em' }}>{postulation._source.kanban_state.es}</span>
                  </span>
                </div>
              </div>

            </div>

            {/* Products end */}

            {/* Global score begin */}

            <div className="d-flex pl-3 pr-5">
              <div className="">
                <i className="flaticon-statistics" style={{ fontSize: '2.5em' }}></i>
              </div>
              <div className="w-100 d-flex flex-column ml-2">
                <span className="text-primary" style={{ fontSize: '1em', fontWeight: '500' }}>
                  Puntaje Global
                </span>
                <Progress
                  theme={{
                    success: {
                      symbol: `${postulation._source.global_score}%`,
                      color: "#5d78ff",
                    },
                  }}
                  percent={postulation._source.global_score}
                  status="success"
                  strokeWidth={3}
                />
              </div>
            </div>

            {/* Global score end */}


            {/* Tags begin */}


            <div className="font-weight-normal pl-3 pr-5 mt-3 mb-3" style={{ fontSize: '1rem' }}>
              <i className="fa fa-tags mr-2"></i>
                <span className="text-primary">Etiquetas</span>
              <br />

              <form autoComplete="off" onSubmit={(event) => { event.preventDefault(); createTag(); }}>
                <div className="form-group" style={{ marginBottom: '0.5rem' }}>
                  <div className="autocomplete">
                    <label> Añadir etiqueta</label>
                    <AutoComplete
                      suggestions={allTags}
                      setInputValue={setInputValue}
                      deleteValue={deleteValue}
                    />
                  </div>
                  { showAlready && (
                  <span className="text-danger" id="already">Esta etiqueta ya existe en este candidato.</span>
                  )}
                </div>
                <button className="btn btn-sm btn-primary" type="submit">Añadir etiqueta</button>
              </form>

              <br />

              <div id="tags_space">

                {

                applicantTags.map((tag) => {
                  return (
                    <div key={tag.id} className="badge badge-pill badge-secondary m-1" id={`tag_id_${tag.id}`}>
                      <strong className="text-muted">{tag.name}</strong>
                      <a onClick={() => { deleteTag(parseInt(tag.id, 10)); }} href="javascript:void(0);">
                        <div className="d-inline-block border border-danger text-danger rounded-circle ml-2" style={{ width: '16px', height: '16px', fontSize: '1.2rem' }}>&times;</div>
                      </a>
                    </div>
                  );
                }) }


              </div>
            </div>


            {/* Tags end */}

    </>
  );
};

export default OffCanvas;
