import React from "react";
import LazyComponent from "../lazy_component";
import _ from "lodash";

export default class PurchaseCar extends LazyComponent {
  constructor(props) {
    super(props);
    this.state = {
      products: props.selected_products.map(product_id => {
        return this.getProductById(product_id);
      }),
      image: props.image
    };
  }

  componentDidMount() {
    const _this = this;
    $(document).ready(function() {
      $("#select_products").select2();
      $("#select_products").on("select2:select", e => {
        _this.handleSelect(e.params.data.id);
      });
      $("#select_products").on("select2:unselect", e => {
        _this.handleUnSelect(e.params.data.id);
      });
    });
  }

  getProductById(productId) {
    return this.props.products.find(product => {
      return product.id == productId;
    });
  }

  handleUnSelect(product_id) {
    var new_products = this.state.products;
    _.remove(new_products, product => {
      return product && product.id == product_id;
    });
    this.setState({ products: new_products });
  }

  handleSelect(product_id) {
    var new_products = this.state.products;
    new_products.push(this.getProductById(product_id));
    this.setState({ products: new_products });
  }

  currencyFormat(number) {
    return new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP"
    }).format(number);
  }

  renderProducts() {
    return this.state.products.map(product => {
      if (product) {
        return (
          <div className="col col-md-6" key={`product_${product.id}`}>
            <div className="card mb-5 mb-lg-0">
              <img src={this.state.image} className="card-img-top" />
              <div className="card-body pt-0">
                <h5 className="card-title text-muted text-uppercase text-center">
                  {product.title}
                </h5>
                <h6
                  className={`card-price text-center ${
                    this.props.pack ? "d-none" : null
                  }`}
                >
                  {this.currencyFormat(product.total_price)}
                  <span className="period text-muted">/año*</span>
                </h6>
                <hr />
                <ul className="fa-ul">
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-check" />
                    </span>
                    {product.quota_text}
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-infinity" />
                    </span>
                    {product.description}
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="fas fa-clock" />
                    </span>
                    {product.vigency}
                  </li>
                </ul>
                <small className="text-center text-muted">*precios + iva</small>
              </div>
            </div>
          </div>
        );
      }
    });
  }

  render() {
    var products_ids = this.state.products.map(product => {
      if (product) return product.id;
    });
    var has_active_trial_product = this.props.has_active_trial_product;

    return (
      <section className="pricing purchase pt-0 pb-5 mt-0">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className={`col-sm-12 ${this.props.pack ? "d-none" : null}`}>
              <div className="form-group">
                <label>Plan</label>
                <select
                  id="select_products"
                  defaultValue={products_ids}
                  name={
                    this.props.form_name + "[details_attributes][][product_id]"
                  }
                  className="form-control select2"
                  multiple={true}
                >
                  {this.props.products.map(function(product, index) {
                    return (
                      <option
                        value={product.id}
                        key={"product-" + index}
                        disabled={
                          product.name == "Trial" && has_active_trial_product
                        }
                      >
                        {product.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {this.props.pack ? (
              <div className="col-sm-12 mb-2">
                <div className="card">
                  <div className="card-body text-center">
                    <h2>{this.props.pack.name}</h2>
                    <h3 className="text-primary">
                      {`${this.currencyFormat(this.props.pack.price)}`}
                    </h3>
                  </div>
                </div>
              </div>
            ) : null}
            {this.renderProducts()}
          </div>
        </div>
      </section>
    );
  }
}
