import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Label,
  Input,
  CustomInput,
  Spinner,
  Form,
  FormGroup,
  Button,
  FormFeedback
} from "reactstrap";

import BaseSelect from  "react-select";
import FixedRequiredSelect from "../../commons/FixRequiredSelect";
import axios from "axios";
import ShowNotification from "../../show_notification";
import { useForm, Controller } from 'react-hook-form';


const OfferForm = (props) => {
  const {
    toggleTab,
    headers,
    chargeTypes,
    areas,
    workingTypes,
    contractualRelationships,
    companyActivities,
    steps,
    projectId,
    offerDetailData
  } = props

  const { register, handleSubmit, errors, control } = useForm()

  const Select = props => {
    return (
      <FixedRequiredSelect
        {...props}
        SelectComponent={BaseSelect}
        options={props.options}
      />
    )
  }

  const [ selectArea, setSelectArea ] = useState(null)
  const [ selectChargeType, setSelectChargeType ] = useState(null)
  const [ selectCompanyActivity, setSelectCompanyActivity ] = useState(null)
  const [ selectContractualRelationship, setSelectContractualRelationship ] = useState(null)
  const [ selectWorkingType, setSelectWorkingType ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(false)

  const [ showSalary, setShowSalary ] = useState(true)
  const [ confidencialOffer, setConfidencialOffer ] = useState(false)
  const [ hiddenOffer, setHiddenOffer ] = useState(false)
  const [ disabilitiesPeople, setDisabilitiesPeople ] = useState(false)

  const [ formatHaveAt, setFormatHaveAt ] = useState(false)
  const [ formatHaveNumbers, setFormatHaveNumbers ] = useState(false)
  const numberRex = /\d{4}/

  const [ isDisabled, ] = useState( offerDetailData !== null ? true : false )

  const onSubmit = value => {
    let validValue = true
    if( [...value.min_requirements].find( find => find === '@' ) !== undefined  ) {
      setFormatHaveAt(true)
      setTimeout(() => { setFormatHaveAt(false) }, 3000)
      validValue = false
    }

    if( numberRex.test(value.min_requirements) ) {
      setFormatHaveNumbers(true)
      setTimeout(() => { setFormatHaveNumbers(false) }, 3000)
      validValue = false
    }

    if( validValue ) {
      setIsLoading(true)
      value.project_id = projectId
      value.is_confidential_salary = !showSalary
      value.is_hidden = hiddenOffer
      axios({
        method: 'POST',
        url: `${window.location.origin}/project/offer_detail`,
        headers: headers,
        data: value
      })
      .then( response => {
        if(response.status === 200) {
          ShowNotification({
            message: "Detalles de publicación guardada en borradores, puedes continuar...",
            type: "success"
          });
          steps("additional_detail")
          toggleTab("additional_detail")
          setIsLoading(false)
          window.scrollTo(0, 0)
        } else {
          ShowNotification({
            message: "Ups, ocurrió un error. Vuelve a Intentar...",
            type: "error"
          })
          setIsLoading(false)
        }
      })
      .catch( error => {
        setIsLoading(false)
        ShowNotification({
          message: "Ups, ocurrió un error. Vuelve a Intentar...",
          type: "error"
        })
        console.log(error)
      })
    }
  }

  useEffect(() => {}, [
    showSalary, selectArea, selectChargeType, selectCompanyActivity, selectContractualRelationship, selectWorkingType, formatHaveAt, formatHaveNumbers
  ])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="vacancies">Cantidad de Vacantes</Label>
            <Input
              type="number"
              name="vacancy_numbers"
              id="vacancy_numbers"
              innerRef={ register({
                required: "La cantidad de vacantes es necesaria.",
                min: {
                  value: 1,
                  message: "El valor no puede ser 0"
                }
              })}
              defaultValue={ offerDetailData && offerDetailData.vacancy_numbers || "" }
              disabled={ isDisabled }
              invalid={ errors.vacancy_numbers !== undefined ? true : false }
            />
              { errors.vacancy_numbers &&
                <FormFeedback invalid={ errors.vacancy_numbers !== undefined ? "true" : "false" }>{ errors.vacancy_numbers.message }</FormFeedback>
              }
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="position">Tipo de Cargo</Label>
            <Controller
              render={({ onChange, onBlur, value, name, ref }) => (
                <Select
                  options={ chargeTypes }
                  placeholder="Seleccione una opción"
                  isLoading={ chargeTypes === null }
                  isDisabled={ chargeTypes === null || isDisabled }
                  required
                  value={ value }
                  onChange={ onChange }
                />
              )}
              name="position"
              id="position"
              control={control}
              defaultValue={ offerDetailData && offerDetailData.position ?
                offerDetailData.position : selectChargeType || ""
              }
              onChange={value => setSelectChargeType(value)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="area">Área</Label>
            <Controller
              render={({ onChange, onBlur, value, name, ref }) => (
                <Select
                  options={ areas }
                  placeholder="Seleccione una opción"
                  isLoading={ areas === null }
                  isDisabled={ areas === null || isDisabled }
                  required
                  value={ value }
                  onChange={ onChange }
                />
              )}
              name="area"
              id="area"
              control={control}
              defaultValue={ offerDetailData && offerDetailData.area ?
                offerDetailData.area : selectArea || ""
              }
              onChange={ value => setSelectChargeType(value) }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={4}>
          <FormGroup>
            <Label for="working_type_id">Jornada Laboral</Label>
            <Controller
              render={({ onChange, onBlur, value, name, ref }) => (
                <Select
                  options={ workingTypes }
                  placeholder="Seleccione una opción"
                  isLoading={ workingTypes === null }
                  isDisabled={ workingTypes === null || isDisabled }
                  required
                  value={ value }
                  onChange={ onChange }
                />
              )}
              name="working_type_id"
              id="working_type_id"
              control={control}
              defaultValue={ offerDetailData && offerDetailData.working_type_id ?
                offerDetailData.working_type_id : selectWorkingType || ""
              }
              onChange={ value => setSelectWorkingType(value) }
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="contractual_relationship_id">Tipo de contrato</Label>
            <Controller
              render={({ onChange, onBlur, value, name, ref }) => (
                <Select
                  options={ contractualRelationships }
                  placeholder="Seleccione una opción"
                  isLoading={ contractualRelationships === null }
                  isDisabled={ contractualRelationships === null || isDisabled }
                  required
                  value={ value }
                  onChange={ onChange }
                />
              )}
              name="contractual_relationship_id"
              id="contractual_relationship_id"
              control={control}
              defaultValue={ offerDetailData && offerDetailData.contractual_relationship_id ?
                offerDetailData.contractual_relationship_id
                :
                selectContractualRelationship || ""
              }
              onChange={ value => setSelectContractualRelationship(value) }
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="company_activity_id">Actividad de la empresa</Label>
            <Controller
              render={({ onChange, onBlur, value, name, ref }) => (
                <Select
                  options={ companyActivities }
                  placeholder="Seleccione una opción"
                  isLoading={ companyActivities === null }
                  isDisabled={ companyActivities === null || isDisabled }
                  required
                  value={ value }
                  onChange={ onChange }
                />
              )}
              name="company_activity_id"
              id="company_activity_id"
              control={control}
              defaultValue={ offerDetailData && offerDetailData.company_activity_id ?
                offerDetailData.company_activity_id : selectCompanyActivity || ""
              }
              onChange={ value => setSelectCompanyActivity(value) }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="d-flex align-items-center">
        <Col md={4}>
          <div className="d-flex justify-content-end">
            <FormGroup>
              <CustomInput
                type="switch"
                id="exampleCustomSwitch"
                name="customSwitch"
                label="Ocultar Salario"
                disabled={ isDisabled }
                onChange={ () => setShowSalary(!showSalary) }
              />
            </FormGroup>
          </div>
        </Col>
        { showSalary &&
          <React.Fragment>
            <Col md={4}>
              <FormGroup>
                <Label>Salario mínimo</Label>
                <Input
                  type="number"
                  name="salary_min"
                  id="salary_min"
                  innerRef={ register({
                    required: "El salario mínimo es necesario.",
                    min: {
                      value: 100000,
                      message: "El salario mínimo no puede ser menor a $100.000"
                    },
                    valueAsNumber: true
                  })}
                  disabled={ isDisabled }
                  defaultValue={ offerDetailData && offerDetailData.salary_min ?
                    offerDetailData.salary_min : ""
                  }
                  invalid={ errors.salary_min !== undefined ? true : false }
                />
                { errors.salary_min &&
                  <FormFeedback invalid={ errors.salary_min !== undefined ? "true" : "false" }>{ errors.salary_min.message }</FormFeedback>
                }
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Salario máximo</Label>
                <Input
                  type="number"
                  name="salary_max"
                  id="salary_max"
                  innerRef={ register({
                    required: "El salario máximo es necesario.",
                    max: {
                      value: 2147483647,
                      message: "El salario máximo no puede ser menor a $2.147.483.647"
                    },
                    valueAsNumber: true
                  })}
                  disabled={ isDisabled }
                  defaultValue={ offerDetailData && offerDetailData.salary_max ?
                    offerDetailData.salary_max : ""
                  }
                  invalid={ errors.salary_max !== undefined ? true : false }
                />
                { errors.salary_max &&
                  <FormFeedback invalid={ errors.salary_max !== undefined ? "true" : "false" }>{ errors.salary_max.message }</FormFeedback>
                }
              </FormGroup>
            </Col>
          </React.Fragment>
        }
      </Row>
      <FormGroup>
        <Label for="requirements">Requerimientos mínimos</Label>
        <Input
          type="textarea"
          name="min_requirements"
          id="min_requirements"
          rows={10}
          innerRef={ register({
            required: "El requerimiento mínimo es necesario."
          })}
          disabled={ isDisabled }
          defaultValue={ offerDetailData && offerDetailData.min_requirements ?
            offerDetailData.min_requirements : ""
          }
          invalid={ errors.min_requirements || formatHaveAt || formatHaveNumbers }
        />
        { errors.min_requirements &&
          <FormFeedback invalid={ errors.min_requirements !== undefined ? "true" : "false" }>{ errors.min_requirements.message }</FormFeedback>
        }
        { formatHaveAt &&
          <FormFeedback invalid={ formatHaveAt }>No puede tener @</FormFeedback>
        }

        { formatHaveNumbers &&
          <FormFeedback invalid={ formatHaveNumbers }>No se permite mas de 3 números consecutivos</FormFeedback>
        }
      </FormGroup>
      <FormGroup>
        <CustomInput
          type="switch"
          id="confidential"
          name="confidential"
          label="Oferta confidencial (no muestra la empresa en la publicación)"
          innerRef={ register() }
          value={ confidencialOffer }
          disabled={ isDisabled }
          onChange={ () => setConfidencialOffer( !confidencialOffer ) }
        />
      </FormGroup>

      <FormGroup>
        <CustomInput
          type="switch"
          id="is_hidden"
          name="is_hidden"
          label="Ocultar proceso para los demás usuarios de mi organización"
          innerRef={register()}
          value={hiddenOffer}
          disabled={isDisabled}
          onChange={() => setHiddenOffer(!hiddenOffer)}
        />
      </FormGroup>


      <FormGroup>
        <CustomInput
          type="switch"
          id="for_disabilities"
          name="for_disabilities"
          label="Apto para personas con discapacidad"
          innerRef={ register() }
          value={ disabilitiesPeople }
          disabled={ isDisabled }
          onChange={ () => setDisabilitiesPeople( !disabilitiesPeople ) }
        />
      </FormGroup>
      <Row>
        <Col md="12" className="text-right">
          { !isDisabled &&
            <React.Fragment>
              { isLoading ?
                <Button
                  color="secondary"
                  disabled
                >
                  <Spinner color="primary" style={{ width: '2rem', height: '2rem' }} />
                </Button>
                :
                <Button
                  color="success"
                  className="my-4"
                  type="submit"
                >
                  Continuar
                  <i className="fas fa-arrow-right ml-2"></i>
                </Button>
              }
            </React.Fragment>
          }
        </Col>
      </Row>
    </Form>
  )
}
export default OfferForm;
