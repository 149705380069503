const ShortlistDefaultQuestions = [
  {
    _hidden: true,
    killer_value: "soporte@4talent.cl",
    killer_condition: "==",
    description: "Ingrese su correo electrónico actualizado",
    value_type: "string"
  },
  {
    _id: "rent",
    _title: "Renta",
    _sub_title:
      "Aceptar candidatos cuando la renta que indiquen se encuentre entre:",
    description: "Indica tus expectativas de renta líquida",
    killer_value: "",
    killer_condition: ">",
    value_type: "currency",
    order: 3
  },
  {
    _id: "exp",
    _title: "Experiencia",
    _sub_title: "Aceptar cuando cuenten con años de experiencia entre:",
    description: "¿Cuántos años de experiencia tienes en el cargo?",
    killer_value: "",
    killer_condition: "<",
    value_type: "int",
    order: 0
  },
  {
    _id: "regions",
    _title: "Trabajo en Región",
    _sub_title: "Aceptar cuando respondan 'Si' a:",
    description: "¿Tienes disponibilidad para trabajar en regiones?",
    killer_value: "No",
    killer_condition: "==",
    value_type: "boolean",
    order: 2
  },
  {
    _id: "exp_in",
    _title: "Experiencia en ...",
    _sub_title: "Aceptar cuando respondan 'Si' a:",
    _label: "¿Tienes experiencia en",
    _value: "",
    description: "¿Tienes experiencia en ",
    killer_value: "No",
    killer_condition: "==",
    value_type: "boolean"
  },
  {
    _id: "knwl_in",
    _title: "Conocimiento específico en ...",
    _sub_title: "Aceptar cuando respondan 'Si' a:",
    _label: "¿Tienes conocimiento en",
    _value: "",
    description: "¿Tienes conocimiento en",
    killer_value: "No",
    killer_condition: "==",
    value_type: "boolean"
  },
  {
    _id: "cert_in",
    _title: "Certificación en ...",
    _sub_title: "Aceptar cuando respondan 'Si' a:",
    _label: "¿Ud. Posee una certificación en",
    _value: "",
    description: "¿Ud. Posee una certificación en ",
    killer_value: "No",
    killer_condition: "==",
    value_type: "boolean"
  },
  {
    _id: "title_in",
    _title: "Título de ...",
    _sub_title: "Aceptar cuando respondan 'Si' a:",
    _label: "¿Está usted titulado de",
    _value: "",
    description: "¿Está usted titulado de ",
    killer_value: "No",
    killer_condition: "==",
    value_type: "boolean"
  },
  {
    _id: "aval",
    _title: "Disponibilidad",
    _sub_title:
      "Aceptar candidatos cuando la fecha de disponibilidad del candidato esté entre:",
    description: "¿Cuál es tu disponibilidad para ingresar a trabajar?",
    killer_value: "",
    killer_condition: ">",
    value_type: "date",
    order: 1
  }
];

export default ShortlistDefaultQuestions;
