import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import LazyComponent from "../lazy_component";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import axios from "axios";
import ShowNotification from "./show_notification";
registerLocale("es", es);
const moment = require("moment");

export default class InterviewModal extends LazyComponent {
  constructor(props) {
    super(props);
    this.state = {
      interviews: props.interviews,
      modal: false,
      showHistory: props.interviews.length > 0,
      showForm: props.interviews.length <= 0,
      newInterview: this.emptyInterview(),
    };
  }

  componentDidMount() {
    $(".kt--datetimepicker").datetimepicker();
  }

  emptyInterview() {
    return {
      location: "",
      start: new Date(),
      interviewer_name: this.props.current_user.name,
      interviewer_mail: this.props.current_user.email,
      init_comment: "",
    };
  }

  handleCancel() {
    if (this.state.interviews.length <= 0) {
      this.setState({ modal: false });
    } else {
      var newInterview = this.emptyInterview();
      this.setState({ newInterview, showForm: false, showHistory: true });
    }
  }

  createInterview() {
    var newInterview = this.emptyInterview();
    this.setState({ newInterview, showForm: true, showHistory: false });
  }

  saveInterview() {
    const { id } = this.state.newInterview;
    const headers = {
      "X-User-Email": this.props.current_user.email,
      "X-User-Token": this.props.current_user.authentication_token,
    };
    if (id) {
      var url = `${window.location.origin}/stage/interviews/${id}.json`;
      var method = "patch";
    } else {
      var url = `${window.location.origin}/postulations/${this.props.postulation.id}/stage/interviews.json`;
      var method = "post";
    }
    axios({
      method,
      url,
      headers,
      data: { stage_interview: this.state.newInterview },
    })
      .then((response) => {
        ShowNotification({
          message: response.data.message,
          type: "success",
        });
        this.setState({ interviews: response.data.interviews }, () => {
          this.handleCancel();
        });
      })
      .catch((error) => {
        ShowNotification({
          message: "Ha ocurrido un error, vuelva a intentar",
          type: "error",
        });
      });
  }

  saveObservations(interview) {
    const headers = {
      "X-User-Email": this.props.current_user.email,
      "X-User-Token": this.props.current_user.authentication_token,
    };
    const { id } = interview;
    var url = `${window.location.origin}/stage/interviews/${id}/add_observation.json`;
    axios
      .patch(
        url,
        { stage_interview: interview },
        {
          headers,
        }
      )
      .then((response) => {
        ShowNotification({
          message: response.data.message,
          type: "success",
        });
        location.reload();
        this.setState(
          { interviews: response.data.interviews, modal: false },
          () => {
            this.handleCancel();
          }
        );
      })
      .catch((error) => {
        ShowNotification({
          message: "Ha ocurrido un error, vuelva a intentar",
          type: "error",
        });
      });
  }

  handleEdit(interview) {
    var newInterview = {
      location: interview.location,
      start: moment(interview.start).toDate(),
      interviewer_name: interview.interviewer_name,
      interviewer_mail: interview.interviewer_mail,
      init_comment: interview.init_comment,
      id: interview.id,
    };
    this.setState({ newInterview, showForm: true, showHistory: false });
  }

  handleChange(e) {
    const {
      target: { value, id },
    } = e;
    let { newInterview } = this.state;
    newInterview[id] = value;
    this.setState({ newInterview });
  }

  handleChangeScore(e, interview) {
    const {
      target: { value, id },
    } = e;
    let { interviews } = this.state;
    const index = _.findIndex(interviews, { id: interview.id });

    const { score } = _.find(this.props.available_options, {
      evaluation: value,
    });

    interviews[index][id] = value;
    interviews[index]["score"] = score;
    this.setState({ interviews });
  }

  handleChangeObs(e, interview) {
    const {
      target: { value, id },
    } = e;
    let { interviews } = this.state;
    const index = _.findIndex(interviews, { id: interview.id });
    interviews[index][id] = value;
    this.setState({ interviews });
  }

  toggle(event) {
    event.preventDefault();
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  setStartDate(date) {
    var { newInterview } = this.state;
    newInterview["start"] = date;
    this.setState({ newInterview });
  }

  drawForm() {
    if (this.state.showForm) {
      return (
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label className="required" htmlFor="stage_interview_location">
                Ubicación
              </label>
              <input
                className="form-control"
                required="required"
                type="text"
                id="location"
                value={this.state.newInterview.location}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label className="required" htmlFor="stage_interview_start">
                Fecha / Hora
              </label>

              <div className="input-group date">
                <input
                  type="text"
                  className="form-control kt--datetimepicker"
                  placeholder="Seleccione una fecha"
                  onChange={(date) => this.setStartDate(date)}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="la la-calendar-check-o glyphicon-th"></i>
                  </span>
                </div>
              </div>

              <DatePicker
                className="form-control"
                timeCaption="Hora"
                selected={this.state.newInterview.start}
                onChange={(date) => this.setStartDate(date)}
                showTimeSelect
                timeFormat="HH:mm"
                dateFormat="MMMM d, yyyy HH:mm"
                locale="es"
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label
                className="required"
                htmlFor="stage_interview_interviewer_name"
              >
                Nombre del Entrevistador
              </label>
              <input
                className="form-control"
                type="text"
                id="interviewer_name"
                value={this.state.newInterview.interviewer_name}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label
                className="required"
                htmlFor="stage_interview_interviewer_mail"
              >
                Email del Entrevistador
              </label>
              <input
                className="form-control"
                type="text"
                id="interviewer_mail"
                value={this.state.newInterview.interviewer_mail}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="stage_interview_init_comment">
                Indicaciones Generales
              </label>
              <textarea
                className="form-control custom_scroll"
                rows="5"
                id="init_comment"
                value={this.state.newInterview.init_comment}
                onChange={this.handleChange}
              ></textarea>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="d-flex justify-content-end align-items-center h-100">
              <button
                className="btn btn-outline-info mr-2"
                onClick={this.handleCancel}
              >
                Cancelar
              </button>
              <button className="btn btn-success" onClick={this.saveInterview}>
                Guardar
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex justify-content-end align-items-center h-100">
              <button
                className="btn btn-success text-white"
                onClick={this.createInterview}
              >
                Crear Entrevista
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  drawInterviews() {
    return this.state.interviews.map((interview, index) => {
      return (
        <div
          key={`interview_${interview.id}`}
          className={`kt-timeline-v1__item kt-timeline-v1__item--${
            index % 2 == 0 ? "left" : "right"
          }`}
        >
          <div className="kt-timeline-v1__item-circle">
            <div className="kt-bg-danger"></div>
          </div>
          <span className="kt-timeline-v1__item-time kt-font-brand">
            {interview.readable_start}
          </span>
          <div className="kt-timeline-v1__item-content">
            <div className="kt-timeline-v1__item-title">
              Por: {interview.interviewer_name}, en {interview.location}
            </div>
            <div className="kt-timeline-v1__item-body">
              <div className="kt-list-pics kt-margin-b-10">
                <strong className="d-block mt-2">Indicaciones Generales</strong>
                {interview.init_comment}

                <strong className="d-block mt-2">Observación Final</strong>
                <textarea
                  className="form-control custom_scroll"
                  rows="5"
                  id="observation"
                  value={interview.observation || ""}
                  onChange={(e) => this.handleChangeObs(e, interview)}
                />
              </div>
            </div>
            <div className="kt-timeline-v1__item-actions">
              <select
                className="btn btn-label-primary btn-sm mx-1 pointer"
                id="evaluation"
                value={interview.evaluation || ""}
                onChange={(e) => this.handleChangeScore(e, interview)}
              >
                <option value=""></option>
                {this.props.available_options.map((option) => {
                  return (
                    <option key={option.evaluation} value={option.evaluation}>
                      {option.evaluation_es}
                    </option>
                  );
                })}
              </select>
              {interview.aasm_state != "completed" ? (
                <button
                  className="btn btn-label-warning btn-sm mx-1"
                  style={{ minWidth: "5em" }}
                  onClick={(e) => this.handleEdit(interview)}
                >
                  Editar
                </button>
              ) : null}
              <button
                className="btn btn-label-success btn-sm mx-1"
                style={{ minWidth: "5em" }}
                onClick={(e) => this.saveObservations(interview)}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      );
    });
  }

  drawnInterviewHistory() {
    if (this.state.interviews.length > 0) {
      return (
        <div
          className="accordion accordion-light  accordion-toggle-arrow"
          id="headingInterviewhistory"
        >
          <div className="card">
            <div className="card-header" id="interviewHistory">
              <div
                className="card-title"
                data-toggle="collapse"
                data-target="#collapseInterviewHistory"
                aria-expanded="true"
                aria-controls="collapseInterviewHistory"
              >
                <i className="flaticon-pie-chart-1"></i> Historial de
                Entrevistas
              </div>
            </div>
            <div
              id="collapseInterviewHistory"
              className={`collapse ${this.state.showHistory ? "show" : ""}`}
              aria-labelledby="interviewHistory"
              data-parent="#headingInterviewhistory"
            >
              <div className="card-body mb-4">
                <div className="row mt-4">
                  <div className="col-xl-1"></div>
                  <div className="col-xl-10">
                    <div className="kt-timeline-v1">
                      <div className="kt-timeline-v1__items">
                        <div className="kt-timeline-v1__marker"></div>
                        {this.drawInterviews()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  drawModalBody() {
    return (
      <React.Fragment>
        {this.drawnInterviewHistory()}
        {this.drawForm()}
      </React.Fragment>
    );
  }

  drawInterviewButton() {
    const interviewText =
      this.state.interviews.length > 0
        ? "Click aquí para Gestionar Entrevistas"
        : "Click aquí para Citar a Entrevista";

    return this.props.hasNavPills ? (
      <a className="nav-link active pointer" onClick={this.toggle}>
        {interviewText}
      </a>
    ) : (
      <a className="kt-nav__link m-4 pointer" onClick={this.toggle}>
        <i className="kt-nav__link-icon flaticon-calendar pl-1 pr-3"></i>
        <span className="kt-nav__link-text ml-1">{interviewText}</span>
      </a>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.drawInterviewButton()}

        <Modal
          size="xl"
          toggle={this.toggle}
          isOpen={this.state.modal}
          className={"modal-dialog-centered"}
        >
          <ModalHeader
            toggle={this.toggle}
          >{`Citar a Entrevista - ${this.props.applicant.name}`}</ModalHeader>
          <ModalBody>{this.drawModalBody()}</ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
