import React from "react";
import LazyComponent from "../lazy_component";
import Questions from "questions-shortlist";
import ShortlistProjectEasy from "./shortlist_project_easy";
import _ from "lodash";

const baseName = "project[shortlist_project_attributes][project]";

export default class ShortlistProjectAttribute extends LazyComponent {
  constructor(props) {
    super(props);

    var attributes = props.shortlist_project_attributes;
    var { defaultQuestion, isUpdate } = props;

    var questions =
      attributes &&
      attributes.project &&
      attributes.project.questions_attributes
        ? attributes.project.questions_attributes
        : [];

    var _questions = [];

    for (var key in questions) {
      if (
        defaultQuestion.description == questions[key].description &&
        !isUpdate
      ) {
        questions[key]["_destroy"] = true;
      } else if (questions[key]["_destroy"] != undefined) {
        questions[key]["_destroy"] = JSON.parse(questions[key]["_destroy"]);
      }
      _questions.push(_.omit(questions[key], "weighing"));
    }

    var smsBody =
      attributes && attributes.project && attributes.project.sms_body
        ? attributes.project.sms_body
        : "";

    this.state = {
      smsBody: smsBody,
      expert: props.expert,
      questions: _questions
    };
  }

  componentDidMount() {
    this.hideQuestion();
  }

  componentDidUpdate() {
    this.hideQuestion();
  }

  hideQuestion() {
    if (this.state.expert) {
      const { defaultQuestion } = this.props;
      const toHide = `input[value='${defaultQuestion.description}']`;
      var timer = setInterval(() => {
        if ($(toHide).is(":visible")) {
          $($(toHide).parents()[6]).hide();
          clearInterval(timer);
        }
      }, 500);
    }
  }

  handleSmsBody(event) {
    var value = event.target.value;
    if (value.length <= 125) {
      this.setState({ smsBody: value });
    }
  }

  charterCounter() {
    var counter = this.state.smsBody.length;
    return <small className="text-primary">{counter}/125</small>;
  }

  expertToggle() {
    var { expert } = this.state;
    this.setState({ expert: !expert });
  }

  updateQuestions(questions) {
    this.setState({ questions });
  }

  showQuestions() {
    const { questions } = this.state;
    if (this.state.expert) {
      return (
        <Questions
          questions={questions}
          form_name={baseName + "[questions_attributes]"}
        />
      );
    } else {
      return (
        <ShortlistProjectEasy
          questions={questions}
          form_name={baseName + "[questions_attributes]"}
          updateQuestions={this.updateQuestions}
        />
      );
    }
  }

  render() {
    var title = this.props.chargeName;
    var attributes = this.props.shortlist_project_attributes;

    var chat_introduction =
      attributes && attributes.project && attributes.project.chat_introduction
        ? attributes.project.chat_introduction
        : "";

    var chat_ending =
      attributes && attributes.project && attributes.project.chat_ending
        ? attributes.project.chat_ending
        : "";

    return (
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
              {I18n.t("shortlist.header")}{" "}
              <small>
                <a
                  href="https://shortlist.chat/"
                  target="_blank"
                  className="text-primary"
                >
                  {I18n.t("shortlist.chatbot")}
                </a>
              </small>
            </h3>
          </div>
        </div>
        <div class="kt-portlet__body">
          <div class="alert alert-outline-info" role="alert">
            <div class="alert-text">
              <h4 class="alert-heading">Introducción</h4>
              <p>
                A continuación podrás configurar nuestra herramienta Shortlist,
                la cual te permitirá conocer mejor a los candidatos con simples
                clicks. Es importante que agregues la{" "}
                <b>mayor cantidad de preguntas</b> para rankear a los
                candidatos.
              </p>
              <hr></hr>
              <p class="mb-0">
                No te preocupes, <b>no descartará a nadie</b>, solo los rankeará
                según las necesidades de tu cargo.
              </p>
            </div>
          </div>
          <div className="form-group">
            <input
              type="hidden"
              defaultValue={title}
              name={baseName + "[title]"}
            />
          </div>

          <div className="form-group">
            <div className="d-flex justify-content-start align-items-center">
              <i
                className="far fa-question-circle text-muted"
                data-toggle="tooltip"
                data-placement="top"
                title={I18n.t(
                  "projects.messages.shortlist_component.invitation"
                )}
              ></i>
              <label className="my-0 mx-2">
                Mensaje de invitación (SMS o Email) {this.charterCounter()}
              </label>
            </div>

            <textarea
              type="text"
              className="form-control"
              value={this.state.smsBody}
              onChange={this.handleSmsBody}
              name={baseName + "[sms_body]"}
              required={true}
            />
            <small className="form-text text-muted">
              El SMS no debe exceder de 125 caracteres
            </small>
          </div>

          <div className="form-group">
            <div className="d-flex justify-content-start align-items-center">
              <i
                className="far fa-question-circle text-muted"
                data-toggle="tooltip"
                data-placement="top"
                title={I18n.t("projects.messages.shortlist_component.intro")}
              ></i>
              <label className="my-0 mx-2">
                Mensaje de introducción al ChatBot
              </label>
            </div>

            <textarea
              type="text"
              className="form-control"
              defaultValue={chat_introduction}
              name={baseName + "[chat_introduction]"}
              required={true}
            />
          </div>

          <div className="form-group mb-5">
            <div className="d-flex justify-content-start align-items-center">
              <i
                className="far fa-question-circle text-muted"
                data-toggle="tooltip"
                data-placement="top"
                title={I18n.t("projects.messages.shortlist_component.farewell")}
              ></i>
              <label className="my-0 mx-2">Mensaje de despedida ChatBot</label>
            </div>

            <textarea
              type="text"
              className="form-control"
              defaultValue={chat_ending}
              name={baseName + "[chat_ending]"}
              required={true}
            />
          </div>

          {this.showQuestions()}

          <br />
          <div className="row text-center">
            <div className="col-md-12">
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={this.expertToggle}
                type="button"
              >
                {this.state.expert ? "Modo Simple" : "Modo Avanzado"}
              </button>
            </div>
            <div className="col-md-12 text-muted">
              <small>
                <i className="far fa-question-circle mr-1"></i>
                {this.state.expert
                  ? "Algunas de las preguntas creadas en el modo avanzado pueden no visualizarse en el modo simple."
                  : "Si quieres agregar otras preguntas o modificar las existentes."}
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
