import React from "react";
import _ from "lodash";

const IndicatorCardWrapper = ({
  title,
  subTitle,
  count,
  buckets,
  color,
  icon
}) => {
  return (
    <div className="col-md-12 col-lg-6 col-xl-3">
      <div className="kt-widget24">
        <div className="kt-widget24__details">
          <div className="kt-widget24__info">
            <h4 className="kt-widget24__title">{title}</h4>
            {subTitle ? (
              <span className="kt-widget24__desc">{subTitle}</span>
            ) : null}
          </div>

          <span className={`kt-widget24__stats kt-font-${color}`}>{count}</span>
        </div>

        <div className="progress progress--sm">
          <div
            className={`progress-bar kt-bg-${color}`}
            role="progressbar"
            style={{ width: `${count}%` }}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>

        <div className="kt-widget24__action">
          {_.map(buckets, bucket => (
            <div key={`project_${bucket.key}`}>
              <span className="kt-widget24__change">
                {bucket.key}: {bucket.doc_count}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IndicatorCardWrapper;
