import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ProjectsModal = ({ projects, opened, setProjects }) => {

  const [open, setOpen] = useState(opened);

  useEffect(() => { setOpen(true) }, [projects]);

  return !_.isEmpty(projects) ? (
    <Modal size="md" isOpen={open} className={"modal-dialog-centered"}>
      <ModalHeader>
        Procesos activos
      </ModalHeader>
      <ModalBody>
        {(!projects[0]) ?
          <p>El candidato no está en ningún proceso activo.</p>
          :
          <ul>
            {
              projects.map((project, index) => {
                const d = new Date(project.created_at);
                return (<li key={index}><strong>{project.charge_name}</strong> - {d.getDate()}/{d.getMonth() + 1}/{d.getFullYear()}</li>)
              })
            }
          </ul>
        }
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-secondary btn-sm btn-upper"
          onClick={() => {
            setOpen(false);
            setProjects([]);
          }}
        >
          Cerrar
        </button>
      </ModalFooter>
    </Modal>
  ) : null;

};

export default ProjectsModal
