import React, { Fragment, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  CustomInput,
  Label
} from "reactstrap";
import axios from "axios";
import _ from "lodash";
import ShowNotification from "./show_notification";

const ProjectScores = ({
  workflow,
  minimum_score,
  ranking_number,
  pathname,
  current_user,
  min_ranking_number,
  smart_cv_weighing,
  smart_cv_max
}) => {
  const [modal, setModal] = useState(false);
  const [newWorkflow, setNewWorkflow] = useState(workflow);
  const [minimumScore, setminimumScore] = useState(minimum_score);
  const [rankingNumber, setrankingNumber] = useState(ranking_number);
  const [smartCvWeighing, setsmartCvWeighing] = useState(smart_cv_weighing);
  const [smartCvMax, setsmartCvMax] = useState(smart_cv_max);
  const [changed, setChanged] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const getValues = () => {
    const currentSum = _.reduce(
      newWorkflow,
      (sum, p) => {
        return sum + parseFloat(p.weighing);
      },
      0
    );
    const rest = 100 - currentSum;
    const isComplete = rest == 0 || newWorkflow == false;
    return { currentSum, rest, isComplete };
  };

  const handleDinamicChange = product_id => {
    const value = parseInt(event.target.value) || 0;
    var workflowIndex = _.findIndex(newWorkflow, { product_id });
    const { currentSum } = getValues();
    const currentWeighing = parseFloat(newWorkflow[workflowIndex].weighing);
    const dif = value - currentWeighing;
    if (currentSum + dif <= 100) {
      newWorkflow[workflowIndex]["weighing"] = value;
    } else {
      const max = 100 - (currentSum - currentWeighing);
      newWorkflow[workflowIndex]["weighing"] = max;
    }
    setNewWorkflow(newWorkflow);
    setChanged(!changed);
  };

  const handleChange = () => {
    const { value, id } = event.target;
    switch (id) {
      case "minimum_score":
        setminimumScore(value);
        break;
      case "ranking_number":
        setrankingNumber(value);
        break;
      case "smart_cv_weighing":
        setsmartCvWeighing(value);
        break;
      case "smart_cv_max":
        setsmartCvMax(value);
        break;
    }
    setChanged(!changed);
  };

  const handleSave = () => {
    setisLoading(true);
    const headers = {
      "X-User-Email": current_user.email,
      "X-User-Token": current_user.authentication_token
    };
    let project = {};
    const _workflow = _.reduce(
      newWorkflow,
      (result, value, index) => {
        result[index] = value;
        return result;
      },
      {}
    );
    if (!_.isEmpty(_workflow)) project["phases_attributes"] = _workflow;
    project["minimum_score"] = minimumScore;
    project["ranking_number"] = rankingNumber;
    project["smart_cv_weighing"] = smartCvWeighing;
    project["smart_cv_max"] = smartCvMax;

    axios({
      url: pathname,
      type: "json",
      method: "PATCH",
      headers,
      data: {
        project
      }
    })
      .then(response => {
        setModal(!modal);
        setisLoading(false);
        ShowNotification({
          message: response.data.message,
          type: "success"
        });
        location.reload();
      })
      .catch(error => {
        setisLoading(false);
        ShowNotification({
          message: _.first(
            _.map(error.response.data.message, (message, model) => {
              return `${model}: ${message}`;
            })
          ),
          type: "error"
        });
      });
  };

  const handleComplete = () => {
    var { rest } = getValues();
    const toSum = 1;
    while (rest > 0) {
      var product = _.sample(newWorkflow);
      const currentWeighing = product["weighing"];
      product["weighing"] = currentWeighing + toSum;
      rest -= toSum;
    }
    setNewWorkflow(newWorkflow);
    setChanged(!changed);
  };

  const drawProducts = () => {
    if (newWorkflow) {
      const { rest } = getValues();
      return (
        <Fragment>
          <div className="alert alert-outline-info" role="alert">
            <div className="alert-text">
              <h4 className="alert-heading">Introducción</h4>
              <p>
                A continuación podrás configurar las onderaciones de cada
                producto, esto influirá en el puntaje de cada candidato
              </p>
            </div>
          </div>

          <ul className="list-group">
            {_.map(newWorkflow, product => {
              const _weighing = parseFloat(product.weighing);
              return (
                <li
                  key={`range_${product.product_id}`}
                  className="border-0 list-group-item d-flex justify-content-between align-items-center"
                >
                  <Label style={{ width: "10em" }}>
                    {product.product_title}
                  </Label>
                  <div className="inline-block d-flex justify-content-between align-items-center w-100">
                    <CustomInput
                      type="range"
                      id={`range_${product.product_id}`}
                      min={0}
                      max={100}
                      value={_weighing}
                      onChange={event =>
                        handleDinamicChange(product.product_id)
                      }
                    />
                    <span className="badge badge-pill badge-primary ml-2">
                      {_weighing}
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="alert alert-solid-brand alert-bold" role="alert">
            <div className="alert-text">
              Ponderación restante: <b>{rest}</b>
            </div>
          </div>
        </Fragment>
      );
    }
  };

  const drawMinimumScore = () => {
    if (newWorkflow) {
      return (
        <li className="border-0 list-group-item d-flex justify-content-between align-items-center">
          <Label style={{ width: "10em" }}>
            {I18n.t("activerecord.attributes.project.minimum_score")}
          </Label>
          <div className="inline-block d-flex justify-content-between align-items-center w-100">
            <CustomInput
              type="range"
              id={"minimum_score"}
              min={0}
              max={100}
              value={minimumScore}
              onChange={handleChange}
            />
            <span className="badge badge-pill badge-primary ml-2">
              {minimumScore}
            </span>
          </div>
        </li>
      );
    }
  };

  const drawModalBody = () => {
    return (
      <div>
        {drawProducts()}

        <hr />

        <ul className="list-group">
          <li className="border-0 list-group-item d-flex justify-content-between align-items-center">
            <Label style={{ width: "10em" }}>
              {I18n.t("activerecord.attributes.project.smart_cv_weighing")}
            </Label>
            <div className="inline-block d-flex justify-content-between align-items-center w-100">
              <CustomInput
                type="range"
                id={"smart_cv_weighing"}
                min={0}
                max={100}
                value={smartCvWeighing}
                onChange={handleChange}
              />
              <span className="badge badge-pill badge-primary ml-2">
                {smartCvWeighing}
              </span>
            </div>
          </li>
          <li className="border-0 list-group-item d-flex justify-content-between align-items-center">
            <Label style={{ width: "10em" }}>
              {I18n.t("activerecord.attributes.project.smart_cv_max")}
            </Label>
            <div className="inline-block d-flex justify-content-between align-items-center w-100">
              <CustomInput
                type="range"
                id={"smart_cv_max"}
                min={0}
                max={100}
                value={smartCvMax}
                onChange={handleChange}
              />
              <span className="badge badge-pill badge-primary ml-2">
                {smartCvMax}
              </span>
            </div>
          </li>
        </ul>

        <hr />

        <ul className="list-group">
          {drawMinimumScore()}
          <li className="border-0 list-group-item d-flex justify-content-between align-items-center">
            <Label style={{ width: "10em" }}>
              {I18n.t("activerecord.attributes.project.ranking_number")}
            </Label>
            <div className="inline-block d-flex justify-content-between align-items-center w-100">
              <CustomInput
                type="range"
                id={"ranking_number"}
                min={min_ranking_number}
                max={100}
                value={rankingNumber}
                onChange={handleChange}
              />
              <span className="badge badge-pill badge-primary ml-2">
                {rankingNumber}
              </span>
            </div>
          </li>
        </ul>
      </div>
    );
  };

  const drawButtonActionText = () => {
    const btnText = getValues().isComplete ? "Guardar" : "Autocompletar";

    return isLoading ? (
      <Fragment>
        {btnText} <i className="fas fa-spinner fa-pulse"></i>
      </Fragment>
    ) : (
      btnText
    );
  };

  return (
    <li className="kt-nav__item">
      <a className="kt-nav__link" onClick={toggle}>
        <i className="kt-nav__link-icon flaticon-interface-7"></i>
        <span className="kt-nav__link-text">Configuración Ponderaciones</span>
      </a>

      <Modal
        size="md"
        isOpen={modal}
        toggle={toggle}
        className={"modal-dialog-centered"}
      >
        <ModalHeader toggle={toggle}>Configuración Ponderaciones</ModalHeader>
        <ModalBody>{drawModalBody()}</ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={getValues().isComplete ? handleSave : handleComplete}
          >
            {drawButtonActionText()}
          </button>
        </ModalFooter>
      </Modal>
    </li>
  );
};

export default ProjectScores;
