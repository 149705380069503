import React from "react";
import Joyride from "react-joyride";
import LazyComponent from "../lazy_component";
import ShowNotification from "./show_notification";
import axios from "axios";
import _ from "lodash";

class JoyRideTour extends LazyComponent {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  convertTitle(title) {
    return (
      <React.Fragment>
        <span className="text-primary">{title}</span>
      </React.Fragment>
    );
  }

  buildCustomStyles() {
    let { steps } = this.state;
    return steps.map(step => {
      let has_title = _.includes(_.keys(step), "title");
      if (has_title) step["title"] = this.convertTitle(step["title"]);
      return step;
    });
  }

  handleTourWasSeen() {
    axios({
      url: `${window.location.origin}/tours/${this.props.tour_id}/seen`,
      type: "json",
      method: "PATCH",
      headers: {
        "X-CSRF-TOKEN": $('[name="csrf-token"]')[0].content,
        enctype: "multipart/form-data"
      },
      success(response) {
        ShowNotification({
          message: "Tour Guiado Completado",
          type: "success"
        });
      },
      error(response, status, err) {
        ShowNotification({
          message: "Vuelva a intentar",
          type: "error"
        });
      }
    });
  }

  handleJoyrideCallback = data => {
    const { status, lifecycle } = data;
    if (status == "finished" && lifecycle == "complete")
      this.handleTourWasSeen();
  };

  render() {
    const { steps } = this.state;
    return (
      <Joyride
        steps={this.buildCustomStyles()}
        callback={this.handleJoyrideCallback}
        continuous
        showProgress
        showSkipButton
        scrollToFirstStep
        disableCloseOnEsc
        styles={{
          options: { primaryColor: "#5a3ee0" }
        }}
        locale={{
          back: "Volver",
          close: "Cerrar",
          last: "Entendido!",
          next: "Siguiente",
          skip: "Saltar"
        }}
      />
    );
  }
}

export default JoyRideTour;
