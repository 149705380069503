const theme = {
  typography: {
    fontFamily: '"Nunito", "Open Sans", sans-serif',
    fontSize: "16px"
  },

  colors: {
    textColor: "#424242",
    primaryTextColor: "#fff",
    primaryColor: "#5867dd",
    titleColor: "#5867dd",
    alertColor: "#ffb822"
  }
};

export default theme;
