import React from "react";
import _ from "lodash";
import LazyComponent from "../lazy_component";
// FullCalendar
import FullCalendar from "@fullcalendar/react";
// Plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeListPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
// Styles
import bootstrap from "@fullcalendar/bootstrap";
// Locales
import esLocale from "@fullcalendar/core/locales/es";
// Modals
import Swal from "sweetalert2";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

export default class EventCalendar extends LazyComponent {
  constructor(props) {
    super(props);
    this.state = {
      calendarEvents: this.props.calendarEvents,
      modal: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  drawModalBody() {
    return (
      <FullCalendar
        navLinks
        businessHours
        aspectRatio="2.3"
        eventTextColor="white"
        defaultView="dayGridMonth"
        header={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,listWeek"
        }}
        plugins={[dayGridPlugin, timeListPlugin, interactionPlugin, bootstrap]}
        themeSystem="bootstrap"
        locale={esLocale}
        weekends={false}
        fixedWeekCount={false}
        showNonCurrentDates={false}
        ref={this.calendarComponentRef}
        events={this.state.calendarEvents}
        eventClick={this.handleEventClick}
      />
    );
  }

  tooltipMessage() {
    const { today } = this.props;
    return today > 0
      ? `Un Cafecito? Recuerda que tienes ${today} citaciones de entrevistas hoy`
      : "No tienes citaciones para hoy";
  }

  drawTooltipAlertIcon() {
    return (
      <i className="far fa-calendar-alt text-primary" onClick={this.toggle} />
    ) +
      this.props.today >
      0 ? (
      <i
        className="fa fa-info-circle fa-sm mr-1 text-info"
        style={{ fontSize: "0.5em" }}
      />
    ) : null;
  }

  render() {
    return (
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
          className="kt-svg-icon"
          onClick={this.toggle}
        >
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24" />
            <path
              d="M10,4 L21,4 C21.5522847,4 22,4.44771525 22,5 L22,7 C22,7.55228475 21.5522847,8 21,8 L10,8 C9.44771525,8 9,7.55228475 9,7 L9,5 C9,4.44771525 9.44771525,4 10,4 Z M10,10 L21,10 C21.5522847,10 22,10.4477153 22,11 L22,13 C22,13.5522847 21.5522847,14 21,14 L10,14 C9.44771525,14 9,13.5522847 9,13 L9,11 C9,10.4477153 9.44771525,10 10,10 Z M10,16 L21,16 C21.5522847,16 22,16.4477153 22,17 L22,19 C22,19.5522847 21.5522847,20 21,20 L10,20 C9.44771525,20 9,19.5522847 9,19 L9,17 C9,16.4477153 9.44771525,16 10,16 Z"
              fill="#000000"
            />
            <rect
              fill="#000000"
              opacity="0.3"
              x="2"
              y="4"
              width="5"
              height="16"
              rx="1"
            />
          </g>
        </svg>

        <Modal
          size="xl"
          toggle={this.toggle}
          isOpen={this.state.modal}
          className={"modal-dialog-centered"}
        >
          <ModalHeader toggle={this.toggle}>Calendario de Eventos</ModalHeader>
          <ModalBody>{this.drawModalBody()}</ModalBody>
        </Modal>
      </div>
    );
  }

  handleEventClick = arg => {
    const interview = arg.event;
    const { postulation } = interview._def.extendedProps;

    Swal.fire({
      title: interview.title,
      type: "info",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Ver Perfil del Candidato"
    }).then(result => {
      if (result.value) {
        var applicantUrl = `${window.location.origin}/process/${postulation.project_id}/applicants/${postulation.applicant.id}`;
        window.open(applicantUrl, "_blank");
      }
    });
  };
}
