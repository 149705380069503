import React from "react";
import { Progress } from 'react-sweet-progress';
import { Button, Badge } from 'reactstrap';
import _ from "lodash";


const VYAResults = (scores) => {

  return(
    <>
      {scores && scores.scores.integration_attributes.scores.map((score, index) => (
        <div className="container p-2" key={`score-${index}`}>
          <div className="card">
            <div className="card-header">
              <b>{`${score.code} ${score.name}`}</b>
            </div>
            <div className="card-body">
              <div className="container">
                <div className="row">
                  <div className="col-6">
                    <div className="d-flex flex-column bd-highlight mb-3">
                      <div className="p-2 bd-highlight">
                        <i className="fas fa-check-circle pr-2"></i>
                        {`Completado: ${new Date(score.completed_at).toLocaleString()}`}
                      </div>
                      <div className="p-2 bd-highlight">
                        <i className="fas fa-list-alt pr-2"></i>
                        {`${score.items} Preguntas`}
                      </div>
                      <div className="p-2 bd-highlight">
                        <i className="fas fa-stopwatch pr-2"></i>
                        {`Duración: ${score.time} min.`}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="pt-5">
                      <Progress percent={score.percentile_score}/>
                      <div className="d-flex flex-column bd-highlight mb-3">
                        <div className="p-2 bd-highlight">
                          <i className="fas fa-bookmark pr-2"></i>
                          <Badge color="primary" pill>
                            {score.type}
                          </Badge>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="container-fluid">
                      <i className="fas fa-tags pr-2"></i>
                        {score.measurements && score.measurements.map((tags, indexTag) => (
                          <div className="px-1" style={{display: 'inline-block'}} key={`tag-${index+indexTag}`}>
                            <Badge color="light" pill>
                              {tags}
                            </Badge>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="text-right">
                <div className="pr-2" style={{display: "inline-block"}}>
                  <Button outline color="primary" href={score.vya_url} target="_blank">Ver Test</Button>
                </div>
                <div style={{display: "inline-block"}}>
                  <Button outline color="secondary" href={score.pdf_url} target="_blank">Descargar Reporte</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))
    }
    </>
  )
}

export default VYAResults;
