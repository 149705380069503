import React from "react";
import _ from "lodash";
import LazyComponent from "../../lazy_component";
import { LineChart } from "react-chartkick";
import "chart.js";

export default class ZyvoResults extends LazyComponent {
  componentDidMount() {
    $(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }

  chartData() {
    var postulationScore = _.transform(
      _.pickBy(this.props.test_results, _.isNumber),
      (result, val, key) => {
        result[_.startCase(key)] = val;
      }
    );

    var globalScore = _.transform(
      this.props.global_results,
      (result, val, key) => {
        result[_.startCase(key)] = val;
      }
    );

    return [
      { name: "Postulation Score", data: postulationScore },
      { name: "Global Score", data: globalScore }
    ];
  }

  drawCompetenceDescriptions() {
    var results = _.pickBy(this.props.test_results, _.isString);

    return (
      <table className="table table-hover mt-5">
        <thead>
          <tr>
            <th scope="col">Competence</th>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody>
          {_.map(results, (value, key) => {
            return (
              <tr key={`competence-${key}`}>
                <th scope="row">{_.startCase(key)}</th>
                <td>{value}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  findCompetence(name) {
    return _.find(this.props.competences.competencies, competence => {
      return competence.name == name;
    });
  }

  render() {
    return (
      <div className="card border-0 text-center pt-4">
        <div className="card-body">
          <h5 className="card-title">Resultados Zyvo</h5>
          <div className="container">
            {this.props.test_results.scores.map((test, index) => {
              const competence = this.findCompetence(test.name);
              return (
                <div key={`score_${index}`} className="w-100 mb-4">
                  <div className="row">
                    <div className="col-sm-10 col-md-6 text-left">
                      <b>{test.es_description}: </b>
                      <span>{parseFloat(test.score).toFixed(2)}%</span>
                    </div>
                    <div className="col-sm-2 col-md-6 text-right">
                      <i
                        className="far fa-question-circle"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={competence.es_tooltip}
                      ></i>
                    </div>
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar bg-info progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-valuenow={test.score}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${test.score}%` }}
                    ></div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 text-left">
                      <i className="text-muted">{competence.es_low_level}</i>
                    </div>
                    <div className="col-sm-6 col-md-6 text-right">
                      <i className="text-muted">{competence.es_high_level}</i>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
