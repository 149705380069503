import React, { useEffect, useState } from "react";
import _ from "lodash";
// Shortlist
import ShortlistResult from "../../shortlist/results";
// Zyvo
import EnrollmentScores from "miterna-tests-scores";
// VYA
import VYAResults from "../../vya/VYAResults";


const Profile = ({ postulation }) => {
  const { shortlist, zyvo, vya } = postulation;
  const [ testPostulation, setTestPostulation ] = useState([])

  useEffect(() => {
    if( !_.isEmpty(_.get(postulation, "shortlist")) ) {
      let shortlist = {"shortlist": _.get(postulation, "shortlist")}
      setTestPostulation(testPostulation => [ ...testPostulation, shortlist ])
    }

    if( !_.isEmpty(_.get(postulation, "vya")) ) {
      let vya = {"vya": _.get(postulation, "vya")}
      setTestPostulation(testPostulation => [ ...testPostulation, vya ])
    }

    if( !_.isEmpty(_.get(postulation, "zyvo")) ) {
      let zyvo = {"zyvo": _.get(postulation, "zyvo")}
      setTestPostulation(testPostulation => [ ...testPostulation, zyvo ])
    }
  }, [ postulation ])


  const drawProfile = () => {
    return (
      <div style={{ minHeight: "400px" }}>
        <ul
          className="nav nav-tabs  nav-tabs-line nav-tabs-line-primary"
          role="tablist"
        >
          {testPostulation && testPostulation.map( (test, indexUl) => (
            <li className="nav-item" key={`ul-test${indexUl}`}>
              <a 
                className={`nav-link ${indexUl === 0 ? "active" : ""}`} 
                data-toggle="tab" 
                href={`#${Object.keys(test)[0]}-tabs-links`} 
                role="tab">
                  { Object.keys(test)[0].includes("shortlist") &&
                    (test.shortlist.external_status === "approved" || test.shortlist.external_status === "discarded") &&
                    <React.Fragment>
                      <i className="flaticon-speech-bubble"></i>
                      SHORTLIST
                    </React.Fragment>
                  }
                  { Object.keys(test)[0].includes("vya") &&
                    test.vya.external_status === "test_completed" &&
                    <React.Fragment>
                      <i className="flaticon-interface-7"></i>
                      VYA
                    </React.Fragment>
                  }
                  { Object.keys(test)[0].includes("zyvo") &&
                    test.zyvo.external_status === "Prueba completada" &&
                    <React.Fragment>
                      <i className="fas fa-clipboard-check"></i>
                      ZYVO
                    </React.Fragment>
                  }
              </a>
            </li>
          ))}
        </ul>
        <div className="tab-content">
          {testPostulation && testPostulation.map( (test, indexTest) => (
            <div 
              className={`tab-pane ${indexTest === 0 ? "active" : "" }`} 
              id={`${Object.keys(test)[0]}-tabs-links`} 
              key={`tabpanel-${indexTest}`} 
              role="tabpanel">
                
                {Object.keys(test)[0].includes("shortlist") &&
                  (test.shortlist.external_status === "approved" || test.shortlist.external_status === "discarded") &&
                  <React.Fragment>
                    <ShortlistResult
                      responses={shortlist.integration_attributes}
                      status={shortlist.external_status}
                      points={shortlist.score}
                    />
                  </React.Fragment>
                }

                { Object.keys(test)[0].includes("vya") &&
                  test.vya.external_status === "test_completed" &&
                  <React.Fragment>
                    <VYAResults 
                      scores={vya}
                    />
                  </React.Fragment>
                }

                { Object.keys(test)[0].includes("zyvo") &&
                  test.zyvo.external_status === "Prueba completada" &&
                  test.zyvo.integration_attributes.main_data &&
                  <React.Fragment>
                    <EnrollmentScores
                      scores={zyvo.integration_attributes}
                    />
                  </React.Fragment>
                }
            </div>
          ))}
        </div>
      </div>
    );
  };

  return !_.isEmpty(postulation) ? drawProfile() : null;
};

export default Profile;
