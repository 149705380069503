import React from "react";
import _ from "lodash";
import IndicatorCardLoader from "../loaders/indicator_card";
import IndicatorCardWrapper from "./indicator_card";

const HeadersWrapper = ({ aggregations }) => {
  if (
    aggregations &&
    aggregations.by_zyvo_state &&
    aggregations.by_shortlist_state
  ) {
    const project_buckets = aggregations.by_project_state.buckets;
    const postulations_count = aggregations.postulations_count.value;
    const shortlist_buckets = aggregations.by_shortlist_state.buckets;
    const zyvo_buckets = aggregations.by_zyvo_state.buckets;
    const interview_buckets = aggregations.by_interview_state.buckets;

    const avg_shortlist_score = aggregations.avg_shortlist_score.value;
    const avg_zyvo_score = aggregations.avg_zyvo_score.value;

    return (
      <div className="kt-portlet">
        <div className="kt-portlet__body  kt-portlet__body--fit">
          <div className="row row-no-padding row-col-separator-lg">
            <IndicatorCardWrapper
              title="Postulaciones"
              subTitle="Totales"
              count={postulations_count}
              buckets={project_buckets}
              color="primary"
              icon="cogs"
            />

            <IndicatorCardWrapper
              title="Filtro Curricular"
              subTitle="Puntaje Promedio"
              count={`${_.round(avg_shortlist_score)}`}
              buckets={shortlist_buckets}
              color="secondary"
              icon="robot"
            />

            <IndicatorCardWrapper
              title="Test"
              subTitle="Puntaje Promedio"
              count={`${_.round(avg_zyvo_score)}`}
              buckets={zyvo_buckets}
              color="success"
              icon="brain"
            />

            <IndicatorCardWrapper
              title="Entrevistas"
              subTitle="Catastro"
              buckets={interview_buckets}
              color="info"
              icon="book"
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="row">
        {_.times(
          4,
          <div className="col-sm-12 col-md-3">
            <IndicatorCardLoader />
          </div>
        )}
      </div>
    );
  }
};

export default HeadersWrapper;
