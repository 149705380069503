import React from "react";
import LazyComponent from "../lazy_component";

export default class AccountVerification extends LazyComponent {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.userId,
      name: this.props.name,
      message: this.props.verification.message,
      color: this.props.verification.color,
      icon: this.props.verification.icon
    };
  }

  componentWillMount() {
    var _this = this;
    var _id = this.state.userId;
    var _portal_name = this.state.name;

    App.cable.subscriptions.create(
      {
        channel: "AccountVerificationsChannel",
        id: _id,
        portal_name: _portal_name
      },
      {
        received: data => {
          _this.setState({
            message: data.verification.message,
            color: data.verification.color,
            icon: data.verification.icon
          });
        }
      }
    );
  }

  drawIcon() {
    var _this = this;
    var icon =
      this.state.icon == "spinner"
        ? `fas fa-${_this.state.icon} fa-pulse`
        : `fas fa-${_this.state.icon}`;

    return (
      <div className="fa-xs d-inline-block mr-2">
        <i className={icon} />
      </div>
    );
  }

  render() {
    var _this = this;
    return (
      <div>
        <div
          className={"text-" + _this.state.color}
          id={`plataforma_${_this.state.name}`}
        >
          {_this.drawIcon()}
          {_this.state.message}
        </div>
      </div>
    );
  }
}
