import React from "react";
import reactCSS from "reactcss";
import { CirclePicker } from "react-color";

class ButtonColor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
      color: props.color
    };
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = color => {
    this.setState({ color: color.hex });
    this.props.handlerColor(color.hex, this.props.target);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.color !== prevState.color) {
      return { color: nextProps.color };
    } else return null;
  }

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center mt-3">
        <CirclePicker color={this.state.color} onChange={this.handleChange} />
      </div>
    );
  }
}

export default ButtonColor;
