import React from "react";

const DropDownOptions = ({ project, postulation, postulation_id, applicant }) => {
  return (
    <ul className="kt-nav">
      <li className="kt-nav__item">
        <a
          href={`/process/${project.id}/applicants/${applicant.id}`}
          className="kt-nav__link"
          target="_blank"
        >
          <i className="kt-nav__link-icon flaticon-medical"></i>
          <span className="kt-nav__link-text">Ver Perfil</span>
        </a>
      </li>

      <li className="kt-nav__item">
        <a href={postulation.one_cv_path} className="kt-nav__link" target="_blank">
          <i className="kt-nav__link-icon flaticon-tool-1"></i>
          <span className="kt-nav__link-text">Descargar CV</span>
        </a>
      </li>

      <li className="kt-nav__item">
        <a
          href={`/postulations/${postulation_id}/notification_email`}
          data-modal="true"
          className="kt-nav__link"
        >
          <i className="kt-nav__link-icon flaticon-mail"></i>
          <span className="kt-nav__link-text">
            Enviar Correo de Notificación
          </span>
        </a>
      </li>


      <li className="kt-nav__item" id={`discard_${postulation_id}`} style={{ display: `${postulation.discarded_at ? "none": ""}` }} >
        <a
          href={`/postulations/${postulation_id}/discard`}
          data-modal="true"
          className="kt-nav__link"
        >
          <i className="kt-nav__link-icon flaticon-close"></i>
          <span className="kt-nav__link-text">Descartar Candidato</span>
        </a>
      </li>

    </ul>
  );
};

export default DropDownOptions;
