import React from "react";
import ButtonColor from "./button_color";
import LazyComponent from "../lazy_component";
import ShowNotification from "./show_notification";
import axios from "axios";

export default class BrandConfigurationForm extends LazyComponent {
  constructor(props) {
    super(props);
    this.state = {
      primary_color: props.primary_color,
      logo_url: props.logo_url,
      banner_url: props.banner_url,
      email: props.email,
      company_name: props.company_name,
      images: props.images
    };
  }

  handlerColor(color, target) {
    this.setState({ target: color, primary_color: color });
  }

  handleImageChange(event) {
    const target = event.target.id;
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        [target]: file,
        [`${target}_url`]: reader.result
      });
    };

    reader.readAsDataURL(file);
  }

  handlerSubmit(e) {
    const states = Object.keys(this.state);
    let formData = new FormData();
    let _this = this;

    states.forEach(function(state, index) {
      formData.append(`user[${state}]`, _this.state[state]);
    });
    e.preventDefault();

    axios({
      url: `save_custom_brand`,
      type: "json",
      method: "PATCH",
      headers: {
        "X-CSRF-TOKEN": $('[name="csrf-token"]')[0].content,
        enctype: "multipart/form-data"
      },
      data: formData,
      processData: false
    })
      .then(response => {
        ShowNotification({
          message: "Cambios Guardados",
          type: "success"
        });
      })
      .catch(error => {
        ShowNotification({
          message: "Vuelva a intentar",
          type: "error"
        });
      });
  }

  render() {
    return (
      <form onSubmit={this.handlerSubmit}>
        <div className="row">
          <div className="col col-md-7">
            <input
              name="user[primary_color]"
              type="hidden"
              value={this.state.primary_color}
            />
            <div className="row">
              <div className="col-sm-12">
                <div className="card profile-card">
                  <div className="card-img-block">
                    <img
                      className="img-fluid"
                      src={this.state.banner_url}
                      alt="banner"
                      width="100%"
                    />
                  </div>
                  <div className="card-body">
                    <div className="image-cropper">
                      <img
                        src={this.state.logo_url}
                        alt="logo"
                        className="profile-pic"
                      />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="container">
                      <h5
                        className="text-left font-weight-bold mt-2"
                        style={{ color: this.state.primary_color }}
                      >
                        Estimado(a) {this.state.company_name}
                      </h5>
                      <p className="mt-3">
                        Esta previsualización corresponde al formato de los
                        correos que saldrán desde la plataforma
                        <span style={{ color: this.state.primary_color }}>
                          MiTerna.com
                        </span>
                      </p>
                      <p className="text-center mt-5 mb-3">
                        Recibe un cordial saludo,
                        <br />
                        Equipo de Reclutamiento y Selección
                      </p>
                    </div>
                  </div>
                  <div
                    className="card-footer"
                    style={{
                      color: "white",
                      backgroundColor: this.state.primary_color
                    }}
                  >
                    <p className="text-center pt-2 pb-1">
                      Este mensaje ha sido generado por un sistema de correos
                      automáticos.
                      <br />
                      No debe responderlo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col col-md-5">
            <div className="alert alert-outline-info fade show" role="alert">
              <div className="alert-icon">
                <i className="flaticon-questions-circular-button"></i>
              </div>
              <div className="alert-text">
                <h5 className="font-weight-bold mb-1">
                  <span>Sugerencias</span>
                </h5>
                <p className="text-muted mb-0 small">
                  <i className="fas fa-info-circle mr-2" />
                  <span>Banner: Dimensiones 600x148px</span>
                </p>
                <p className="text-muted mb-0 small">
                  <i className="fas fa-info-circle mr-2" />
                  <span>Logo: Dimensiones 100x100px</span>
                </p>
                <p className="text-muted mb-0 small">
                  <i className="fas fa-info-circle mr-2" />
                  <span>Formatos permitidos: png, jpg, jpeg</span>
                </p>
              </div>
            </div>

            <div className="row my-3">
              <div className="col-12 text-center mb-4">
                <ButtonColor
                  target="user[primary_color]"
                  color={this.state.primary_color}
                  onChange={this.handlerColor}
                  handlerColor={this.handlerColor}
                />
              </div>
              <div className="col-12 text-center">
                <input
                  className="inputfile input-primary"
                  type="file"
                  name="user[logo]"
                  id="logo"
                  onChange={this.handleImageChange}
                />
                <label
                  className="fileLabel"
                  style={{ width: "100%" }}
                  htmlFor="logo"
                >
                  <i className="fas fa-upload mr-2" />
                  <span>Cambiar Logo</span>
                </label>
              </div>
              <div className="col-12 text-center">
                <input
                  className="inputfile input-primary"
                  type="file"
                  name="user[banner]"
                  id="banner"
                  onChange={this.handleImageChange}
                />
                <label
                  className="fileLabel"
                  style={{ width: "100%" }}
                  htmlFor="banner"
                >
                  <i className="fas fa-upload mr-2" />
                  <span>Cambiar Banner</span>
                </label>
              </div>
            </div>

            <button className="btn btn-label-primary btn-block" type="submit">
              Guardar
            </button>
          </div>
        </div>
      </form>
    );
  }
}
