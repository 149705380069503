import React from "react";
import LazyComponent from "../lazy_component";
import URLImage from "./url_image";
import ShowNotification from "./show_notification";
import { Stage, Layer, Text } from "react-konva";
import axios from "axios";
import _ from "lodash";

export default class SocialConfigurationForm extends LazyComponent {
  constructor(props) {
    super(props);
    let logo_attrs = props.social_logo_attrs;
    this.state = {
      social_logo_url: props.social_logo_url,
      social_image_url: props.social_image_url,
      company_name: props.company_name,
      images: props.images,
      image: null,
      stage_width: 1024,
      stage_height: 768,
      stage_scale: 1,
      social_logo_x: _.isEmpty(logo_attrs.x) ? 50 : _.toInteger(logo_attrs.x),
      social_logo_y: _.isEmpty(logo_attrs.y) ? 50 : _.toInteger(logo_attrs.y),
      social_logo_width: _.isEmpty(logo_attrs.width)
        ? 300
        : _.toInteger(logo_attrs.width),
      social_logo_height: _.isEmpty(logo_attrs.height)
        ? 84
        : _.toInteger(logo_attrs.height),
      social_background_color: _.isEmpty(logo_attrs.background)
        ? "blue"
        : logo_attrs.background,
      social_title: _.isEmpty(logo_attrs.title)
        ? "Nueva Oferta Laboral"
        : logo_attrs.title
    };
  }

  componentDidMount() {
    this.fitStageIntoParentContainer();
  }

  handleImageChange(event) {
    const target = event.target.id;
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        [target]: file,
        [`${target}_url`]: reader.result
      });
    };

    reader.readAsDataURL(file);
  }

  handleBackground(event) {
    let { social_background_color, social_image_url } = this.state;

    social_background_color = event.target.value;
    social_image_url = `/images/social/social-${social_background_color}.jpg`;

    this.setState({
      social_background_color,
      social_image_url
    });
  }

  handlerSubmit(e) {
    let formData = new FormData();
    let _this = this;
    let social_logo_attrs = {
      x: String(this.state.social_logo_x),
      y: String(this.state.social_logo_y),
      width: String(this.state.social_logo_width),
      height: String(this.state.social_logo_height),
      background: String(this.state.social_background_color),
      title: String(this.state.social_title)
    };
    formData.append(
      "user[social_logo_attrs]",
      JSON.stringify(social_logo_attrs)
    );
    if (this.state.social_logo !== undefined)
      formData.append("user[social_logo]", this.state.social_logo);

    e.preventDefault();

    axios({
      url: `save_custom_social`,
      type: "json",
      method: "PATCH",
      headers: {
        "X-CSRF-TOKEN": $('[name="csrf-token"]')[0].content,
        enctype: "multipart/form-data"
      },
      data: formData,
      processData: false
    })
      .then(response => {
        ShowNotification({
          message: "Cambios Guardados",
          type: "success"
        });
      })
      .catch(error => {
        ShowNotification({
          message: "Vuelva a intentar",
          type: "error"
        });
      });
  }

  fitStageIntoParentContainer() {
    var container = document.querySelector("#card-images");
    var containerWidth = container.offsetWidth;
    var scale = containerWidth / this.state.stage_width;

    this.setState({
      stage_width: this.state.stage_width * scale,
      stage_height: this.state.stage_height * scale,
      stage_scale: scale
    });
  }

  titleProps() {
    let { stage_width, social_title } = this.state;

    var text = new Konva.Text({
      text: social_title,
      fontSize: 60,
      fontStyle: "bold",
      fontFamily: "Nunito"
    });

    var width = (stage_width * 0.8) / 2.1;
    var align = "center";

    return { width: width, align: align };
  }

  render() {
    const _this = this;

    return (
      <React.Fragment>
        <form onSubmit={this.handlerSubmit}>
          <div className="row">
            <div className="col col-md-7">
              <div id="card-images" className="card-images">
                <div id="container">
                  <Stage
                    width={this.state.stage_width}
                    height={this.state.stage_height}
                    scaleX={this.state.stage_scale}
                    scaleY={this.state.stage_scale}
                  >
                    <Layer>
                      <URLImage
                        id="social_image"
                        src={this.state.social_image_url}
                        x={0}
                        y={0}
                        width={1024}
                        height={768}
                      />
                      <Text
                        name="social_title"
                        x={this.state.stage_width / 2}
                        y={304}
                        width={
                          this.state.stage_width *
                          (1 / this.state.stage_scale) *
                          0.8
                        }
                        text={this.state.social_title}
                        offsetX={this.titleProps()["width"]}
                        fill="#fff"
                        align={this.titleProps()["align"]}
                        fontSize={60}
                        fontStyle="bold"
                        fontFamily="Nunito"
                      />
                      <URLImage
                        id="social_logo"
                        src={this.state.social_logo_url}
                        x={this.state.social_logo_x}
                        y={this.state.social_logo_y}
                        width={this.state.social_logo_width}
                        height={this.state.social_logo_height}
                        draggable={true}
                        rotateEnabled={false}
                        isSelected={true}
                        onChange={attrs => {
                          _this.setState({
                            social_logo_x: _.toInteger(attrs.x),
                            social_logo_y: _.toInteger(attrs.y),
                            social_logo_width: attrs.width
                              ? _.toInteger(attrs.width)
                              : this.state.social_logo_width,
                            social_logo_height: attrs.height
                              ? _.toInteger(attrs.height)
                              : this.state.social_logo_height
                          });
                        }}
                      />
                    </Layer>
                  </Stage>
                </div>
              </div>
            </div>

            <div className="col col-md-5">
              <div className="alert alert-outline-info fade show" role="alert">
                <div className="alert-icon">
                  <i className="flaticon-questions-circular-button"></i>
                </div>
                <div className="alert-text">
                  <h5 className="font-weight-bold mb-1">
                    <i className="fas fa-images mr-2" />
                    <span>Sugerencias</span>
                  </h5>

                  <p className="text-muted mb-0 small">
                    <i className="fas fa-info-circle mr-2" />
                    <span>Imagen: Dimensiones 256x256px</span>
                  </p>

                  <p className="text-muted mb-0 small">
                    <i className="fas fa-info-circle mr-2" />
                    <span>Formatos permitidos: png, jpg, jpeg</span>
                  </p>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="user_social_background">Imagen de fondo</label>
                <select
                  defaultValue={this.state.social_background_color}
                  name="user[social_background]"
                  className="form-control"
                  onChange={this.handleBackground}
                >
                  {this.props.background_colors.map(function(
                    background_color,
                    index
                  ) {
                    return (
                      <option
                        value={background_color.id}
                        key={"color-" + index}
                      >
                        {background_color.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="social_title">Descripción de la carta</label>
                <input
                  className="form-control"
                  type="text"
                  name="user[social_title]"
                  id="social_title"
                  value={this.state.social_title}
                  onChange={event => {
                    _this.setState({
                      social_title: event.target.value
                    });
                  }}
                />
              </div>
              <input
                className="inputfile input-primary"
                type="file"
                name="user[social_logo]"
                id="social_logo"
                onChange={this.handleImageChange}
              />
              <label
                className="fileLabel"
                style={{ width: "100%" }}
                htmlFor="social_logo"
              >
                <i className="fas fa-upload mr-2" />
                <span>Cambiar Logo</span>
              </label>
              <button
                className="btn btn-outline-primary btn-block"
                type="submit"
              >
                Guardar
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
