import React, { useState } from "react";
import axios from "axios";
import ReactTagInput from "@pathofdev/react-tag-input";

const InputTag = ({ current_tags, pathname, current_user }) => {
  const [tags, setTags] = useState(current_tags || []);

  const handleOnChange = newTags => {
    setTags(newTags);
    const headers = {
      "X-User-Email": current_user.email,
      "X-User-Token": current_user.authentication_token
    };
    axios({
      url: pathname,
      method: "PATCH",
      headers,
      data: { project: { tags: newTags } }
    });
  };

  return (
    <ReactTagInput
      tags={tags}
      editable={true}
      placeholder={"Ingresar nuevo TAG y presionar ENTER"}
      onChange={newTags => handleOnChange(newTags)}
    />
  );
};
export default InputTag;
