import WebpackerReact from "webpacker-react";

// USER
import UserAlert from "../components/user_alert.js";
import AccountVerification from "../components/account_verification.js";
import BrandConfigurationForm from "../components/brand_configuration_form.js";
import SocialConfigurationForm from "../components/social_configuration_form.js";
import ContactUs from "../components/contact_us.js";
import ProductSpending from "../components/product_spending.js";

// PROJECT
import PostInPortal from "../components/post_in_portal.js";
import ProjectFilters from "../components/project_filters";
import Portals from "../components/portals/Portals";
import ProjectNotification from "../components/project_progress_notification.js";
import EventCalendar from "../components/event_calendar.js";
import Dashboard from "../components/dashboard.js";
import ProjectScores from "../components/project_scores.jsx";
import NewPublicationStepper from "../components/new_publication/new_publication.js.jsx";

// POSTULATION
import PostulationToastNotification from "../components/postulation_toast_notification.js";
import TogglePinned from "../components/toggle_pinned";

// APPLICANT
import ApplicantRater from "../components/postulation/rating.js";
import CandidateList from "../components/candidates_list";
import Board from "../components/kanban/board.js.jsx";

// INTERVIEW
import InterviewModal from "../components/interview_modal.jsx";

// SHORTLIST
import ShortlistProjectAttribute from "../components/shortlist_project_attributes.js";
import ShortlistConfiguration from "../components/configurations_tools/shortlist/shortlist_project_attributes.jsx";
import ButtonChatDemo from "../components/button_chat_demo.js";

// VYA
import VYAResults from "../components/vya/VYAResults.jsx";
import VYAConfiguration from "../components/configurations_tools/vya/vya.jsx";

// ZYVO
import ZyvoConfiguration from "../components/configurations_tools/zyvo/zyvo.jsx";
import ZyvoResults from "../components/zyvo/results.js";
import ZyvoProjectAttribute from "../components/zyvo/project_attributes.js";
import ZyvoCreateProfile from "../components/zyvo/create_profile.jsx";
import ZyvoProjectShow from "../components/zyvo/show_project";
import ZyvoProfileResults from "../components/zyvo/profile_results.jsx";

// CONTROL DOCS
import ControldocDocumentsAttributes from "../components/controldoc_documents_attributes.js";

// PRODUCTS
import PurchaseCar from "../components/purchase_car.js";
import FlowButton from "../components/purchase/flow_button.js";

// GENERALS
import JoyRideTour from "../components/joy_ride_tour.js";
import ShowNotification from "../components/show_notification";
import ShopingCart from "../components/shoping_cart";
import RenderButterToast from "../components/render_butter_toast";
import InputTag from "../components/input_tag";
import InternalDashboard from "../components/internal_dashboard";
import CvSearch from "../components/cv_search";
import VideoHelpModal from "../components/video_help";
import SocialShareButtons from "../components/commons/share_buttons.js.jsx";
import ShareCorporatePortal from "../components/commons/share_corporate_portal.jsx";

import FiltersSelectorStep from "../components/filters";

WebpackerReact.setup({
  UserAlert,
  ContactUs,
  AccountVerification,
  Portals,
  ShortlistProjectAttribute,
  ShortlistConfiguration,
  ZyvoConfiguration,
  VYAConfiguration,
  PostInPortal,
  ProjectNotification,
  Dashboard,
  PostulationToastNotification,
  BrandConfigurationForm,
  SocialConfigurationForm,
  PurchaseCar,
  ProductSpending,
  ApplicantRater,
  ButtonChatDemo,
  JoyRideTour,
  ShowNotification,
  ProjectFilters,
  ZyvoResults,
  VYAResults,
  ZyvoProjectAttribute,
  EventCalendar,
  ShopingCart,
  RenderButterToast,
  ControldocDocumentsAttributes,
  InterviewModal,
  ZyvoCreateProfile,
  ZyvoProjectShow,
  ZyvoProfileResults,
  ProjectScores,
  TogglePinned,
  FlowButton,
  InputTag,
  InternalDashboard,
  CandidateList,
  Board,
  CvSearch,
  NewPublicationStepper,
  FiltersSelectorStep,
  VideoHelpModal,
  SocialShareButtons,
  ShareCorporatePortal
});
