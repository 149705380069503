import React from "react";
import ButterToast, { Cinnamon } from "butter-toast";
import LazyComponent from "../lazy_component";

export default class PostulationToastNotification extends LazyComponent {
  constructor(props) {
    super(props);
    this.state = { userId: props.userId };
  }

  addNotification(data) {
    var { applicant, project } = data.postulation;

    ButterToast.raise({
      content: (
        <Cinnamon.Crisp
          scheme={Cinnamon.Crisp.SCHEME_BLUE}
          content={() => (
            <div>
              <a href={applicant.url} className="text-capitalize text-info">
                {applicant.name}
              </a>{" "}
              ha postulado al proceso{" "}
              <a className="text-muted" href={project.url}>
                {project.charge_name}
              </a>
            </div>
          )}
          title="Nuevo Candidato(a)"
          icon={<i className="fas fa-info fa-2x" />}
        />
      )
    });
  }

  componentDidMount() {
    var _this = this;
    var _id = this.state.userId;

    App.cable.subscriptions.create(
      { channel: "PostulationChannel", id: _id },
      { received: _this.addNotification }
    );
  }

  render() {
    return null;
  }
}
