import React, { Component, Fragment } from "react";
import Swal from "sweetalert2";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import _ from "lodash";
import axios from "axios";
import ShowNotification from "./show_notification";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (draggableStyle, isDragging) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  textAlign: "center",
  fontSize: "20px",
  padding: "7px 0px",
  maxWidth: "250px",
  backgroundColor: "rgb(255, 255, 255)",
  border: isDragging ? "" : "2px solid",
  borderRadius: "10px",
  margin: "10px auto",
  boxShadow: isDragging
    ? "0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1)"
    : "none",

  // change background colour if dragging
  color: isDragging ? "#2c2c2c" : "#5a3ee0",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  maxWidth: "250px",
  margin: "70px auto 20px",
  minHeight: "30em",
});

const previewBasePath = `${window.location.origin}/images/products/filters`;

export default class ProjectFilters extends Component {
  constructor(props) {
    super(props);
    if (!_.isEmpty(props.workflow_attributes)) {
      var items = _.map(props.filters, (filter) => {
        const find = _.find(props.workflow_attributes, {
          product_id: filter.product_id,
        });
        if (find) {
          filter["selected"] = true;
          filter["id"] = find.id;
        } else {
          filter.selected = false;
        }
        return filter;
      });
    } else {
      var items = props.filters;
    }
    this.state = {
      items: _.sortBy(items, ["order"]),
      isLoading: false,
    };

    this.onDragEnd = this.onDragEnd.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.calculateWeighing();
  }

  drawButtonActionText() {
    const btnText = "Continuar";
    return this.state.isLoading ? (
      <Fragment>
        {btnText} <i className="fas fa-spinner fa-pulse"></i>
      </Fragment>
    ) : (
      btnText
    );
  }

  handleSave() {
    this.setState({ isLoading: true });
    const headers = {
      "X-User-Email": this.props.current_user.email,
      "X-User-Token": this.props.current_user.authentication_token,
    };
    const selected_items = _.filter(this.state.items, (item) => {
      return item["selected"] == true || item["_destroy"] == true;
    });
    let project = { phases_attributes: selected_items };

    axios({
      url: this.props.path,
      type: "json",
      method: "PUT",
      headers,
      data: { project },
    })
      .then((response) => {
        this.setState({ isLoading: false });
        ShowNotification({
          message: response.data.message,
          type: "success",
        });
        window.location = response.data.redirect_to;
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        ShowNotification({
          message: error.response.data.message,
          type: "error",
        });
      });
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });
  }

  calculateWeighing() {
    var { items } = this.state;
    const selected = _.filter(this.state.items, ["selected", true]);
    if (!_.isEmpty(selected)) {
      const toSum = 1;
      var index = 0;
      var rest = 100;
      const item_ids = selected.map((_item) => {
        _item["weighing"] = 0;
        return _item["product_id"];
      });
      if (selected.length == 1) {
        var item_index = _.findIndex(items, ["product_id", item_ids[index]]);
        items[item_index]["weighing"] = 100;
      } else {
        const all_without_weighing = _.every(selected, (item) => {
          return !item["with_weighing"];
        });
        while (rest > 0) {
          var item_index = _.findIndex(items, ["product_id", item_ids[index]]);
          index = index == _.size(selected) - 1 ? 0 : index + 1;
          if (all_without_weighing || items[item_index]["with_weighing"]) {
            items[item_index]["weighing"] =
              items[item_index]["weighing"] + toSum;
            rest -= toSum;
          }
        }
      }
      this.setState({ items });
    }
  }

  selectItem(index) {
    var { items } = this.state;
    items[index]["selected"] = !!!items[index]["selected"];
    items[index]["_destroy"] = !!!items[index]["selected"];
    this.setState({ items }, () => {
      this.calculateWeighing();
    });
  }

  buyItem(index) {
    var { items } = this.state;
    const product = items[index];
    const { type_name } = product;

    Swal.fire({
      imageUrl: `${window.location.origin}/images/logo_${type_name}.png`,
      imageAlt: product.name,
      width: "700px",
      customClass: {
        popup: "product-info-modal",
        image: "img-fluid sweet-logo product-logo mr-auto",
      },
      html:
        I18n.t(`products.filter.${type_name}.description`) +
        '<div class="row mt-2"><div class="col-md-12">' +
        I18n.t(`products.filter.${type_name}.benefits_html`) +
        '<div class="owl-carousel">' +
        `<img src=${previewBasePath}/${type_name}/preview_1.png>` +
        `<img src=${previewBasePath}/${type_name}/preview_2.png>` +
        `<img src=${previewBasePath}/${type_name}/preview_3.png>` +
        "</div>" +
        "</div></div>",
      showCloseButton: true,
      focusConfirm: false,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Lo quiero!',
      confirmButtonAriaLabel: "Dedo arriba, lo quiero!",
    }).then((result) => {
      if (result.value)
        window.location.href = `/purchases/new?product_id=${product.id}`;
    });

    // Cambiar y Utilizar algun componente de OwlCarousel
    $(".owl-carousel").owlCarousel({
      items: 2,
      loop: true,
      margin: 10,
      center: true,
      autoplay: true,
    });
  }

  renderItem(item, index) {
    const selectedStyle = {
      backgroundColor: "#20bcfa",
      borderColor: "#20bcfa",
      color: "#ffffff",
      cursor: item.default ? "not-allowed" : "pointer",
    };

    return (
      <div
        className={`pointer alert alert-outline-${
          item.buyed ? (item.selected ? "success" : "info") : "warning"
        }`}
        key={`${item.type_name}-${index}`}
        onClick={(e) =>
          item.buyed
            ? item.default
              ? null
              : this.selectItem(index)
            : this.buyItem(index)
        }
        role="alert"
      >
        <div className="alert-icon">
          <i
            className={
              item.buyed
                ? item.selected
                  ? "flaticon-list-3"
                  : "flaticon-add-circular-button"
                : "flaticon-bag"
            }
          ></i>
        </div>
        <div className="alert-text"> {_.startCase(`${item.title}`)}</div>
      </div>
    );
  }

  render() {
    const selected_items = _.filter(this.state.items, ["selected", true]);
    //TODO: partial fix not show vya
    const itemsWithOutVYA = this.state.items.filter( item => item.type_name !== "vya" )

    return (
      <div>
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">
                Filtro de Candidatos{" "}
                <small>
                  <span>Selecciona los filtros a utilizar.</span>
                </small>
              </h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="row">
              <div className="col col-md-3">
                {itemsWithOutVYA.map((item, index) =>
                  this.renderItem(item, index)
                )}
              </div>
              <div className="col col-md-9 funnel-div">
                <div className="align-self-center text-muted text-center">
                  Confirma y modifica en esta área el orden en qué los filtros
                  serán aplicados.
                </div>

                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        className="text-center"
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                      >
                        {_.filter(this.state.items, ["selected", true]).map(
                          (item, index) => (
                            <Draggable
                              key={item.product_id}
                              isDragDisabled={true}
                              draggableId={item.product_id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <React.Fragment>
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                    style={getItemStyle(
                                      provided.draggableProps.style,
                                      snapshot.isDragging
                                    )}
                                  >
                                    {_.startCase(item.title)}
                                  </div>
                                  {provided.placeholder}
                                </React.Fragment>
                              )}
                            </Draggable>
                          )
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
          <div className="kt-portlet__foot">
            <div className="row align-items-center">
              <div className="col kt-align-right">
                <button
                  type="submit"
                  className="btn btn-info mt-3"
                  disabled={selected_items.length <= 0}
                  onClick={this.handleSave}
                >
                  {this.drawButtonActionText()}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
