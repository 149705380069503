import React from "react";
import ButterToast, { Cinnamon, POS_RIGHT, POS_BOTTOM } from "butter-toast";

const ShowNotification = data => {
  var { message, type } = data;
  var _title = type == "success" ? "Excelente!" : "Error!";
  var icon =
    type == "success" ? (
      <i className="far fa-thumbs-up fa-2x text-info"></i>
    ) : (
      <i className="far fa-thumbs-down fa-2x text-danger"></i>
    );
  var _type =
    type == "success" ? Cinnamon.Crisp.SCHEME_BLUE : Cinnamon.Crisp.SCHEME_RED;

  ButterToast.raise({
    content: (
      <Cinnamon.Crisp
        scheme={_type}
        content={() => <span>{message}</span>}
        title={_title}
        icon={icon}
      />
    )
  });
};

export default ShowNotification;
