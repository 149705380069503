import React from "react";
import LazyComponent from "../lazy_component";
import _ from "lodash";
import { Row, Col, Container } from "reactstrap"

import SpendingWrapper from "./product/spending_wrapper";

export default class ProductSpending extends LazyComponent {
  state = { modal: false };

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  calculatedUsage() {
    let data = {};

    _.map(this.props.global_resume, (object, key) => {
      let { quota, used } = object;
      let available = quota - used;
      let percentage = quota == -1 ? 0 : (used * 100) / quota;
      data[key] = {
        quota,
        used,
        available,
        percentage,
      };
    });

    return data;
  }

  drawCharts() {
    const calculatedUsage = this.calculatedUsage();

    const renderIcon = (product) => {
      if (product[3].unlimited) {
        return (
          <>
            <h5 className="mt-0">{product[0]}</h5>
            <i className="fas fa-infinity fa-5x text-success" />
            <h5 className="text-primary mt-2">Ilimitado</h5>
          </>
        );
      } else if (product[3].empty) {
        return (
          <>
            <h5 className="mt-0">{product[0]}</h5>
            <i className="fas fa-battery-empty fa-5x text-danger" />
            <h5 className="text-primary mt-2">Vacio</h5>
          </>
        );
      } else {
        return (
          <SpendingWrapper
            mainColor={product[4]}
            title={product[0]}
            data={product[1]}
            colors={[product[2][0], product[2][1]]}
          />
        );
      }
    };

    const productData = [
      [
        "Procesos",
        [
          ["Usados", calculatedUsage["project"]["used"]],
          ["Disponibles", calculatedUsage["project"]["available"]],
          ["Porcentaje", Math.round(calculatedUsage["project"]["percentage"])],
        ],
        ["#b5a7f1", "#5a3ee0"],
        {
          unlimited: calculatedUsage["project"]["quota"] == -1,
          empty: calculatedUsage["project"]["quota"] == 0,
        },
      ],
      [
        "Filtro Curricular",
        [
          ["Usados", calculatedUsage["shortlist"]["used"]],
          ["Disponibles", calculatedUsage["shortlist"]["available"]],
          [
            "Porcentaje",
            Math.round(calculatedUsage["shortlist"]["percentage"]),
          ],
        ],
        ["#a4e9f4", "#17a2b8"],
        {
          unlimited: calculatedUsage["shortlist"]["quota"] == -1,
          empty: calculatedUsage["shortlist"]["quota"] == 0,
        },
      ],
      [
        "Zyvo",
        [
          ["Usados", calculatedUsage["zyvo"]["used"]],
          ["Disponibles", calculatedUsage["zyvo"]["available"]],
          ["Porcentaje", Math.round(calculatedUsage["zyvo"]["percentage"])],
        ],
        ["#adebbb", "#28a745"],
        {
          unlimited: calculatedUsage["zyvo"]["quota"] == -1,
          empty: calculatedUsage["zyvo"]["quota"] == 0,
        },
      ],
      [
        "VYA",
        [
          ["Usados", calculatedUsage["vya"]["used"]],
          ["Disponibles", calculatedUsage["vya"]["available"]],
          ["Porcentaje", Math.round(calculatedUsage["vya"]["percentage"])],
        ],
        ["#adebbb", "#28a745"],
        {
          unlimited: calculatedUsage["vya"]["quota"] == -1,
          empty: calculatedUsage["vya"]["quota"] == 0,
        },
      ],
    ];

    const available_projects = calculatedUsage["project"]["available"];
    const enought_projects =
      calculatedUsage["project"]["quota"] == -1 ||
      available_projects > 0 ||
      available_projects == -1;

    return (
      <div className="text-center">
        {enought_projects ? (
          <>
            <button className="card-link btn btn-outline-primary mb-5">
              <a
                href="/pricing"
                className="d-flex justify-content-center d-flex align-items-center"
              >
                <i className="fas fa-shopping-bag mr-1" />
                Ver todos los planes
              </a>
            </button>
            <Container>
              <Row className="text-center">
                {productData.map((product, index) => (
                  <Col 
                    key={product[0]+index}
                    xs={`${product[0].includes("Tests") ? "12" : "6"}`}
                  >
                    {renderIcon(product)}
                  </Col>
                ))}
              </Row>
            </Container>
          </>
        ) : (
          <div className="mt-5">
            <a href="/products" className="">
              <img src={this.props.nina_buy_pack} alt="nina" width="100%" />
            </a>
          </div>
        )}
      </div>
    );
  }

  render() {
    return this.drawCharts();
  }
}
