import React from "react";
import LazyComponent from "../lazy_component";
import _ from "lodash";
import moment from "moment";
import ShortlistDefaultQuestions from "./shortlist_default_questions";
import {
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

export default class ShortlistProjectEasy extends LazyComponent {
  constructor(props) {
    super(props);

    this.state = {
      selected: _.filter(ShortlistDefaultQuestions, question => {
        const index = _.findIndex(props.questions, _question => {
          return _question.description.includes(question.description);
        });
        if (index != -1) {
          if (_.isEmpty(question["id"]))
            question["id"] = props.questions[index]["id"];
          if (_.isEmpty(question["killer_value"]))
            question["killer_value"] = props.questions[index]["killer_value"];
          question["_value"] = _.last(
            props.questions[index].description.split(question.description)
          ).split("?")[0];
          return true;
        }
      })
    };
  }

  isIncluded(question) {
    const { selected } = this.state;
    return !!_.find(selected, { _id: question._id });
  }

  handleSelect(question) {
    var { selected } = this.state;
    if (this.isIncluded(question)) {
      if (question._destroy) {
        question._destroy = false;
      } else {
        _.remove(selected, item => {
          if (item._id == question._id) {
            if (item.id) {
              item["_destroy"] = true;
              return false;
            } else {
              return true;
            }
          }
        });
      }
    } else {
      selected.push(question);
    }
    this.setState({ selected }, () => {
      this.props.updateQuestions(selected);
    });
  }

  handleChange({ e, question }) {
    const { selected } = this.state;
    const { value } = e.target;
    var index = _.findIndex(selected, { _id: question._id });
    switch (question._id) {
      case "rent":
      case "exp":
      case "aval":
      case "regions":
        selected[index].killer_value = value;
        break;
      case "exp_in":
      case "knwl_in":
      case "cert_in":
      case "title_in":
        selected[index].description = `${question._label} ${value}?`;
        selected[index]._value = value;
        break;
    }
    selected[index].order = index;
    this.setState({ selected }, () => {
      this.props.updateQuestions(selected);
    });
  }

  renderNumeric({ question, index, options }) {
    const { type, val } = options;
    var input_data = {};
    switch (type) {
      case "date":
        input_data["min"] = moment().format("YYYY-MM-DD");
        input_data["value"] = question.killer_value;
        break;
      case "number":
        input_data["min"] = 0;
        input_data["value"] = question.killer_value
          ? parseInt(question.killer_value.toString().replace(/\D/g, ""))
          : question.killer_value;
        break;
    }

    return (
      <div className="card card-hover mb-3">
        <div className="card-header bg-white border-0 text-primary">
          {question._title}
        </div>
        <div className="card-body">
          <h6 className="card-subtitle mb-2 text-muted">
            {question._sub_title}
          </h6>
          <div className="row">
            <div className="col-md-6">
              <div className="form-inline">
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                  <Label for="min_number" className="mr-sm-2">
                    Mínimo
                  </Label>
                  <Input
                    type={type}
                    min={input_data.min}
                    placeholder="Desde"
                    required={val == "min"}
                    value={val == "min" ? input_data.value : undefined}
                    onChange={e =>
                      val == "min"
                        ? this.handleChange({ e, question })
                        : undefined
                    }
                    name={
                      val == "min"
                        ? `${this.props.form_name}[${index}][killer_value]`
                        : undefined
                    }
                  />
                </FormGroup>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-inline">
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                  <Label for="max_number" className="mr-sm-2">
                    Máximo
                  </Label>
                  <Input
                    type={type}
                    min={input_data.min}
                    placeholder="Hasta"
                    required={val == "max"}
                    value={val == "max" ? input_data.value : undefined}
                    onChange={e =>
                      val == "max"
                        ? this.handleChange({ e, question })
                        : undefined
                    }
                    name={
                      val == "max"
                        ? `${this.props.form_name}[${index}][killer_value]`
                        : undefined
                    }
                  />
                  {this.renderHidden({
                    question,
                    index,
                    options: { frozen: true, with_killer_value: false }
                  })}
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderInsertWord({ question, index, options = { frozen: false } }) {
    const { frozen } = options;
    return (
      <div className="card card-hover mb-3">
        <div className="card-header bg-white border-0 text-primary">
          {question._title}
        </div>
        <div className="card-body">
          <h6 className="card-subtitle mb-2 text-muted">
            {question._sub_title}
          </h6>
          <div className="row">
            <div className="col-md-12">
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0 w-100">
                <Label for="max_number" className="mr-sm-2 w-auto">
                  {question._label}
                </Label>
                <InputGroup>
                  <Input
                    readOnly={frozen}
                    type="text"
                    placeholder="Completa acá lo que necesites"
                    value={frozen ? question.description : question._value}
                    onChange={e => this.handleChange({ e, question })}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>{frozen ? "" : "?"}</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {this.renderHidden({
                  question,
                  index,
                  options: { frozen, with_killer_value: true }
                })}
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderHidden({
    question,
    index,
    destroy = false,
    options = { frozen: true, with_killer_value: true }
  }) {
    const { frozen, with_killer_value } = options;
    return (
      <div>
        <input
          type="hidden"
          name={`${this.props.form_name}[${index}][id]`}
          value={question.id}
        />
        <input
          type="hidden"
          name={`${this.props.form_name}[${index}][description]`}
          value={
            frozen
              ? question.description
              : `${question._label} ${question._value}?`
          }
        />
        {with_killer_value ? (
          <input
            type="hidden"
            name={`${this.props.form_name}[${index}][killer_value]`}
            value={question.killer_value}
          />
        ) : null}
        <input
          type="hidden"
          name={`${this.props.form_name}[${index}][value_type]`}
          value={question.value_type}
        />
        <input
          type="hidden"
          name={`${this.props.form_name}[${index}][killer_condition]`}
          value={question.killer_condition}
        />
        {destroy ? (
          <input
            type="hidden"
            name={`${this.props.form_name}[${index}][_destroy]`}
            value={question._destroy}
          />
        ) : null}
      </div>
    );
  }

  renderForm(question, index) {
    if (question._destroy) {
      return this.renderHidden({ question, index, destroy: true });
    } else if (question._hidden) {
      return this.renderHidden({ question, index });
    } else {
      switch (question._id) {
        case "rent":
          return this.renderNumeric({
            question,
            index,
            options: { type: "number", val: "max" }
          });
        case "exp":
          return this.renderNumeric({
            question,
            index,
            options: { type: "number", val: "min" }
          });
        case "exp_in":
        case "knwl_in":
        case "cert_in":
        case "title_in":
          return this.renderInsertWord({ question, index });
        case "aval":
          return this.renderNumeric({
            question,
            index,
            options: { type: "date", val: "max" }
          });
        case "regions":
          return this.renderInsertWord({
            question,
            index,
            options: { frozen: true }
          });
      }
    }
  }

  renderList({ questions, active_class = false, clickeable = false }) {
    return (
      <ul className="list-group">
        {questions.map((question, index) => {
          return (
            <li
              key={`${question._title}_${index}`}
              className={`list-group-item ${
                active_class && this.isIncluded(question) && !question._destroy
                  ? "active border-white"
                  : ""
              } ${clickeable ? "pointer" : ""}`}
              onClick={event =>
                clickeable ? this.handleSelect(question) : null
              }
            >
              {question._title}
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    const { selected } = this.state;

    return (
      <div className="row mt-4">
        <div className="col-md-6">
          <h5 className="card-title text-center">
            Listado preguntas frecuentes
          </h5>
          <h6 className="mb-2 text-muted text-center">
            Selecciona cualquier ítem. Los podrás configurar y modificar según
            lo necesites.
          </h6>
          {this.renderList({
            questions: _.filter(ShortlistDefaultQuestions, item => {
              return !item._hidden;
            }),
            active_class: true,
            clickeable: true
          })}
        </div>
        <div className="col-md-6">
          <h5 className="card-title text-center">Tus preguntas</h5>
          <h6 className="mb-2 text-muted text-center">
            Estos son los ítems que tienes seleccionados hasta el momento. A
            continuación los puedes configurar.
          </h6>
          {this.renderList({
            questions: _.filter(selected, item => {
              return !(item._destroy || item._hidden);
            })
          })}
        </div>

        <div className="row justify-content-md-center mt-5">
          <h5 className="col-md-12 card-title text-center mb-0">
            Configuremos las preguntas que escojiste anteriormente
          </h5>
          <small className="col-md-12 text-muted text-center mb-4">
            Si tienes alguna duda con las preguntas, ¡contáctanos!
          </small>
          {selected.map((question, index) => {
            return (
              <div className="col-md-8" key={`question_${index}`}>
                {this.renderForm(question, index)}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
