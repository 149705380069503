import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Label,
  Input,
  CustomInput,
  Form,
  FormGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  FormFeedback
} from "reactstrap";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BaseSelect from  "react-select";
import FixRequiredSelect from "../../commons/FixRequiredSelect";
import { useForm, Controller } from 'react-hook-form';
import axios from "axios";
import ShowNotification from "../../show_notification";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck } from "@fortawesome/free-solid-svg-icons";


const detailsForm = props => {
  const {
    toggleTab,
    regions,
    profileTypes,
    studySituations,
    levels,
    steps,
    headers,
    projectId,
    detailsData,
    careers
  } = props;

  const MySwal = withReactContent(Swal);

  const showAlert = (isComplete, projectId) => {
    MySwal.fire({
      title: isComplete ? 'Configuración Finalizada' : 'Borrador de Proceso guardado',
      text: isComplete ? 'Tu proceso ha sido creado exitosamente, y será revisado por nuesto equipo para su publicación' : 'Puedes finalizar la configuración de tu proceso más tarde',
      type: 'success',
      confirmButtonText: 'Ver Proceso',
      confirmButtonClass: "btn btn-primary"
    }).then( result => {
      if ( result.value ) {
        localStorage.removeItem("project_id")
        localStorage.removeItem("steps")
        isComplete ?
          window.location.href = `${window.location.origin}/process`
          :
          window.location.href = `${window.location.origin}/process`

      }
    });
  }

  const { register, handleSubmit, errors, control } = useForm()

  const [ selectRegion, setSelectRegion ] = useState(null)
  const [ communeIsDisabled, setCommuneIsDisabled ] = useState(true)
  const [ communeIsLoading, setCommuneIsLoading ] = useState(false)
  const [ selectCommune, setSelectCommune ] = useState(null)
  const [ selectStudySituation, setSelectStudySituation ] = useState(null)
  const [ selectProfileType, setSelectProfileType ] = useState(null)
  const [ selectLevel, setSelectLevel ] = useState(null)
  const [ selectCareer, setSelectCareer ] = useState(null)
  const [ careerIsDisabled, setCareerIsDisabled ] = useState(false)
  const [ disabled, setDisabled ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isDisabled, ] = useState( detailsData !== null ? true : false )

  const Select = props => {
    return (
      <FixRequiredSelect
        {...props}
        SelectComponent={BaseSelect}
        options={props.options}
      />
    )
  }

  const onSubmit = value => {
    setIsLoading(true)

    if( disabled ) {
      axios({
        method: 'POST',
        url: `${window.location.origin}/project/additional_detail`,
        headers: headers,
        data: { project_id: projectId, complete_later: true }
      })
      .then( response => {
        if(response.status === 200) {
          ShowNotification({
            message: "Detalles adicionales guardados en borradores, puedes continuar...",
            type: "success"
          });
          setIsLoading(false)
          steps("portals_selected")
          toggleTab("portals_selected")
          window.scrollTo(0, 0)
        } else {
          ShowNotification({
            message: "Ups, ocurrió un error. Vuelve a Intentar...",
            type: "error"
          })
        }
      })
      .catch( error => {
        setIsLoading(false)
        ShowNotification({
          message: "Ups, ocurrió un error. Vuelve a Intentar...",
          type: "error"
        });
        console.log(error)
      })
    } else {
      value.project_id = projectId
      axios({
        method: 'POST',
        url: `${window.location.origin}/project/additional_detail`,
        headers: headers,
        data: value
      })
      .then( response => {
        if(response.status === 200) {
          ShowNotification({
            message: "Detalles adicionales guardados en borradores, puedes continuar...",
            type: "success"
          });
          setIsLoading(false)
          steps("portals_selected")
          toggleTab("portals_selected")
          window.scrollTo(0, 0)
        } else {
          ShowNotification({
            message: "Ups, ocurrió un error. Vuelve a Intentar...",
            type: "error"
          })
          setIsLoading(false)
        }
      })
      .catch( error => {
        setIsLoading(false)
        ShowNotification({
          message: "Ups, ocurrió un error. Vuelve a Intentar...",
          type: "error"
        });
        console.log(error)
      })
    }
  }

  const handleRegion = value => {
    setCommuneIsDisabled(true)
    setCommuneIsLoading(true)
    axios({
      method: 'GET',
      url: `${window.location.origin}/process/communes/${value.value}`,
      headers: headers
    })
    .then( response => {
      if(response.status === 200) {
        setCommuneIsDisabled(false)
        setSelectCommune( response.data.map( commune => ( { label: commune.text, value: commune.id } ) ) )
      } else {
        setCommuneIsDisabled(false)
        ShowNotification({
          message: "Ups, ocurrió un error. Vuelve a Intentar...",
          type: "error"
        })
      }
      setCommuneIsLoading(false)
    })
    .catch( error => {
      setCommuneIsDisabled(false)
      ShowNotification({
        message: "Ups, ocurrio un error. Vuelve a Intentar...",
        type: "error"
      });
      console.log(error)
      setCommuneIsLoading(false)
    })
  }

  const handleCareer = value => {
    if( value && value.label.includes("Universitaria") ) {
      setCareerIsDisabled(true)
    } else {
      setCareerIsDisabled(false)
    }
  }

  useEffect(() => {},
    [
      selectRegion, selectCommune, selectStudySituation, selectProfileType,
      selectLevel, communeIsDisabled, selectCareer, careerIsDisabled
    ]
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Card className="mb-3">
        <CardHeader className="font-weight-bold">
          <span className="kt-portlet__head-icon mr-2">
            <i className="flaticon-map-location"></i>
          </span>
          Ubicación
        </CardHeader>
        <CardBody>
          <Row form>
            <Col md={4}>
              <FormGroup>
                <Label for="region">Región</Label>
                <Controller
                  render={({ onChange, onBlur, value, name, ref }) => (
                    <Select
                      options={ regions }
                      placeholder="Seleccione una opción"
                      isLoading={ regions === null }
                      isDisabled={ regions === null || disabled || isDisabled }
                      required={!disabled}
                      value={ value }
                      onChange={ value => { onChange(value); handleRegion(value) }}
                      disabled={ disabled }
                    />
                  )}
                  name="region"
                  id="region"
                  control={control}
                  defaultValue={ detailsData && detailsData.region ?
                    detailsData.region : selectRegion || ""
                  }
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="commune">Comuna</Label>
                <Controller
                  render={({ onChange, onBlur, value, name, ref }) => (
                    <Select
                      options={ selectCommune }
                      placeholder={ communeIsDisabled ? "Debe Seleccionar una Región" : "Seleccione una opción" }
                      isLoading={ communeIsLoading }
                      isDisabled={ communeIsDisabled }
                      required={!disabled}
                      value={ value }
                      onChange={ onChange }
                    />
                  )}
                  name="commune"
                  id="commune"
                  control={control}
                  defaultValue={ selectCommune || "" }
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="mb-3">
        <CardHeader className="font-weight-bold">
          <span className="kt-portlet__head-icon mr-2">
            <i className="flaticon-users"></i>
          </span>
          Perfil Candidato
        </CardHeader>
        <CardBody>
          <Row form>
            <Col md={4}>
              <FormGroup>
                <Label for="experience_years">Años de Experiencia</Label>
                <Input
                  type="number"
                  name="experience_years"
                  id="experience_years"
                  innerRef={ register({
                    required: {
                      value: !disabled,
                      message: "Los años de experiencia son necesarios."
                    }
                  })}
                  defaultValue={ detailsData && detailsData.experience_years || "" }
                  disabled={disabled || isDisabled}
                  invalid={ errors.experience_years !== undefined ? true : false }
                />
                  { errors.experience_years &&
                    <FormFeedback invalid={ errors.experience_years !== undefined ? "true" : "false" }>{ errors.experience_years.message }</FormFeedback>
                  }
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="profile_type_id">Tipo de Perfil</Label>
                <Controller
                  render={({ onChange, onBlur, value, name, ref }) => (
                    <Select
                      options={ profileTypes }
                      placeholder="Seleccione una opción"
                      isLoading={ profileTypes === null }
                      isDisabled={ profileTypes === null || disabled || isDisabled }
                      required={!disabled}
                      value={ value }
                      onChange={ onChange }
                    />
                  )}
                  name="profile_type_id"
                  id="profile_type_id"
                  control={control}
                  defaultValue={ detailsData && detailsData.profile_type_id ?
                    detailsData.profile_type_id : selectProfileType || ""
                  }
                  onChange={ value => setSelectProfileType(value) }
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="degreeStatus">Situación de Estudios</Label>
                <Controller
                  render={({ onChange, onBlur, value, name, ref }) => (
                    <Select
                      options={ studySituations }
                      placeholder="Seleccione una opción"
                      isLoading={ studySituations === null }
                      isDisabled={ studySituations === null || disabled || isDisabled }
                      required={!disabled}
                      value={ value }
                      onChange={ onChange }
                    />
                  )}
                  name="study_situation_id"
                  id="study_situation_id"
                  control={control}
                  defaultValue={ selectStudySituation || "" }
                  defaultValue={ detailsData && detailsData.study_situation_id ?
                    detailsData.study_situation_id : selectStudySituation || ""
                  }
                  onChange={ value => setSelectStudySituation(value) }
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="level_id">Nivel</Label>
                <Controller
                  render={({ onChange, onBlur, value, name, ref }) => (
                    <Select
                      options={ levels }
                      placeholder="Seleccione una opción"
                      isLoading={ levels === null }
                      isDisabled={ levels === null || disabled || isDisabled }
                      required={!disabled}
                      value={ value }
                      onChange={ value => { onChange(value); handleCareer(value) }}
                    />
                  )}
                  name="level_id"
                  id="level_id"
                  control={control}
                  defaultValue={ selectLevel || "" }
                  defaultValue={ detailsData && detailsData.level_id ?
                    detailsData.level_id : selectLevel || ""
                  }
                  onChange={ value => setSelectLevel(value) }
                />
              </FormGroup>
            </Col>
            { careerIsDisabled &&
              <Col md={4}>
                <FormGroup>
                  <Label for="career_id">Carreras</Label>
                  <Controller
                    render={({ onChange, onBlur, value, name, ref }) => (
                      <Select
                        options={ careers }
                        placeholder="Seleccione una opción"
                        isLoading={ careers === null }
                        isDisabled={ careers === null || disabled || isDisabled }
                        required={!disabled}
                        value={ value }
                        onChange={ onChange }
                      />
                    )}
                    name="career_id"
                    id="career_id"
                    control={control}
                    defaultValue={ selectCareer || "" }
                  />
                </FormGroup>
              </Col>
            }
          </Row>
        </CardBody>
      </Card>
      <Row>
        <Col md="12" className="text-right">
          { !isDisabled &&
            <React.Fragment>
              {/*
              <div className="d-flex justify-content-end">
                <Label for="level_id" className="pr-2">Completar más tarde</Label>
                <CustomInput
                  type="switch"
                  id="continuelater"
                  name="continuelater"
                  onChange={ () =>  setDisabled(!disabled) }
                />
              </div>
              */}
              {isLoading ?
                <Button
                  color="secondary"
                  disabled
                >
                  <Spinner color="primary" style={{ width: '2rem', height: '2rem' }} />
                </Button>
                :
                <Button
                  color="success"
                  className="my-4"
                  type="submit"
                >
                  Continuar
                  <i className="fas fa-arrow-right ml-2"></i>
                </Button>
              }
            </React.Fragment>
          }
        </Col>
      </Row>
    </Form>
  )
}
export default detailsForm;
