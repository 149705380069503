import React, { useState } from "react";
import { ReactiveBase, ReactiveList } from "@appbaseio/reactivesearch";

import { defaultQuery, renderFilters, componentsIds } from "./common_board";
import PostulationCard from "./postulation/card";
import ModalResults from "./postulation/modal_results";

import ProjectsModal from "./postulation/projects_modal.js";

import theme from "./reactive_search/theme";
import CustomSelectedFilters from "./reactive_search/custom_selected_filters";

const CvSearch = (props) => {
  const { is_admin, projects_ids, authentication_headers } = props;
  const [selectedPostulation, setSelectedPostulation] = useState({});

  const [projects, setProjects] = useState([]);

  return (
    <ReactiveBase
      app="postulations"
      url={`${window.location.origin}/elastic`}
      theme={theme}
      headers={{
        "Content-Type": "application/json",
        ...authentication_headers,
      }}
      transformRequest={(data) => {
        return { ...data, body: JSON.stringify(data.body) };
      }}
    >
      <ModalResults
        selectedPostulation={selectedPostulation}
        setSelectedPostulation={setSelectedPostulation}
        authentificationHeaders={authentication_headers}
      />

      <ProjectsModal
        projects={projects}
        opened={true}
        setProjects={setProjects}
      />

      <div className="row">
        <div className="col-sm-12 col-md-3">
          <div className="kt-portlet">
            <div className="kt-portlet__body">
              <div
                className="custom_scroll"
                style={{ height: "2615px", overflowY: "auto" }}
              >
                {renderFilters({
                  is_admin,
                  projects_ids,
                  withDataSearch: true,
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-9">
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__body">
              <CustomSelectedFilters />
            </div>
          </div>

          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__body">
              <div className="row">
                <div className="col-md-12">
                  <ReactiveList
                    innerClass={{ resultsInfo: "m-0", sortOptions: "m-0" }}
                    componentId="postulationsResult"
                    dataField={"applicant.name"}
                    defaultQuery={defaultQuery(projects_ids)}
                    pagination={true}
                    paginationAt="bottom"
                    renderNoResults={() => ("No se encontraron coincidencias en sus postulantes ni en los de su organización.")}
                    showLoader={false}
                    render={({ loading, error, data, resultStats }) => {
                      if (loading) {
                        return <div>Cargando...</div>;
                      }
                      if (error) {
                        return <div>Algo sali&oacute; mal! Detalles del error: {JSON.stringify(error)}</div>;
                      }
                      let dataFiltered = [];
                      let applicantNotShowed = 0;
                      if (data) {
                        const appIds = [];
                        dataFiltered = data.filter((item) => {
                          const response = appIds.includes(item.applicant.id) ? false : true;
                          appIds.push(item.applicant.id);
                          if (!response) { applicantNotShowed = applicantNotShowed + 1; }
                          return response;
                        });
                      }
                      return (
                        <>
                          {(dataFiltered.length !== 0) ?
                            (<span style={{ position: "relative", top: "-28px" }}>
                              <small className="text-primary">
                                {`Mostrando ${resultStats.displayedResults - applicantNotShowed} de ${resultStats.numberOfResults - applicantNotShowed} en ${resultStats.time} ms`}
                              </small>
                            </span>)
                          : null }
                          {dataFiltered.map(item => (
                            <PostulationCard
                              key={item._id}
                              postulation={item}
                              setSelectedPostulation={setSelectedPostulation}
                              setProjects={setProjects}
                              authentificationHeaders={authentication_headers}
                            />
                          ))}
                        </>
                      );
                    }}
                    sortOptions={[
                      {
                        label: "Puntaje Global Descendiente",
                        dataField: "global_score",
                        sortBy: "desc",
                      },
                      {
                        label: "Puntaje Global Ascendiente",
                        dataField: "global_score",
                        sortBy: "asc",
                      },
                      {
                        label: "Match CV Descendiente",
                        dataField: "cv_score",
                        sortBy: "desc",
                      },
                      {
                        label: "Match CV Ascendiente",
                        dataField: "cv_score",
                        sortBy: "asc",
                      },
                      {
                        label: "Estrellas Descendiente",
                        dataField: "rating",
                        sortBy: "desc",
                      },
                      {
                        label: "Estrellas Ascendiente",
                        dataField: "rating",
                        sortBy: "asc",
                      },
                      {
                        label: "Filtro Curricular Descendiente",
                        dataField: "shortlist.score",
                        sortBy: "desc",
                      },
                      {
                        label: "Filtro Curricular Ascendiente",
                        dataField: "shortlist.score",
                        sortBy: "asc",
                      },
                      {
                        label: "Tests Descendiente",
                        dataField: "zyvo.score",
                        sortBy: "desc",
                      },
                      {
                        label: "Tests Ascendiente",
                        dataField: "zyvo.score",
                        sortBy: "asc",
                      },
                    ]}
                    showResultStats={false}
                    react={{
                      and: componentsIds("postulationsResult"),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactiveBase>
  );
};

export default CvSearch;
