import React, { useState } from "react";
import axios from "axios";
import ShowNotification from "./show_notification";
import _ from "lodash";
import { Tooltip } from 'reactstrap';

const TogglePinned = ({
  current_pinned_at,
  authentificationHeaders,
  postulation_id,
  reload
}) => {
  const [pinned_at, set_pinned_at] = useState(current_pinned_at);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const handleToggle = () => {
    axios({
      url: `${window.location.origin}/postulations/${postulation_id}/toggle_pinned_at`,
      type: "json",
      method: "PATCH",
      headers: authentificationHeaders
    })
      .then(response => {
        ShowNotification({
          message: response.data.message,
          type: "success"
        });
        set_pinned_at(response.data.pinned_at);
        if (reload) location.reload();
      })
      .catch(error => {
        ShowNotification({
          message: _.first(
            _.map(error.response.data.message, (message, model) => {
              return `${model}: ${message}`;
            })
          ),
          type: "error"
        });
      });
  };

  return (
    <div className="pin-card">
      <div className={!!pinned_at ? "pinned" : ""}>
        <button
          className={
            "btn btn-link btn-sm " +
            (!!pinned_at ? "text-warning" : "text-muted")
          }
          title={pinned_at ? "Desanclar" : "Anclar"}
          data-toggle="tooltip"
          data-placement="top"
          onClick={handleToggle}
        >
          <i className="fas fa-thumbtack fa-2x" />
        </button>
      </div>
    </div>
  );
};

export default TogglePinned;
