import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Progress } from "react-sweet-progress";
import _ from "lodash";

import moment from "moment";
import { hasProduct } from "../../candidates_list/functions";
import PostulationRating from "../rating";
import TogglePinned from "../../toggle_pinned";
import DropDownOptions from "./dropdown_options";
import axios from "axios";
import ShowNotification from "../../show_notification";
import { Spinner } from "reactstrap";

import ProjectsModal from "../projects_modal.js.jsx";


const PostulationCard = ({
  postulation,
  setSelectedPostulation,
  setProjects,
  authentificationHeaders,
  projectFilters,
  with_salary_question = true,
  with_years_question = true,
  evaluations
}) => {
  const { portal, project, applicant, highlight } = postulation;
  const [ haveTest, setHaveTest ] = useState(false)
  const [ isEvaluated, setEvaluated ] = useState(null)
  const [ isloading, setIsloading ] = useState(false)
  const [ isNew, setIsNew ] = useState(postulation.is_new)

  const [ vya, setVya ] = useState( postulation && postulation.vya ? postulation.vya : null )
  const [ zyvo, setZyvo ] = useState( postulation && postulation.zyvo ? postulation.zyvo : null )

  useEffect(() => {
    if( postulation &&
        postulation.zyvo ||
        postulation.shortlist ||
        postulation.vya
      ){
      setHaveTest(true)
    }

    if( !_.isNull(evaluations) ) {
      setEvaluated(false)
    }
  }, [ postulation, evaluations ])

  const toCurrency = (data) => {
    return data
      ? data.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
      : data;
  };

  const toYears = (data) => {
    if (_.isNumber(data) && data > 0) return `(${data} años)`;
  };

  const applicant_name = highlight["applicant.name"]
    ? highlight["applicant.name"]
    : applicant.name;

  const showDiscartedAt = () => {
    if (postulation.discarded_at) {
      return (
        <div className="kt-widget__subhead p-0">
          <a className="default-cursor" style={{ color: "red" }}>
            <i className="flaticon-warning"></i>
            {`Descartado el ${moment(postulation.discarded_at).format(
              "DD/MM/YYYY"
            )} - ${postulation.discarded_reason || ""}`}
          </a>
        </div>
      );
    }
  };

  const handledEvaluation = (postulationID, type) => {
    setIsloading(true)
    axios
    .post(`${window.location.origin}/evaluation/${postulationID}/${type}`, {
      id: postulationID,
      type: type
    }, {headers: authentificationHeaders})
    .then( res => {
      setIsloading(false)
      ShowNotification({
        message: `Enviada invitación a rendir test ${type} al candidato.`,
        type: "success"
      })
      setTimeout(()=>{
        window.location.reload(true)
      }, 3000)
    })
    .catch( error => {
      setIsloading(false)
      ShowNotification({
        message: `Ha Ocurrido un Error, intente nuevamente...`,
        type: "error"
      })
      console.log(error)
    })
  }

  const updateIsNew = async (postulationId) => {
    await axios
      .patch(`${window.location.origin}/postulations/${postulationId}/update_is_new`)
      .then((response) => {
        if (response.data.message === "OK") {

          ShowNotification({
            message: "El candidato ya no es nuevo.",
            type: "success"
          });

          return true;
        }
      })
      .catch(() => {
        ShowNotification({
          message: "Ha ocurrido un error.",
          type: "error"
        });
      });
  };

  return (
    <div
      className={`kt-portlet border candidates-card card-hover
        ${postulation.discarded_at ? "discarded_postulation" : ""}
        ${postulation.pinned_at ? "pinned-card" : ""}
      `}
    >
      <div className="toggle-select">
        <label htmlFor="defaultCheck1">
          <input type="checkbox" value="" id="defaultCheck1"/>
        </label>
      </div>
      <TogglePinned
        reload={false}
        current_pinned_at={postulation.pinned_at}
        authentificationHeaders={authentificationHeaders}
        postulation_id={postulation._id}
      />
      <div className="kt-portlet__body">
        <div className="kt-widget kt-widget--user-profile-3">
          <div className="kt-widget__top">
            <div className="kt-widget__media kt-hidden- d-none d-sm-block">
              <Avatar
                size="60"
                round
                name={applicant.name}
                email={applicant.email}
                color="rgba(93, 120, 255, 0.4)"
              />
            </div>

            <div className="kt-widget__content">
              <div className="kt-widget__head">
                <span className="d-md-inline-flex">
                  <a
                    href={`/process/${project.id}/applicants/${applicant.id}`}
                    className="kt-widget__username py-2"
                    target="_blank"
                    dangerouslySetInnerHTML={{
                      __html: applicant_name,
                    }}
                    onClick={() => {
                      if (isNew) {
                        if (updateIsNew(postulation._id)) {
                          setIsNew(false);
                        }
                      }
                    }}
                  ></a>
                  { toYears(postulation.age) }

                  { isNew &&
                    <i
                      className="flaticon2-bell-2 kt-font-warning py-2 px-1"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Nuevo Candidato"
                    ></i>
                    }
                </span>

                <div className="kt-widget__action">


                { setProjects &&
                  <button
                    onClick={
                      () => {
                        (postulation.applicant.projects.length === 0) ? setProjects([false]) : setProjects(postulation.applicant.projects);
                      }
                    }
                    className="btn btn-label-primary btn-sm btn-upper"
                    disabled={false}
                  >
                    Procesos
                  </button>
                }


                  <button
                    onClick={
                      haveTest ? () => setSelectedPostulation(postulation._id) : ()=>{}
                    }
                    className={`btn ${haveTest ? "btn-label-success" : "btn-label-warning active" } btn-sm btn-upper`}
                    disabled={!haveTest ? true : false}
                  >
                    { haveTest ? "Resultados" : "Sin Resultados" }
                  </button>

                  { evaluations !== null ?
                    <>
                      { isloading ?
                        <Spinner color="primary" style={{ width: '2rem', height: '2rem' }} />
                        :
                        <div className="dropdown dropdown-inline">
                          <button
                            className={`btn ${isEvaluated ? "btn-label-warning active disabled" : "btn-danger btn-sm"}  btn-upper`}
                            disabled={isEvaluated}
                            data-toggle={`${isEvaluated ? "" : "dropdown"}`}
                            aria-haspopup={`${isEvaluated ? "false" : "true"}`}
                            aria-expanded={`${isEvaluated ? "true" : "false"}`}
                          >
                            {isEvaluated ? "Evaluado" : "Evaluar"}
                          </button>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="kt-nav">
                              { !_.isEmpty( vya ) && !_.isEmpty( vya.status ) &&
                                <li className="kt-nav__item">
                                  { vya.status !== "no_evaluation" || vya.status === "waiting_for_results" || vya.status === "test_completed" ?
                                    <a
                                      style={{
                                        display: "flex",
                                        flexGrow: 1,
                                        alignItems: "center",
                                        padding: "0.55rem 1.75rem",
                                        cursor: "not-allowed"
                                      }}
                                    >
                                      { vya.status === "waiting_for_results" &&
                                        <>
                                          <i className="kt-nav__link-icon fas fa-file-medical-alt disabled-icon mx-2" style={{color: "#a2a5b9"}}></i>
                                          <span className="kt-nav__link-text mx-2"  style={{color: "#a2a5b9"}}>
                                            Evaluación en Proceso VYA
                                          </span>
                                        </>
                                      }
                                      { vya.status === "test_completed" &&
                                        <>
                                          <i className="kt-nav__link-icon far fa-check-circle disabled-icon mx-2" style={{color: "#a2a5b9"}}></i>
                                          <span className="kt-nav__link-text mx-2"  style={{color: "#a2a5b9"}}>
                                            Evaluación Completada VYA
                                          </span>
                                        </>
                                      }
                                    </a>
                                    :
                                    <a
                                      className="kt-nav__link"
                                      onClick={() => handledEvaluation(postulation._id, "vya" )}
                                    >
                                      <i className="kt-nav__link-icon fas fa-street-view"></i>
                                      <span className="kt-nav__link-text">Evaluación VYA</span>
                                    </a>
                                  }
                                </li>
                              }
                              { !_.isEmpty( zyvo ) && !_.isEmpty( zyvo.status ) &&
                                <li className="kt-nav__item">
                                  { zyvo.status !== "no_evaluation" || zyvo.status === "Prueba completada" ?
                                    <a
                                      style={{
                                        display: "flex",
                                        flexGrow: 1,
                                        alignItems: "center",
                                        padding: "0.55rem 1.75rem",
                                        cursor: "not-allowed"
                                      }}
                                    >
                                      { zyvo.status === "waiting_for_results" &&
                                        <>
                                          <i className="kt-nav__link-icon fas fa-file-medical-alt disabled-icon mx-2" style={{color: "#a2a5b9"}}></i>
                                          <span className="kt-nav__link-text mx-2"  style={{color: "#a2a5b9"}}>
                                            Evaluación en Proceso ZYVO
                                          </span>
                                        </>
                                      }
                                      { zyvo.status === "Prueba completada" &&
                                        <>
                                          <i className="kt-nav__link-icon far fa-check-circle disabled-icon mx-2" style={{color: "#a2a5b9"}}></i>
                                          <span className="kt-nav__link-text mx-2"  style={{color: "#a2a5b9"}}>
                                            Evaluación Completada ZYVO
                                          </span>
                                        </>
                                      }
                                    </a>
                                    :
                                    <a
                                      className="kt-nav__link"
                                      onClick={() => handledEvaluation(postulation._id, "zyvo" )}
                                    >
                                      <i className="kt-nav__link-icon fas fa-street-view"></i>
                                      <span className="kt-nav__link-text">Evaluación ZYVO</span>
                                    </a>
                                  }
                                </li>
                              }
                            </ul>
                          </div>
                        </div>
                      }
                    </>
                    :
                    <div className="dropdown dropdown-inline">
                      <button
                        className="btn btn-secondary disabled"
                        disabled={true}
                        data-toggle={"dropdown"}
                        aria-haspopup={"true"}
                        aria-expanded={"true"}
                      >
                        Debe Configurar Evaluación
                      </button>
                    </div>
                  }

                  <div className="dropdown dropdown-inline">
                    <button
                      className="btn btn-clean btn-sm btn-icon"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="flaticon-more"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      <DropDownOptions
                        project={project}
                        postulation={postulation}
                        postulation_id={postulation._id}
                        applicant={applicant}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <PostulationRating
                total={5}
                postulation_id={postulation._id}
                current_rating={postulation.rating}
                interactive={true}
              />

              <div className="kt-widget__subhead pb-0 d-flex flex-wrap">
                <a className="default-cursor __force-text_break-word">
                  <i className="flaticon-email" />
                  { applicant.email }
                </a>
                <a className="default-cursor">
                  <i className="flaticon-chat-2" />
                  { applicant.phone_number }
                </a>
                <a className="default-cursor">
                  <i className="flaticon2-open-text-book" />
                  { applicant.career }
                </a>
                <a className="default-cursor">
                  <i className="flaticon-profile-1" />
                  { applicant.institute }
                </a>
                <a className="default-cursor">
                  <i className="flaticon-presentation" />
                  { applicant.contact_reason }
                </a>
                { with_salary_question &&
                  <a className="default-cursor __force-text_break-word">
                    <i className="flaticon-price-tag" />
                    {toCurrency(postulation.salary_expectation)}
                  </a>
                }
                { with_years_question &&
                  <a className="default-cursor __force-text_break-word">
                    <i className="flaticon2-time" />
                    {`${postulation.experience_years} años`}
                  </a>
                }
              </div>
              { showDiscartedAt() }
            </div>
          </div>

          <div className="kt-widget__bottom">
            <div className="kt-widget__item">
              <div className="kt-widget__icon">
                <i className="flaticon-interface-10"></i>
              </div>
              <div className="kt-widget__details">
                <span className="kt-widget__title text-primary">
                  {I18n.t("cv.title")}
                </span>
                <span className="kt-widget__value">
                  <span>{Math.round(postulation.cv_score)} pts.</span>
                </span>
              </div>
            </div>

            { hasProduct({ product: "shortlist", projectFilters }) &&
              <div className="kt-widget__item">
                <div className="kt-widget__icon">
                  <i className="flaticon-speech-bubble"></i>
                </div>
                <div className="kt-widget__details">
                  <span className="kt-widget__title text-primary">
                    {I18n.t("shortlist.title")}
                  </span>
                  <span className="kt-widget__value">
                    <span>{Math.round(postulation.shortlist.score)} pts.</span>
                  </span>
                </div>
              </div>
            }

            { hasProduct({ product: "zyvo", projectFilters }) &&
              <div className="kt-widget__item">
                <div className="kt-widget__icon">
                  <i className="flaticon-interface-7"></i>
                </div>
                <div className="kt-widget__details">
                  <span className="kt-widget__title text-primary">
                    {I18n.t("zyvo.title")}
                  </span>
                  <span className="kt-widget__value">
                    <span>{Math.round(postulation.zyvo.score)} pts.</span>
                  </span>
                </div>
              </div>
            }

            { hasProduct({ product: "interview", projectFilters }) &&
              <div className="kt-widget__item">
                <div className="kt-widget__icon">
                  <i className="flaticon-list"></i>
                </div>
                <div className="kt-widget__details">
                  <span className="kt-widget__title text-primary">
                    {I18n.t("interview.title")}
                  </span>
                  <span className="kt-widget__value">
                    <span>{Math.round(postulation.interview.score)} pts.</span>
                  </span>
                </div>
              </div>
            }

            <div className="kt-widget__item">
              <div className="kt-widget__icon">
                <i className="flaticon-computer"></i>
              </div>
              <div className="kt-widget__details">
                <span className="kt-widget__title text-primary">
                  {I18n.t("portal.title")}
                </span>
                <span className="kt-widget__value">
                  <span>{portal.name}</span>
                </span>
              </div>
            </div>

            <div className="kt-widget__item">
              <div className="kt-widget__icon">
                <i className="flaticon-statistics"></i>
              </div>
              <div className="kt-widget__details w-100">
                <span className="kt-widget__title text-primary">
                  Puntaje Global
                </span>
                <Progress
                  theme={{
                    success: {
                      symbol: `${postulation.global_score}%`,
                      color: "#5d78ff",
                    },
                  }}
                  percent={postulation.global_score}
                  status="success"
                  strokeWidth={3}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostulationCard;
