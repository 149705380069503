import axios from "axios";
import _ from "lodash";
import moment from "moment";

export const updatePostulant = async ({ postulation_ids, toWhere }) => {
  var response;

  await axios
    .patch(`${window.location.origin}/postulations/update_aasm_state`, {
      postulation_ids,
      aasm_state: toWhere,
    })
    .then(() => {
      response = true;
    })
    .catch(() => {
      response = false;
    });
  return response;
};

export const componentsIds = (except = null, append = []) => {
  var allIds = [
    "searchPostulation",
    "shortlistScore",
    "shortlistState",
    "zyvoScore",
    "zyvoState",
    "interviewScore",
    "interviewState",
    "cvScore",
    "globalScore",
    "globalState",
    "portalList",
    "discardedList",
    "ownCvList",
    "isNewList",
    "pinnedList",
    "postulationByMonth",
    "genderList",
    "universitiesSearch",
    "ratingList",
    "postulationsResult",
    "rankPostulations",
    "ageRange",
    "salaryRange",
    "yearsRange",
    "kanbanState",
  ];

  if (!_.isEmpty(append)) {
    allIds = allIds.concat(append);
  }
  const toReturn = _.without(allIds, except);
  return toReturn;
};

export const transformedQuery = ({ value, field }) => {
  if (value) {
    const bool = value == "Si";
    const query = { match: {} };
    query["match"][field] = bool;
    return { query };
  }
};

export const transformedBool = (list) => {
  var _list = list;
  _list.map((item) => {
    switch (item["key_as_string"]) {
      case "true":
        item["key"] = "Si";
        break;
      case "false":
        item["key"] = "No";
        break;
    }
  });
  return _list;
};

export const hasProduct = ({ product, projectFilters }) => {
  if (projectFilters) {
    return (
      _.findIndex(projectFilters, (item) => {
        return item["aasm_state"] == product;
      }) != -1
    );
  } else {
    return true;
  }
};

export const defaultQuery = (project_id, postulations_ids) => {
  return () => {
    return {
      query: {
        bool: {
          must: [
            { term: { "project.id": project_id } },
            { terms: { _id: postulations_ids } },
          ],
        },
      },
    };
  };
};

export const localDateFromNow = (date) =>
  moment(date).utcOffset("-03:00").fromNow();
