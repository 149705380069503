import React, { useState } from "react";
import Rater from "react-rater";
import ShowNotification from "../show_notification";
import axios from "axios";

const ApplicantRater = ({
  postulation_id,
  interactive,
  current_rating,
  total
}) => {
  const [rating, setRating] = useState(current_rating);

  const handleApplicantUpdate = _rating => {
    setRating(_rating);
    applicantUpdate(_rating);
  };

  const applicantUpdate = new_rating => {
    axios
      .patch(
        `${window.location.origin}/postulations/${postulation_id}/rate_postulant.json`,
        { postulation: { rating: new_rating } }
      )
      .then(response => {
        ShowNotification({
          message: "El candidato se ha actualizado correctamente",
          type: "success"
        });

        if (!interactive) location.reload();
      })
      .catch(error => {
        ShowNotification({
          message: "Ha ocurrido un error, vuelva a intentar",
          type: "error"
        });
      });
  };

  return (
    <div className="align-self-center pb-1">
      <div className="d-flex align-items-center">
        {interactive ? (
          <input
            type="hidden"
            name="postulation[rating]"
            id="postulation_rating"
            value={rating}
          />
        ) : null}
        <Rater
          total={total}
          rating={rating}
          interactive={interactive}
          onRate={rater => handleApplicantUpdate(rater.rating)}
        />
        <i
          data-toggle="tooltip"
          data-placement="right"
          title="Restablecer puntuación"
          className="reset-rating fas fa-backspace text-primary"
          onClick={() => handleApplicantUpdate(0)}
        ></i>
      </div>
    </div>
  );
};

export default ApplicantRater;
