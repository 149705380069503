import React from "react";
import _ from "lodash";
import { FormGroup, CustomInput, Collapse } from "reactstrap";

//TODO: Refactor ME Please!!!
export const drawCompetence = ({
  competencies_ids,
  competences,
  selected_competencies,
  competence_type,
  group_name,
  type,
  handleRange,
  handleChange,
  selected_profile
}) => {
  const grouped_competences = _.groupBy(
    competences.competencies,
    "competency_type"
  );

  const iterator = grouped_competences[`${group_name}`];
  switch (type) {
    case "project":
      const have_any = _.some(iterator, competence => {
        return _.includes(competencies_ids, competence.id.toString());
      });
      var condition = !_.isEmpty(competencies_ids) && have_any;
      var read_only = true;
      break;
    case "profile":
      var condition = true;
      var read_only = false;
      break;
  }

  if (condition)
    return (
      <div className="col">
        <div
          className="card card-hover mb-2"
          style={read_only ? null : { minHeight: "105px" }}
        >
          <div
            className="card-header bg-white d-flex flex-column justify-content-between"
            style={read_only ? null : { minHeight: "105px" }}
          >
            <div className="d-inline-block d-flex align-items-center">
              <h3 className="h5 mb-0 d-inline-block">
                {competences[`es_${competence_type}_title`]}
              </h3>
            </div>
            <p className="small text-muted mb-0 pr-2 pr-sm-0">
              {competences[`es_${competence_type}_description`]}
            </p>
          </div>
          <div
            className="card-body"
            style={read_only ? null : { minHeight: "480px" }}
          >
            {iterator.map(competence => {
              switch (type) {
                case "project":
                  var find_competence = _.find(
                    selected_profile ? selected_profile.competencies : null,
                    {
                      id: competence.id
                    }
                  );
                  var _checked = _.includes(
                    competencies_ids,
                    competence.id.toString()
                  );
                  var show = _checked;
                  break;
                case "profile":
                  var find_competence = _.find(selected_competencies, {
                    id: competence.id
                  });
                  var _checked = !!find_competence;
                  var show = true;
                  break;
              }

              if (show)
                return (
                  <div key={competence.name} className="form-group form-check">
                    <FormGroup>
                      <div>
                        <CustomInput
                          type="switch"
                          disabled={read_only}
                          readOnly={read_only}
                          checked={_checked}
                          onChange={event =>
                            type == "profile"
                              ? handleChange(
                                  event,
                                  competence.id,
                                  competence_type
                                )
                              : null
                          }
                          id={`${type}_${competence_type}_${competence.id}`}
                          label={competence.es_description}
                        />
                        <Collapse isOpen={_checked}>
                          <div className="d-flex align-items-center my-2">
                            <CustomInput
                              className="zyvo_range"
                              type="range"
                              className={
                                find_competence && find_competence.score
                                  ? null
                                  : "without_slider"
                              }
                              disabled={read_only}
                              readOnly={read_only}
                              value={
                                find_competence ? find_competence.score : 50
                              }
                              onChange={event =>
                                type == "profile"
                                  ? handleRange(event, competence.id)
                                  : null
                              }
                              id={`${type}_range_${competence.id}`}
                              min="0"
                              max="100"
                            />
                          </div>
                          <div className="row my-2">
                            <small className="col text-left text-primary">
                              {competence.es_low_level}
                            </small>
                            {find_competence && find_competence.score ? (
                              <small className="col text-center text-primary">
                                {find_competence
                                  ? `${Math.abs(
                                      2 * (find_competence.score - 50)
                                    )}%`
                                  : 50}
                              </small>
                            ) : null}
                            <small className="col text-right text-primary">
                              {competence.es_high_level}
                            </small>
                          </div>
                        </Collapse>
                        <small className="text-muted d-block text-justify">
                          {competence.es_tooltip}
                        </small>
                      </div>
                    </FormGroup>
                  </div>
                );
            })}
          </div>
        </div>
      </div>
    );
};
