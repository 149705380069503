 import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Spinner
} from "reactstrap";
import miterna from "../../../../assets/images/logos/circle/miterna.svg";
import "../../../../assets/stylesheets/multiposting.scss";
import axios from "axios";
import ShowNotification from "../../show_notification"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck } from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const PortalsForm = props => {
  const {
    toggleTab,
    headers,
    portals,
    steps,
    projectId,
    portalsFormData
  } = props


  const MySwal = withReactContent(Swal);

  const showAlert = (isComplete, projectId) => {
    MySwal.fire({
      title: isComplete ? 'Configuración Finalizada' : 'Borrador de Proceso guardado',
      text: isComplete ? 'Tu proceso ha sido creado exitosamente, y será revisado por nuestro equipo para su publicación' : 'Puedes finalizar la configuración de tu proceso más tarde',
      type: 'success',
      confirmButtonText: 'Ver Proceso',
      confirmButtonClass: "btn btn-primary"
    }).then(result => {
      if (result.value) {
        localStorage.removeItem("project_id")
        localStorage.removeItem("steps")
        isComplete ?
          window.location.href = `${window.location.origin}/process`
          :
          window.location.href = `${window.location.origin}/process`

      }
    });
  }




  const [ isLoading, setIsLoading ] = useState(false)
  const [ portalSelect, setPortalSelect ] = useState([ { portal: "miterna" } ])
  const [ isDisabled, ] = useState( portalsFormData !== null ? true : false )

  const handleSelectPortals = (event, portal_selected) => {
    if(event.target.checked) {
      setPortalSelect([...portalSelect, { portal: portal_selected }])
    } else {
      setPortalSelect([ ...portalSelect.filter( portalSelect => portalSelect.portal !== portal_selected) ])
    }
  }

  const handleSubmit = () => {
    setIsLoading(true)
    axios({
      method: 'POST',
      url: `${window.location.origin}/project/portals`,
      Headers: headers,
      data: {
        project_id: projectId,
        portals: portalSelect
      }
    })
    .then( response => {
      if(response.status === 200) {
        /*
        ShowNotification({
          message: "Portales guardados en borradores, puedes continuar...",
          type: "success"
        })
        */
        setIsLoading(false)
        showAlert(true, projectId)
        window.scrollTo(0, 0)
      } else {
        setIsLoading(false)
        ShowNotification({
          message: "Ups, ocurrió un error. Vuelve a intentar...",
          type: "error"
        })
      }
    })
    .catch( error => {
      setIsLoading(false)
      ShowNotification({
        message: "Ups, ocurrió un error. Vuelve a intentar...",
        type: "error"
      })
      console.log(error)
    })
  }

  useEffect(() => {
    if( portalsFormData ) setPortalSelect(portalsFormData.portals)
  }, [])

  useEffect(()=>{}, [ isLoading, portals, portalSelect ])

  return (
    <React.Fragment>
      <Row>
        <Col md="4" className="text-center mb-2">
          <div className="portal-item border-0 d-flex flex-column justify-content-center align-items-center  form-check ">
            <label id="label_miterna"
              className=" pointer w-100 flex-fill text-center m-3 d-flex flex-column justify-content-center align-items-center form-check-label"
            >
              <img
                className="rounded-lg mb-2 d-block p-2 portal-img-form img-fluid active"
                width="150"
                height="150"
                src={miterna}
              />
              <Input
                type="checkbox"
                id="miterna"
                name="miterna"
                className="form-check-input"
                checked={true}
                readOnly
              />
            </label>
          </div>
        </Col>
        {/* portals && portals.map( (portal, index) =>
          <Col md="4" className="text-center mb-2" key={`${portal}-${index}`}>
            <div className="portal-item border-0 d-flex flex-column justify-content-center align-items-center form-check ">
              <label id={"label_" + portal.name}
                className="pointer w-100 flex-fill text-center m-3 d-flex flex-column justify-content-center align-items-center form-check-label"
              >
                { portalSelect.find( portalSelect => portalSelect.portal === portal.name ) ?
                  <img
                    className="rounded-lg mb-2 d-block p-2 portal-img-form img-fluid active"
                    width="150"
                    height="150"
                    src={ portal.img }
                  />
                  :
                  <img
                    className="rounded-lg mb-2 d-block p-2 portal-img-form img-fluid"
                    width="150"
                    height="150"
                    src={ portal.img }
                  />
                }
                { isDisabled ?
                  <Input
                    type="checkbox"
                    id={ portal && portal.id }
                    name={ portal && portal.name }
                    className="form-check-input"
                    checked={true}
                    readOnly
                  />
                  :
                  <Input
                    type="checkbox"
                    id={ portal && portal.id }
                    name={ portal && portal.name }
                    className="form-check-input"
                    onChange={ event => handleSelectPortals(event, portal.name) }
                  />
                }
              </label>
            </div>
          </Col>
        )*/}
      </Row>
      <Row>
        <Col md="12" className="text-right">
          { !isDisabled &&
            <React.Fragment>
              {isLoading ?
                <Button
                  color="secondary"
                  disabled
                >
                  <Spinner color="primary" style={{ width: '2rem', height: '2rem' }} />
                </Button>
                :
                <Button
                  color="success"
                  className="my-4"
                  onClick={() => handleSubmit()}
                >
                  <strong className="px-3">Finalizar</strong>
                  <FontAwesomeIcon icon={faUserCheck} />
                </Button>
              }
            </React.Fragment>
          }
        </Col>
      </Row>
    </React.Fragment>
  )
}
export default PortalsForm;
