import React from "react";
import ButterToast, { POS_TOP, POS_RIGHT } from "butter-toast";
import LazyComponent from "../lazy_component";

export default class RenderButterToast extends LazyComponent {
  render() {
    return (
      <ButterToast position={{ vertical: POS_TOP, horizontal: POS_RIGHT }} />
    );
  }
}
