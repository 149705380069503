import React from "react";
import LazyComponent from "../../lazy_component";
import { drawCompetence } from "./common";
import _ from "lodash";

export default class ZyvoProjectShow extends LazyComponent {
  constructor(props) {
    super(props);
    const { selected_profile, attributes } = props;
    const { competencies } = attributes.service || attributes;
    this.state = {
      competencies_ids: _.uniq(competencies.replace(/\s/g, "").split(",")),
      selected_profile: selected_profile,
    };
  }

  render() {
    const { competences } = this.props;
    const { competencies_ids, selected_profile } = this.state;

    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="flaticon-interface-7"></i>
            </span>
            <h3 className="kt-portlet__head-title">
              Habilidades a Evaluar
              <a
                href="https://tests.4talent.cl"
                target="_blank"
                className="text-primary"
              >
                {" "}
                - Zyvo
              </a>
            </h3>
          </div>
          <div className="kt-portlet__head-toolbar"></div>
        </div>
        <div className="kt-portlet__body">
          <div className="kt-portlet__content">
            <div className="row">
              {drawCompetence({
                competence_type: "cognitive",
                group_name: "cognitive_skill",
                competences,
                competencies_ids,
                selected_profile,
                type: "project",
              })}
              {drawCompetence({
                competence_type: "personality",
                group_name: "personality_trait",
                competences,
                competencies_ids,
                selected_profile,
                type: "project",
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
