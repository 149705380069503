import React from "react";
import LazyComponent from "../lazy_component";

export default class UserAlert extends LazyComponent {
  constructor(props) {
    super(props);
    var type = props.platforms.length >= 1 ? "success" : undefined;
    this.state = {
      userId: props.userId,
      type: type
    };
  }

  componentWillMount() {
    var _this = this;

    App.cable.subscriptions.create(
      {
        channel: "LaborumAccountVerificationsChannel",
        id: _this.state.userId
      },
      {
        received: data => {
          _this.setState({ type: data.verification.color });
        }
      }
    );

    App.cable.subscriptions.create(
      {
        channel: "TrabajandoAccountVerificationsChannel",
        id: _this.state.userId
      },
      {
        received: data => {
          _this.setState({ type: data.verification.color });
        }
      }
    );
  }

  drawProjectButton() {
    if (this.state.type == "success") {
      return (
        <a
          id="user_project_button"
          className="btn btn-primary"
          href="/process/new"
        >
          Publicar <i className="fas fa-arrow-circle-right ml-2" />
        </a>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div>
        <button type="submit" className="btn btn-success mr-2">
          Actualizar Información
        </button>
        {this.drawProjectButton()}
      </div>
    );
  }
}
