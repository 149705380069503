import React from "react";
import { SelectedFilters } from "@appbaseio/reactivesearch";

const CustomSelectedFilters = () => (
  <SelectedFilters
    innerClass={{
      button: "btn btn-label-primary"
    }}
    showClearAll={true}
    clearAllLabel="Limpiar Filtros"
  />
);

export default CustomSelectedFilters;
