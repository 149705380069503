import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import LineChartLoader from "../loaders/line_chart";
import lineLibraryOpt from "../line_library_opt";
import Chart from "react-apexcharts";
import _ from "lodash";

const LineChartWrapper = ({ aggregations, loading, title }) => {
  const [data, setData] = useState([]);

  const handleData = _buckets => {
    return new Promise((resolve, reject) => {
      resolve(_.map(_buckets, item => [item["key"], item["doc_count"]]));
    });
  };

  useEffect(() => {
    if (aggregations && aggregations.by_created_at.buckets) {
      const { buckets } = aggregations.by_created_at;
      handleData(buckets).then(result => {
        setData(result);
      });
    }
  }, [aggregations]);

  if (_.isEmpty(data) || loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="w-50">
          <LineChartLoader />
        </div>
      </div>
    );
  } else {
    return (
      <div className="card border-0">
        <div className="card-header text-center bg-white border-0">
          <h4 className="text-primary pt-3">{title}</h4>
        </div>
        <div className="card-body">
          <Chart
            title={title}
            options={lineLibraryOpt}
            series={[{ name: "Cantidad de Postulaciones", data: data }]}
            height={350}
            type="area"
          />
        </div>
      </div>
    );
  }
};

export default LineChartWrapper;
