export const colors = [
  "#5a3ee0",
  "#651fff",
  "#7c4dff",
  "#4527a0",
  "#512da8",
  "#5e35b1",
  "#673ab7",
  "#7e57c2",
  "#b388ff",
  "#9575cd",
  "#b39ddb",
  "#d1c4e9",
  "#ede7f6"
];

const lineLibraryOpt = {
  colors: colors,
  stroke: {
    curve: "smooth"
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    type: "datetime"
  },
  tooltip: {
    x: {
      format: "dd MMM yyyy"
    }
  },
  fill: {
    colors: colors,
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 90, 100]
    }
  }
};

export default lineLibraryOpt;
