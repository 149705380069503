import React from "react";
import _ from "lodash";
import LazyComponent from "../../lazy_component";
import ShowNotification from "../show_notification";
import { drawCompetence } from "./common";
import axios from "axios";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";
import zyvoImg from "../../../assets/images/tests/test_zyvo.png";

export default class ZyvoCreateProfile extends LazyComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
      zyvo_process_duration: {},
      selected_competencies: [],
      zyvo_profile_name: "",
      zyvo_profile_description: ""
    };
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  handleChangeValue(e) {
    const { target } = e;
    this.setState({ [`${target.id}`]: target.value });
  }

  handleChange(event, competence_id, competence_type) {
    const { checked } = event.target;
    var { selected_competencies } = this.state;
    if (checked)
      selected_competencies.push({
        id: competence_id,
        score: competence_type == "cognitive" ? "100" : "50"
      });
    else
      _.remove(selected_competencies, item => {
        return item.id == competence_id;
      });
    const stateUpdater = data => {
      this.setState({ zyvo_process_duration: data });
    };
    this.setState({ selected_competencies }, () => {
      this.props.getProcessDuration(
        _.map(this.state.selected_competencies, "id"),
        stateUpdater
      );
    });
  }

  handleRange(event, competence_id) {
    const { value } = event.target;
    const { selected_competencies } = this.state;
    var competence = _.find(selected_competencies, {
      id: competence_id
    });
    competence["score"] = value;
    this.setState({ selected_competencies });
  }

  handlerSubmit(e) {
    this.setState({ loading: true });
    e.preventDefault();
    const { current_user } = this.props;
    const {
      selected_competencies,
      zyvo_profile_name,
      zyvo_profile_description
    } = this.state;

    const headers = {
      "X-User-Email": current_user.email,
      "X-User-Token": current_user.authentication_token
    };

    const data = {
      name: zyvo_profile_name,
      description: zyvo_profile_description,
      competencies: selected_competencies
    };

    axios
      .post(`${window.location.origin}/zyvo/profiles`, data, {
        headers: headers
      })
      .then(response => {
        this.setState({
          modal: false,
          loading: false,
          zyvo_profile_name: "",
          selected_competencies: [],
          zyvo_profile_description: ""
        });
        ShowNotification({
          message: "Cambios Guardados",
          type: "success"
        });
      })
      .catch(error => {
        this.setState({
          loading: false
        });
        ShowNotification({
          message: "Vuelva a intentar",
          type: "error"
        });
      });
  }

  drawCompetences() {
    const { competences } = this.props;
    const { selected_competencies } = this.state;

    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="flaticon2-graph-1"></i>
            </span>
            <h3 className="kt-portlet__head-title">
              Configuración habilidades a Evaluar
              <small>
                <a
                  href="https://tests.4talent.cl"
                  target="_blank"
                  className="text-primary"
                >
                  Filtro Test
                </a>
              </small>
            </h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="alert alert-solid-brand alert-bold" role="alert">
            <div className="alert-text">
              Puedes seleccionar tanto habilidades cognitivas como rasgos de
              personalidad, en ambos casos deberas <b>ajustar</b> el
              <b>resultado esperado</b>.
            </div>
          </div>

          <div className="row">
            {drawCompetence({
              competences,
              selected_competencies,
              handleRange: this.handleRange,
              handleChange: this.handleChange,
              competence_type: "cognitive",
              group_name: "cognitive_skill",
              type: "profile"
            })}
            {drawCompetence({
              competences,
              selected_competencies,
              handleRange: this.handleRange,
              handleChange: this.handleChange,
              competence_type: "personality",
              group_name: "personality_trait",
              type: "profile"
            })}
          </div>
        </div>
      </div>
    );
  }

  drawButtonActionText() {
    return this.state.loading ? (
      <>
        Guardando <i className="text-white fas fa-spinner fa-pulse"></i>
      </>
    ) : (
      "Guardar"
    );
  }

  drawModalBody() {
    return (
      <Form onSubmit={this.handlerSubmit}>
        <div className="alert alert-outline-info" role="alert">
          <div className="alert-text">
            <h4 className="alert-heading">Introducción</h4>
            <p>
              A continuación podrás <b>crear un nuevo perfil</b> para la
              herramienta Zyvo.
            </p>
            <hr></hr>
            <p className="mb-0">
              Debes <b>seleccionar las habilidades</b> a evaluar y luego ajustar
              el <b>resultado esperado</b> utilizando la barra.
            </p>
          </div>
        </div>

        <FormGroup>
          <Label for="zyvo_profile_name">Nombre</Label>
          <Input
            type="text"
            id="zyvo_profile_name"
            value={this.state.zyvo_profile_name}
            onChange={this.handleChangeValue}
            required
          />
          <FormText>
            Nombre que tendrá el perfil. Esto es solo para poder identificarlo.
          </FormText>
        </FormGroup>
        <FormGroup>
          <Label for="zyvo_profile_description">Descripción</Label>
          <Input
            type="textarea"
            id="zyvo_profile_description"
            value={this.state.zyvo_profile_description}
            onChange={this.handleChangeValue}
            required
          />
          <FormText>
            Pequeña descripción del perfil, mencionando algunos rasgos
            específicos.
          </FormText>
        </FormGroup>
        {this.drawCompetences()}
        <div className="d-flex justify-content-center">
          {this.props.drawProcessDuration(
            this.state.zyvo_process_duration,
            _.map(this.state.selected_competencies, "id")
          )}
        </div>
        <div className="w-100 text-center">
          <Button color="success">{this.drawButtonActionText()}</Button>
        </div>
      </Form>
    );
  }

  render() {
    return (
      <>
        <button
          type="button"
          className="btn btn-label-primary btn-block"
          onClick={this.toggle}
        >
          Crear Perfil
        </button>
        {/* <div className="card test-card mb-5 p-0 d-flex flex-column justify-content-between align-items-center bg-light">
          <a className="btn btn-light flex-fill h-100 p-0 text-decoration-none d-block rounded-0 bg-light border-0 p-2" 
             id="psicolaboralTest" 
             title="Test Psicolaboral"
             onClick={this.toggle}
             type="button"
          >
            <div className="w-100 h-100 flex-fill text-center d-flex flex-column justify-content-between align-items-center form-check-label font-weight-bold">
              <p className="d-block w-100 text-info font-weight-bold">
                <i className="fas fa-brain fa-lg mr-2"></i>
                Crear Nuevo Perfil
              </p>
              <div className="flex-fill d-flex flex-column justify-content-center align-items-center">
                <img className="img-fluid rounded-lg d-block py-2 mx-auto" src={zyvoImg} />
              </div>
            </div>
          </a>
        </div> */}

        <Modal
          size="xl"
          toggle={this.toggle}
          isOpen={this.state.modal}
          className={"modal-dialog-centered"}
        >
          <ModalHeader toggle={this.toggle}>Nuevo Perfil</ModalHeader>
          <ModalBody>{this.drawModalBody()}</ModalBody>
        </Modal>
      </>
    );
  }
}
