import React from "react";
import LazyComponent from "../lazy_component";
import Documents from "documents-controldoc";
import _ from "lodash";

export default class ControldocDocumentsAttributes extends LazyComponent {
  render() {
    var baseName = "person_sending";
    var {
      documents,
      document_types,
      signer_types,
      person_email,
      company_email
    } = this.props;
    var _documents = [];
    for (var key in documents) {
      if (documents[key]["_destroy"] != undefined) {
        documents[key]["_destroy"] = JSON.parse(documents[key]["_destroy"]);
      }
      _documents.push(documents[key]);
    }

    return (
      <Documents
        documents={_documents}
        document_types={document_types}
        signer_types={signer_types}
        person_email={person_email}
        company_email={company_email}
        form_name={baseName + "[documents_attributes]"}
      />
    );
  }
}
