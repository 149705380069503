import React from "react";
import { Cart, AddCartButton, CartReducers } from "react-purchase-cart";
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import LazyComponent from "../lazy_component";
import ShowNotification from "./show_notification";
import axios from "axios";

const store = createStore(
  combineReducers({
    cart: CartReducers
  })
);

const currencyFormat = number => {
  return new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP"
  }).format(number);
};

class ShopingCart extends LazyComponent {
  handleCheckout(data) {
    const headers = {
      "X-User-Email": this.props.current_user.email,
      "X-User-Token": this.props.current_user.authentication_token
    };
    axios
      .post(`${window.location.origin}/products/buy_multiple.json`, data, {
        headers: headers
      })
      .then(response => {
        ShowNotification({
          message: response.data.message,
          type: "success"
        });
        window.location = `${window.location.origin}/purchases/${response.data.purchase.id}`;
      })
      .catch(error => {
        ShowNotification({
          message: "Ha ocurrido un error, vuelva a intentar",
          type: "error"
        });
      });
  }

  render() {
    return (
      <Provider store={store}>
        <Cart
          currencySymbol="CLP"
          languaje="es-CL"
          handleCheckout={this.handleCheckout}
          cartEmptyLabel={"El carrito está vacio"}
          cartTextLabel={"Carrito de compras"}
          checkoutTextLabel={"Ir a pagar"}
          subTotalTextLabel={"Total"}
          quantityTextLabel={"Cantidad"}
        />
        {this.props.items.map((item, key) => {
          if (item.products.length > 0) {
            return (
              <div
                key={key}
                className="row d-flex justify-content-center align-items-center mt-5"
              >
                <div className="col-md-12 section-title text-center">
                  <h2 className="h3 hr-black font-weight-bold">
                    {item.category}
                  </h2>
                </div>
                {item.products.map((product, key) => {
                  return (
                    <div
                      key={key}
                      className="col-lg-3 col-md-6 col-sm-12 p-lg-0"
                    >
                      <div
                        className="card mb-12 price-card pb-0 border-0"
                        style={{ height: "425px" }}
                      >
                        <div
                          className="card-body text-center aos-init aos-animate"
                          data-aos="zoom-in"
                          data-aos-duration="800"
                          data-aos-offset="50"
                        >
                          <div className="card-title">
                            <h4
                              className="clearfix mb-0"
                              style={{ height: "125px" }}
                            >
                              <span
                                className="font-weight-bold product-name"
                                style={{ lineHeight: "0px" }}
                              >
                                {product.title_text}
                              </span>
                              <br />
                              <span className="h2 price font-weight-bold">
                                <span className="h6 font-weight-bold mr-1"></span>
                                {currencyFormat(product.unit_price)}
                                <p className="font-weight-bold product-disclaimer">
                                  {`Por ${product.quota_type}`}
                                </p>
                              </span>
                            </h4>
                            <h6 className="font-weight-light small">
                              {`Precio ${currencyFormat(
                                product.total_price
                              )} + iva`}
                            </h6>
                          </div>
                          <ul className="list-group list-group-flush text-left">
                            <li>{`${product.quota} ${product.quota_type}/s`}</li>
                            {product.quota_applicant ? (
                              <li>{`${product.quota_applicant} ${product.quota_applicant_type} máximos por proceso`}</li>
                            ) : null}

                            <li>{product.description}</li>
                            <li>{product.vigency}</li>
                          </ul>
                          <div className="card-body p-0">
                            <AddCartButton
                              className="card-link btn btn-outline-primary my-3"
                              addLabel="Comprar"
                              product={{
                                id: product.id,
                                sku: `${product.name}-${product.id}`,
                                name: product.name,
                                price: product.price,
                                thumb: product.thumb
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          }
        })}
      </Provider>
    );
  }
}

export default ShopingCart;
