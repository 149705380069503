import React from "react";
import LazyComponent from "../lazy_component";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import axios from "axios";

class ButtonChatDemo extends LazyComponent {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      sending: true,
      showLink: false
    };
  }

  handleClick = () => {
    let _this = this;
    const data = {
      screening_test: {
        phone: _this.state.phone,
        email: _this.props.user_email
      }
    };

    _this.setState({ sending: true });

    axios({
      url: `/process/${_this.props.project_id}/request_shortlist_demo`,
      type: "json",
      method: "POST",
      headers: {
        "X-CSRF-TOKEN": $('[name="csrf-token"]')[0].content,
        enctype: "multipart/form-data"
      },
      data: data,
      processData: false
    })
      .then(response => {
        const url = response.url_chat;
        _this.setState({ showLink: true, url: url });
        ShowNotification({
          message: "Demo Generado",
          type: "success"
        });
      })
      .cacth(error => {
        ShowNotification({
          message: "Vuelva a intentar",
          type: "error"
        });
      })
      .finally(function() {
        _this.setState({ sending: false });
      });
  };

  isNumberPhoneValid = () => {
    const { phone } = this.state;
    return isValidPhoneNumber(phone);
  };

  handleInputPhone = numberPhone => {
    this.setState({
      phone: numberPhone,
      sending: !isValidPhoneNumber(numberPhone)
    });
  };

  handleClickReset = () => {
    this.setState({ phone: "", showLink: false });
  };

  drawFrom = () => {
    const { showLink } = this.state;
    const invalidPhoneMessage = I18n.t(
      "activerecord.errors.models.applicant.attributes.phone_number.invalid"
    );
    const requiredPhoneMessage = I18n.t(
      "activerecord.errors.models.applicant.attributes.phone_number.required"
    );

    if (!showLink) {
      return (
        <PhoneInput
          placeholder="Ingresar número de teléfono"
          value={this.state.phone}
          onChange={this.handleInputPhone}
          error={
            this.state.phone
              ? this.isNumberPhoneValid()
                ? undefined
                : invalidPhoneMessage
              : requiredPhoneMessage
          }
        />
      );
    } else {
      return (
        <div>
          <p>
            {I18n.t("projects.messages.demo_chat.success")}
            <a
              aria-controls="showChatLink"
              aria-expanded={false}
              data-toggle="collapse"
              href="#showChatLink"
              role="button"
            >
              {I18n.t("projects.messages.demo_chat.here")}
            </a>
            .
          </p>
          <div className="collapse" id="showChatLink">
            <a href={this.state.url} target="_blank">
              {this.state.url}
            </a>
          </div>
        </div>
      );
    }
  };

  drawButtons = () => {
    const { showLink } = this.state;
    if (showLink) {
      return (
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {I18n.t("actions.close")}
          </button>
          <button
            type="button"
            onClick={this.handleClickReset}
            className="btn btn-primary"
            disabled={this.state.sending}
          >
            {I18n.t("actions.try_again")}
          </button>
        </div>
      );
    } else {
      return (
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {I18n.t("actions.close")}
          </button>
          <button
            type="button"
            onClick={this.handleClick}
            className="btn btn-primary"
            disabled={this.state.sending}
          >
            {I18n.t("projects.actions.send_invitation")}
          </button>
        </div>
      );
    }
  };

  render() {
    return (
      <div>
        <button
          type="button"
          className="btn btn-label-info"
          data-toggle="modal"
          data-target="#chatBotDemoModal"
        >
          {I18n.t("projects.actions.chatbot_preview")}
        </button>

        <div
          className="modal fade"
          id="chatBotDemoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="chatBotDemoModal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="chatBotDemoModal">
                  {I18n.t("projects.headers.chatbot_modal_title")}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">{this.drawFrom()}</div>
              {this.drawButtons()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ButtonChatDemo;
