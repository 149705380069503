import React, {  useState, useEffect } from "react"
import { 
  UncontrolledTooltip, Form, Button, FormGroup, Input, Spinner
} from "reactstrap";
import ShowNotification from "../../show_notification";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import _ from "lodash";


const vya = props => {
  const { tests, selected_tests, authentication_headers, project_id } = props
  const MySwal = withReactContent(Swal)

  const [ checkboxList, setcheckboxList ] = useState([]);
  const [ isLoadingButton, setIsLoadingButton ] = useState(false)
  const [ vya, setVya ] = useState(
    selected_tests.find( vya_test => vya_test.type === "vya") !== null ?
    selected_tests.find( vya_test => vya_test.type === "vya") : null
  )

  const handledChangeCheckBox = code => {
    let new_checkbox_array = [...checkboxList, code];
    if(checkboxList.includes(code)) {
      new_checkbox_array = new_checkbox_array.filter(checkbox => checkbox !== code);
    }
    setcheckboxList(new_checkbox_array);
  }

  const showAlert = () => {
    MySwal.fire({
      title: 'Guardada la Configuración de Evaluación Personal del Proceso.',
      type: 'success',
    }).then( result => {
      if( result.value ) window.location.reload()
    })
  }

  const onSubmitVya = async (event) => {
    setIsLoadingButton(true)
    event.preventDefault();

    axios
      .post(`${window.location.origin}/project/optional_phase`, {
        project_id: project_id,
        type: "vya",
        tests: checkboxList
      }, {headers: authentication_headers})
      .then( response => {
        if( response.status === 201 ) {
          setIsLoadingButton(false)
          showAlert()
          window.scrollTo(0, 0)
        } else {
          ShowNotification({
            message: "Ups, ocurrió un error. Vuelve a Intentar...",
            type: "error"
          })
        }
      })
      .catch(error => {
        ShowNotification({
          message: `Ha Ocurrido un Error, intente nuevamente...`,
          type: "error"
        })
        setIsLoadingButton(false)
        console.log(error)
      });
  };

  useEffect( () => {}, [ checkboxList ])

  useEffect( () => {
    if( !_.isEmpty(selected_tests) ){
      if( !_.isEmpty(vya) ) setcheckboxList( vya.attributes )
    }
  }, [])


  return (
    <Form>
      <div className="row">
        { tests && tests.map( (test, index) => (
          <div key={`${test.code}-${index}`} className="col-sm-6 col-md-6 col-lg-6 mb-3">
            <div className="card h-100 hover-card">
              <div className="card-header p-2">
                <label className="d-flex w-100 justify-content-between align-items-start mb-0">
                  <FormGroup check>
                    {  _.isEmpty(vya) ?
                      <Input
                        className="mr-3"
                        type="checkbox"
                        onChange={ () => handledChangeCheckBox( test.code ) }
                        name={`application_process[vya_systems][]`}
                        id={`application_process_vya_systems_${test.code}`}
                      />
                      :
                      <Input
                        className="mr-3"
                        type="checkbox"
                        defaultChecked={ !_.isEmpty(vya.attributes.find( attribute => attribute === test.code)) }
                        name={`application_process[vya_systems][]`}
                        id={`application_process_vya_systems_${test.code}`}
                        disabled
                      />
                    }
                  </FormGroup>
                  <div className="d-block flex-fill">
                    <h5 className="h5 font-weight-bold my-0">{test.name_es}</h5>
                    <p className="small mb-0">
                      {test.code}
                    </p>
                  </div>
                  <div className="float-right">
                    <button className="btn btn-light text-primary p-0" 
                      data-placement="top" 
                      data-toggle="tooltip" 
                      title="" 
                      type="button" 
                      id={`tooltipInfo${test.code}`}
                    >
                      <i className="fas fa-info-circle fa-lg pr-0"></i>
                    </button>
                    <UncontrolledTooltip placement="top" target={`tooltipInfo${test.code}`}>
                      {test.description}
                    </UncontrolledTooltip>
                  </div>
                </label>
              </div>
              <div className="card-body p-2 flex-grow-1">
                <div className="row">
                  <div className="col-md-12">
                    <ul className="fa-ul list-unstyled my-2 my-lg-0 text-dark ml-4 small">
                      <li className="mt-1">
                        <span className="fa-li"><i className="fas fa-bookmark text-muted"></i></span><span className="badge badge-pill badge-info">{test.type_es}</span>
                      </li>
                      <li className="mt-1">
                        <span className="fa-li"><i className="fas fa-stopwatch text-muted"></i></span>Duración: {test.time} min.
                      </li>
                      <li className="mt-1">
                        <span className="fa-li"><i className="fas fa-tags text-muted"></i></span>
                          { test.measurements && test.measurements.map( measurement => (
                            <span key={`${measurement}`} className="badge badge-pill badge-light border mx-1 mt-1">{measurement}</span>
                          ))}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="w-100 text-center">
        { isLoadingButton ?
          <Spinner color="primary" style={{ width: '4rem', height: '4rem' }} />
          :
          <>
            { _.isEmpty(vya) &&
              <Button onClick={ onSubmitVya } color="success">Guardar</Button>
            }
          </>
        }
      </div>
    </Form>
  )
}

export default vya;
